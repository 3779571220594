import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FetchOrderData } from "src/thunks/order";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import { GetError } from "src/constant";
import { t } from "i18next";

export const CommonOrder = ({ onChange, value, disable }) => {
  const dispatch = useDispatch();

  const AllOrders = useSelector(
    (state) => state?.Order?.orderData?.result || []
  );

  const [svalue, setsValue] = useState("");

  useEffect(() => {
    let defaultData = _.find(AllOrders, (d) => d.id == value);
    setsValue(defaultData?.orderNo || "");
  }, [value, AllOrders]);

  const getAllOrderData = async () => {
    try {
      await dispatch(
        FetchOrderData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();
    } catch (err) {
      // toast.error(err?.message || "something issue");
      toast.error(
        GetError(
          err.response?.data?.errors || err?.message || t("toast.errorMessage")
        )
      );
    }
  };

  useEffect(() => {
    getAllOrderData();
  }, []);

  return (
    <Autocomplete
      disableClearable
      disabled={disable}
      disablePortal
      id="combo-box-demo"
      onChange={(e, value) => {
        onChange(value?.value || "");
      }}
      value={svalue}
      options={AllOrders?.map((d) => {
        return {
          label: d?.orderNo,
          value: d?.id,
        };
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("common.selectContract")}
          size="small"
        />
      )}
    />
  );
};
