import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchSchoolUserData = createAsyncThunk(
  "fetchSchoolUserData",
  async (model, thunkApi) => {
    try {
      // let res = await getAxios().get(
      //   `schoolUser?currentPage=${model?.currentPage || 0}&pageSize=${
      //     model?.pageSize || 0
      //   }&paging=${model?.paging || true}`
      // );
      let res = await getAxios().get(
        `school/user?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || true}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddSchoolUser = createAsyncThunk(
  "AddSchoolUser",
  async (model, thunkApi) => {
    try {
      // await getAxios().post("schoolUser", model);
      // let res = await getAxios().get(
      //   `schoolUser?currentPage=${model?.currentPage || 0}&pageSize=${
      //     model?.pageSize
      //   }&paging=${model?.paging}`
      // );

      await getAxios().post(`school/${model?.schoolId}/user`, model);
      let res = await getAxios().get(`school/user`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditSchoolUser = createAsyncThunk(
  "EditSchoolUser",
  async (model, thunkApi) => {
    try {
      // await getAxios().put(`schoolUser/${model?.id}`, model);
      // let res = await getAxios().get(
      //   `schoolUser?currentPage=${model?.currentPage || 0}&pageSize=${
      //     model?.pageSize || 0
      //   }&paging=${model?.paging || true}`
      // );

      await getAxios().put(
        `/school/${model?.schoolId}/user/${model?.id}`,
        model
      );

      let res = await getAxios().get(
        `school/user?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || true}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteSchoolUser = createAsyncThunk(
  "DeleteSchoolUser",
  async (model, thunkApi) => {
    try {
      // await getAxios().delete(`schoolUser/${model?.id}`, {
      //   id: model?.id,
      // });
      // let res = await getAxios().get(
      //   `schoolUser?currentPage=${model?.currentPage || 0}&pageSize=${
      //     model?.pageSize
      //   }&paging=${model?.paging}`
      // );

      await getAxios().delete(`school/user/${model?.id}`, {
        id: model?.id,
      });

      let res = await getAxios().get(
        `school/user?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || true}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
