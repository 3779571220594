import React from "react";
import { Box, Button, Modal } from "@mui/material";
import "./deletemodal.scss";
import deleteIcon from "src/assets/images/ModalDeleteIcon.png";
import { InlineLoader } from "../InlineLoader";
import { t } from "i18next";

export const NewModal = ({
  togal,
  toggleHandler,
  title,
  subtitle,
  children,
  deleteButtonHandler,
  message,
  loading,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "495px",
    height: "280px",
    bgcolor: "background.paper",
    overflowY: "scroll",
    borderRadius: "18px",
  };

  return (
    <Modal
      open={togal}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {loading ? <InlineLoader /> : ""}
        <div style={{ height: "100%" }} className="deleteModalMain">
          <div className="deletemainHeaderDelete">
            <div style={{ paddingTop: 26, marginInline: "50px" }}>
              {title && (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBlock: "10px",
                    }}
                  >
                    <img src={deleteIcon} alt="imgNotFound"></img>
                  </div>

                  <div className="title">{message?.message}</div>
                  <div>{message?.scheduleName?.scheduleNo}</div>
                </div>
              )}
            </div>
            <div
              style={{
                marginTop: "1rem",
                paddingInline: "3rem",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "black",
                  fontSize: "15px",
                  width: "122px",
                  color: "white",
                  borderRadius: "22px",
                  "&:hover": {
                    backgroundColor: "black", // Change to the desired hover color
                  },
                }}
                color="primary"
                onClick={() => deleteButtonHandler()}
                fullWidth
              >
                {t("common.yes")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "black",
                  fontSize: "15px",
                  width: "122px",
                  color: "white",
                  borderRadius: "22px",
                  "&:hover": {
                    backgroundColor: "black", // Change to the desired hover color
                  },
                }}
                onClick={() => toggleHandler()}
                fullWidth
              >
                {t("common.no")}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
