import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonVehicles } from "src/components/dropdowns/Vehicle";
import { ReportValidationSchema } from "src/helpers/validation";
import { GetReportData } from "src/thunks/report";
import { GetError, errorMessageChangeInFinish } from "src/constant";
import { DownloadFile } from "src/thunks/common";
import Table from "src/components/Table";
import MyDateInput from "src/components/DatePicker";
import "./ReportsStyle.scss";
import { InlineLoader } from "src/components/InlineLoader";
import moment from "moment";

const Report = () => {
  // ------------------------------- Table Columns ----------------------------
  const columns = [
    { title: t("report.orderId"), field: "orderId" },
    { title: t("report.itemName"), field: "itemName" },
    { title: t("report.date"), field: "date" },
    { title: t("report.routeName"), field: "routeName" },
    { title: t("report.sourceStop"), field: "from" },
    { title: t("report.destinationStop"), field: "to" },
    { title: t("report.startTime"), field: "departTime" },
    { title: t("report.endTime"), field: "arrivalTime" },
    { title: t("report.status"), field: "status" },
    { title: t("report.contractArea"), field: "contractArea" },
    { title: t("report.vehicle"), field: "vehicle" },
    { title: t("report.freeSeats"), field: "freeSeats" },
    { title: t("report.timeSpend"), field: "timeSpent" },
    { title: t("report.kilometers"), field: "kilometers" },
    { title: t("report.dropOff"), field: "dropOff" },
  ];

  // -------------------- Global Objects ------------------------
  const reportStateData = useSelector((state) => state?.Report || []);
  const loading = useSelector((state) => state?.Report?.loading);
  const [data, setData] = useState("");

  // -------------------- React Hooks ----------------------------
  const dispatch = useDispatch();

  // -------------------- React Hook Form --------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ReportValidationSchema),
  });

  // --------------------- API Call Functions ---------------------

  const onSubmitHandler = async (data) => {
    try {
      const parameters = {
        vehicleId: data?.vehicleId,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
      };
      setData(data);
      const res = await dispatch(GetReportData({ ...parameters }));

      if (res?.payload?.reportRecords?.length > 0) {
        toast.success(t("report.toastmsg1"));
      } else {
        toast.warning(t("report.toastmsg2"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const downloadExcelFile = async () => {
    try {
      const res = reportStateData?.reportData?.filePath;

      console.log("Response state : ", reportStateData);

      if (res) {
        const dowloadFile = await dispatch(
          DownloadFile({
            filePath: res,
            // VehicleID_FromDateYmd_ToDateYmd
            fileName: `${
              reportStateData?.reportData?.reportRecords[0].vehicle
            }_FromDate_${moment(data?.fromDate).format(
              "YY_MM_DD"
            )}_ToDate_${moment(data?.toDate).format("YY_MM_DD")}.csv`,
          })
        );

        if (dowloadFile) {
          toast.success(t("report.toastmsg3"));
          reset({
            vehicleId: "",
            fromDate: "",
            toDate: "",
          });
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // --------------------- Local Helper Functions --------------------

  const mapData = (data) => {
    return data.map((reportData) => {
      return {
        ...reportData,
      };
    });
  };

  return (
    <div className="allcontainer">
      {loading ? <InlineLoader /> : ""}
      <div className="report-container">
        <div className="report-heading-container">
          <Typography className="report-heading">
            {t("report.report")}
          </Typography>
        </div>

        <div className="report-body-container">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container xs={11.8} className="form-container">
              <Grid container xs={10} className="form-sub-container">
                <Grid item xs={3} className="vehicle-container">
                  <Typography className="forminputTitle">
                    {t("schedule.vehicleNumber")}
                    <span className="asteric">*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name="vehicleId"
                    render={({ field: { onChange, value } }) => (
                      <CommonVehicles onChange={onChange} value={value} />
                    )}
                  />
                  <span className="errorMessageTitle">
                    {errors?.vehicleId?.message
                      ? errorMessageChangeInFinish("schedule.vehicleNumber")
                      : ""}
                  </span>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="forminputTitle">
                    {t("schedule.fromDate")} <span className="asteric">*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name="fromDate"
                    render={({ field: { onChange, value } }) => {
                      return <MyDateInput onChange={onChange} value={value} />;
                    }}
                  />
                  <span className="errorMessageTitle">
                    {errors?.fromDate?.message
                      ? errorMessageChangeInFinish("schedule.fromDate")
                      : ""}
                  </span>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="forminputTitle">
                    {t("schedule.toDate")} <span className="asteric">*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field: { onChange, value } }) => {
                      return <MyDateInput onChange={onChange} value={value} />;
                    }}
                  />
                  <span className="errorMessageTitle">
                    {errors?.toDate?.message
                      ? errorMessageChangeInFinish("schedule.toDate")
                      : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container xs={1.8} className="button-container">
                <Button
                  type="submit"
                  className="saveButton"
                  sx={{
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                  size="large"
                >
                  {t("sidebar.showData")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      {reportStateData?.reportData?.reportRecords?.length > 0 ? (
        <div className="table-conatainer">
          <Grid className="table-sub-container">
            <Typography className="reportDetails">
              {t("common.reportDetails")}
            </Typography>
            <Button
              type="submit"
              className="saveButton button-donwload"
              size="large"
              onClick={() => downloadExcelFile()}
            >
              {t("common.downloadReport")}
            </Button>
          </Grid>
          <div>
            <Table
              columns={columns}
              Data={mapData(reportStateData?.reportData?.reportRecords || [])}
              loading={loading}
              // headerTitle={t("common.driver")}
              tableTittle={false}
              options={{
                actionsColumnIndex: -1,
                paging: false,
                search: false,
                toolbar: false,
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Report;
