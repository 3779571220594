/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Button, SwipeableDrawer } from "@mui/material";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  ArrowBack,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { imageApi } from "src/settings";
import Logo from "src/assets/images/Group 178038.png";
import MenusArray from "../sidebars/menu";
import { useThemeContext } from "src/contexts/theme";
import { DriverShiftStartAndEnd, getUserById } from "src/thunks/auth";
import { GetError } from "src/constant";
import EngFlag from "../../../assets/images/English_Language_Flag.png";
import FinFlag from "../../../assets/images/Finland_Flag.jpg";
import "./style.scss";
import { LoadingScreen } from "src/components/Loaders/componentLoader";

//  ------------------- Local Helper Functions ------------------

function Toggle({ label, value, checked, onChange, ...props }) {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input danger"
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
        {label}
      </label>
    </div>
  );
}

function Iconbutton({
  id,
  icon,
  backgroundColor,
  color,
  isDropdownToggle,
  ...props
}) {
  const getClassName = () => {
    let clsName = `btn border-0 bg-${backgroundColor}`;
    if (isDropdownToggle) {
      clsName = clsName + " dropdown-toggle";
    }
    if (color) {
      clsName = clsName + ` text-${color}`;
    }
    return clsName;
  };

  return (
    <button
      data-testid="iconButtonTestId"
      className={getClassName()}
      type="button"
      id={id}
      {...props}
    >
      {icon}
    </button>
  );
}

const NavBar = () => {
  // ------------------ Global States --------------------
  const UserData = useSelector((state) => state?.Auth?.userDetail || {});
  const driverShift = useSelector((state) => state?.Auth?.driverShift || {});
  const isShiftStarted = useSelector((state) => state?.Auth?.shiftStarted);
  const loading = useSelector((state) => state?.Auth?.loading);

  // ------------------ React Hooks-----------------------
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeObj = useThemeContext();
  const [t, I18n] = useTranslation();

  // ------------------ Local Component States ----------
  const [openSideNavbar, setOpenSideNavbar] = useState(false);
  const [languageState, setLanguageState] = useState(false);
  const [newMenues, setMenues] = useState([...MenusArray]);
  const [isDarkMode, changeMode] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // ------------------ API Call Functions --------------
  const getUserData = async () => {
    try {
      let id = localStorage.getItem("id");
      await dispatch(getUserById(id)).unwrap();
    } catch (error) {
      // toast.error(err?.message || "Error !!");
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            "Error In To Get User."
        )
      );
    }
  };

  const DriverShiftStartAndEndFun = async () => {
    try {
      const data = await dispatch(
        DriverShiftStartAndEnd({ driverId: UserData?.transactionId })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            t("common.errorMessage")
        )
      );
    }
  };

  const navigateTo = async () => {
    try {
      let loginRes = UserData;
      if (loginRes.token) {
        if (loginRes?.roles?.includes("Driver")) {
          navigate("/app/drivermaster");
        } else if (loginRes?.roles?.includes("Contact")) {
          navigate("/app/parentmaster");
        } else if (loginRes?.roles?.includes("SchoolCoordinator")) {
          navigate("/app/schoolAdminmaster");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            "Problem To Navigate"
        )
      );
    }
  };

  const navigateToPrivacy = async () => {
    try {
      let loginRes = UserData;
      if (loginRes.token) {
        if (loginRes?.roles?.includes("Driver")) {
          navigate("/app/driverpolices");
        } else if (loginRes?.roles?.includes("Contact")) {
          navigate("/app/parentmaster");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            "Problem To Navigate"
        )
      );
    }
  };

  // ------------------ Local Helper Functions ----------
  const languageToggleHandler = () => {
    setLanguageState(!languageState);
  };

  const sidbarToggleHandler = () => {
    setOpenSideNavbar(!openSideNavbar);
    setLanguageState(false);
  };

  const toggleTheme = () => {
    setTimeout(() => {
      if (themeObj.theme === "light") {
        themeObj.changeTheme("dark");
      } else {
        themeObj.changeTheme("light");
      }
    }, 100);
  };

  const getThemedTextColor = () => {
    if (themeObj.theme === "light") {
      return "text-dark";
    } else {
      return "text-white";
    }
  };

  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };

  const onFirstlayerClick = (e) => {
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
  };

  const nestedLinkClicked = (e) => {
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
    e.target.parentElement.parentElement.parentElement.children[0].classList.add(
      "active"
    );
  };

  const changeLanguage = (lnCode) => {
    I18n.changeLanguage(lnCode);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (code) => {
    window.location.reload();
    // code ? changeLanguage(code) : "en";
    if (code === "en" || code === "fi") {
      localStorage.setItem("Languagecode", code);
      changeLanguage(code);
    } else {
      changeLanguage(localStorage.getItem("Languagecode"));
    }
    setAnchorEl(null);
  };

  // ------------------ Component Life Cycle Methods ----
  useEffect(() => {
    let userRole = UserData?.roles[0];
    let filterModules = _.map(
      _.filter(newMenues, (d) => d.roles.includes(userRole)),
      (d) => {
        let temp = { ...d };
        if (temp.subMenu) {
          temp["subMenu"] = _.filter(temp["subMenu"], (d1) =>
            d1.roles.includes(userRole)
          );
        }
        return temp;
      }
    );
    setMenues([...filterModules]);
  }, [UserData]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    changeMode(themeObj.theme === "dark");
  }, [themeObj]);

  return (
    <div className={`${getThemedBgColor()} navbar-container`}>
      {loading ? <LoadingScreen /> : ""}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className={`${getThemedBgColor()}`}
      >
        <div className="terms_condition d-none d-md-flex">
          <span
            className="terms_condition_text"
            style={{ color: "white" }}
            onClick={() => navigateToPrivacy()}
          >
            {/* {t("common.termsAndPolicies")} */}
          </span>
          <>
            <span
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="language_text"
            >
              {t("common.Language")} <LanguageIcon className="language-icon" />
            </span>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleClose("fi")}>
                {/* {t("common.finish")} */}
                <img
                  src={FinFlag}
                  alt="Finnish"
                  style={{ width: "60px", height: "30px" }}
                />
              </MenuItem>
              <MenuItem onClick={() => handleClose("en")}>
                {/* {t("common.english")} */}
                <img
                  src={EngFlag}
                  alt="English"
                  style={{ width: "60px", height: "30px" }}
                />
              </MenuItem>
            </Menu>
          </>
        </div>
      </div>
      <nav className={`navbar navbar-expand-md  ${getThemedBgColor()}`}>
        <div className="container-fluid p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MenuIcon
              className="menu-icon"
              sx={{ color: "white", width: "41px", height: "34px" }}
              onClick={() => sidbarToggleHandler()}
            />
            <a
              // href=""

              className={`d-flex align-items-start mb-md-0 me-md-auto ${getThemedTextColor()} text-decoration-none `}
            >
              <span
                className={` ${
                  themeObj.theme === "light" ? "bg-primary" : "bg-transparent"
                }`}
                onClick={() => navigateTo()}
                style={{ cursor: "pointer" }}
              >
                <img src={Logo} alt="Busteri" className="busteri-logo" />
              </span>
            </a>
          </div>

          <span>
            {newMenues.map((item, index) => {
              if (item?.button) {
                return (
                  <Button
                    onClick={() => DriverShiftStartAndEndFun()}
                    className="d-md-none start-end-shift-button"
                    sx={{
                      height: "35px",
                      flexShrink: 0,
                      borderRadius: "20.5px",
                      border: "1px solid #AFF4FF",
                      background: "#00B3D1",
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: "15.429px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      textTransform: "none",
                      paddingInline: "20px",
                    }}
                  >
                    {/* {!driverShift?.endDateTime && driverShift?.startDateTime
                      ? t("common.endShift")
                      : t("common.startShift")} */}

                    {isShiftStarted
                      ? t("common.endShift")
                      : t("common.startShift")}
                  </Button>
                );
              }
            })}
          </span>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div
              className="d-flex justify-content-end aling-items-center w-100 flex-md-row"
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "55%",
                  marginRight: 50,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ul
                  className="navbar-nav"
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  {newMenues.map((item, index) => {
                    if (item?.button) {
                      return (
                        <Button
                          onClick={() => DriverShiftStartAndEndFun()}
                          sx={{
                            height: "37px",
                            flexShrink: 0,
                            borderRadius: "20.5px",
                            // border: "1px solid #AFF4FF",
                            background: "#00B3D1",
                            color: "#FFF",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontSize: "15.429px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            textTransform: "none",

                            // paddingInline: "20px",
                          }}
                          className="d-none d-md-block"
                        >
                          {/* {!driverShift?.endDateTime &&
                          driverShift?.startDateTime
                            ? t("common.endShift")
                            : t("common.startShift")} */}

                          {isShiftStarted
                            ? t("common.endShift")
                            : t("common.startShift")}
                        </Button>
                      );
                    } else if (!item.subMenu) {
                      return (
                        <li key={index} className="nav-item">
                          <NavLink
                            to={item.path}
                            className={`nav-link text-start ${getThemedTextColor()} ${
                              item.vissbleOnSmallScrren
                                ? "d-none d-md-block"
                                : "d-md-block"
                            }`}
                            aria-current="page"
                            onClick={() => {
                              onFirstlayerClick();
                            }}
                          >
                            {item.icon}
                            {t(item.label)}
                          </NavLink>
                        </li>
                      );
                    } else {
                      return (
                        <li key={index} className="nav-item dropdown">
                          <a
                            className={`nav-link nested-parent text-start dropdown-toggle  ${getThemedTextColor()} ${
                              item.vissbleOnSmallScrren
                                ? ""
                                : "d-none d-md-block"
                            }`}
                            to={"#"}
                            id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {item.icon}
                            {t(item.label)}
                          </a>
                          <ul
                            style={{ border: "0px" }}
                            className={`dropdown-menu ${getThemedBgColor()}`}
                            aria-labelledby="navbarDropdownMenuLink"
                          >
                            {item.subMenu.map((nestedItem, i) => {
                              return (
                                <li key={`nested-${i}`} className="nav-item">
                                  <NavLink
                                    onClick={nestedLinkClicked}
                                    to={nestedItem.path}
                                    className={`nav-link text-start  ${getThemedTextColor()} ${
                                      item.vissbleOnSmallScrren
                                        ? ""
                                        : "d-none d-md-block"
                                    }`}
                                    aria-current="page"
                                  >
                                    {nestedItem.icon}
                                    {t(nestedItem.label)}
                                  </NavLink>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>

              <div
                className="d-none d-md-flex  aling-items-center"
                style={{ width: "25%", justifyContent: "space-around" }}
              >
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <div
                        className="profile-dropdown"
                        style={{}}
                        {...bindTrigger(popupState)}
                      >
                        <img
                          src={
                            UserData?.profileUrl
                              ? `${imageApi}${UserData?.profileUrl}`
                              : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                          }
                          alt="User Profile"
                          className="rounded-circle me-2"
                        />
                        <span className="profile-dropdown-text">
                          {UserData?.firstName || "User"}
                        </span>{" "}
                        <KeyboardArrowDownSharpIcon />
                      </div>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => navigate("/app/profile")}>
                          {t("common.Profile")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            localStorage.removeItem("userDetail");
                            localStorage.removeItem("token");
                            localStorage.removeItem("id");
                            navigate("/login");
                          }}
                        >
                          {t("common.LogOut")}
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </div>
            </div>
          </div>
        </div>
        <SwipeableDrawer
          anchor={"left"}
          open={openSideNavbar}
          onClose={() => sidbarToggleHandler()}
        >
          <Box
            sx={{
              width: 270,
              background: "black",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            role="presentation"
            className={`swipebar-box ${getThemedBgColor()}`}
          >
            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                cursor: "pointer",
              }}
              className="arrow-back-container"
              onClick={() => sidbarToggleHandler()}
            >
              <ArrowBack
                sx={{
                  border: "1px solid white",
                  borderRadius: "100%",
                  padding: "2px",
                  marginTop: "25px",
                  marginRight: "25px",
                  height: "30px",
                  width: "30px",
                }}
              />
            </div>
            <ul className="nav nav-pills flex-column mb-auto">
              {newMenues.map((item, index) => {
                if (item?.button) {
                  return (
                    <Button
                      onClick={() => DriverShiftStartAndEndFun()}
                      sx={{
                        width: "151px",
                        height: "33px",
                        flexShrink: 0,
                        borderRadius: "20.5px",
                        border: "1px solid #AFF4FF",
                        background: "#00B3D1",
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Albert Sans",
                        fontSize: "11.429px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                      fullWidth
                      className="d-none"
                    >
                      {/* {!driverShift?.endDateTime && driverShift?.startDateTime
                        ? t("common.endShift")
                        : t("common.startShift")} */}
                      {isShiftStarted
                        ? t("common.endShift")
                        : t("common.startShift")}
                    </Button>
                  );
                } else if (!item.subMenu) {
                  return (
                    <li
                      key={index}
                      className="nav-item"
                      style={{ listStyleType: "none" }}
                    >
                      <NavLink
                        to={item.path}
                        className={`nav-link text-start ${getThemedTextColor()} ${
                          item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                        }`}
                        style={{ marginTop: "10px" }}
                        aria-current="page"
                        onClick={() => {
                          sidbarToggleHandler();
                          onFirstlayerClick();
                        }}
                      >
                        {item.icon}
                        {t(item.label)}
                      </NavLink>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} className="nav-item dropdown">
                      <a
                        className={`nav-link nested-parent text-start dropdown-toggle  ${getThemedTextColor()} ${
                          item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                        }`}
                        to={"#"}
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {item.icon}
                        {t(item.label)}
                      </a>
                      <ul
                        style={{ border: "0px" }}
                        className={`dropdown-menu ${getThemedBgColor()}`}
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        {item.subMenu.map((nestedItem, i) => {
                          return (
                            <li key={`nested-${i}`} className="nav-item">
                              <NavLink
                                onClick={nestedLinkClicked}
                                to={nestedItem.path}
                                className={`nav-link text-start  ${getThemedTextColor()} ${
                                  item.vissbleOnSmallScrren
                                    ? ""
                                    : "d-none d-md-block"
                                }`}
                                aria-current="page"
                              >
                                {nestedItem.icon}
                                {t(nestedItem.label)}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                }
              })}
            </ul>

            <div
              style={{
                marginLeft: "40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",

                height: "100%",
              }}
            >
              <div>
                <div
                  style={{
                    color: "white",
                    marginTop: "20px",
                    fontSize: "17px",
                  }}
                >
                  <div onClick={() => languageToggleHandler()}>
                    {t("common.Language")}
                    <span>
                      {languageState ? (
                        <KeyboardArrowUp
                          style={{ width: "40px", height: "30px" }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          style={{ width: "40px", height: "30px" }}
                        />
                      )}
                    </span>
                  </div>

                  {languageState && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <li
                        style={{ fontSize: "15px", marginTop: "10px" }}
                        onClick={() => handleClose("fi")}
                      >
                        {/* {t("common.finish")} */}
                        <img
                          src={FinFlag}
                          alt="Finnish"
                          style={{ width: "60px", height: "30px" }}
                        />
                      </li>
                      <li
                        style={{
                          fontSize: "15px",
                          marginTop: "10px",
                          fontWeight: "400px",
                        }}
                        onClick={() => handleClose("en")}
                      >
                        {/* English */}
                        {/* {t("common.english")} */}

                        <img
                          src={EngFlag}
                          alt="English"
                          style={{ width: "60px", height: "30px" }}
                        />
                      </li>
                    </div>
                  )}
                </div>
                {/* <ul className="nav nav-pills flex-column mb-auto">
                  {[{
                    path: "/app/faq",
                    label: "FAQ's",
                    vissbleOnSmallScrren: true
                  }, { label: "Terms And Security", path: "/app/driverpolices", vissbleOnSmallScrren: true }].map((item, index) => {
                    return <li
                      key={index}
                      className="nav-item nav-item1"
                      style={{ listStyleType: "none" }}
                    >
                      <NavLink
                        to={item.path}
                        className={`nav-link text-start ${getThemedTextColor()} ${item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                        style={{ marginTop: "10px" }}
                        aria-current="page"
                        onClick={() => {
                          sidbarToggleHandler()
                          onFirstlayerClick();
                        }}
                      >
                        <NavLink
                          to={item.path}
                          className={`nav-link text-start ${getThemedTextColor()} ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          style={{ marginTop: "10px" }}
                          aria-current="page"
                          onClick={() => {
                            sidbarToggleHandler();
                            onFirstlayerClick();
                          }}
                        >
                          {item.icon}
                          {t(item.label)}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul> */}

                <div
                  onClick={() => {
                    localStorage.removeItem("userDetail");
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    navigate("/login");
                  }}
                  style={{
                    color: "white",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  Log Out
                </div>
              </div>
              <div>
                <div
                  onClick={() => {
                    sidbarToggleHandler();
                    navigate("/app/profile");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      border: "0.5px soli     d green",
                      width: "35px",
                      height: "35px",
                      borderRadius: "100%",
                    }}
                  >
                    <img
                      src={
                        UserData?.profileUrl
                          ? `${imageApi}${UserData?.profileUrl}`
                          : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                      }
                      alt="not Found"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "100%",
                        padding: "2px",
                      }}
                    ></img>
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontSize: "15px",
                      fontFamily: "Albert Sans",
                      marginLeft: "7px",
                    }}
                  >
                    {UserData?.firstName}
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "35px",
                    color: "#505050",
                    fontSize: "10px",
                    marginTop: "13px",
                  }}
                >
                  Copyright © 2023 Busteri. All rights reserved.
                </div>
              </div>
            </div>
          </Box>
        </SwipeableDrawer>
      </nav>
      <div
        className={`d-flex d-md-none  aling-items-end justify-content-end ${getThemedBgColor()}`}
      >
        <div className="dropdown dropstart m-x-2  d-none">
          <Iconbutton
            icon={<i className={`bi bi-globe`}></i>}
            id="dropdownMenuButtonSetting"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            backgroundColor={"transparent"}
            isDropdownToggle={true}
          />

          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButtonSetting"
          >
            <li>
              <a
                onClick={() => changeLanguage("en")}
                className={`dropdown-item ${
                  I18n.language === "en" ? "active" : ""
                }`}
                href="#"
              >
                {/* English */}
                {t("common.english")}
              </a>
            </li>
            <li>
              <a
                onClick={() => changeLanguage("fi")}
                className={`dropdown-item ${
                  I18n.language === "fi" ? "active" : ""
                }`}
                href="#"
              >
                {t("common.finish")}
              </a>
            </li>
          </ul>
        </div>
        <div className="dropdown dropstart  d-none">
          <Iconbutton
            icon={<i className={`bi bi-gear`}></i>}
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            backgroundColor={"transparent"}
            isDropdownToggle={true}
          />
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div className="dropdown-item">
                <Toggle
                  label="Dark Mode"
                  id="flexSwitchCheckDefault"
                  value={isDarkMode}
                  checked={isDarkMode}
                  onChange={toggleTheme}
                />
              </div>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </div>
        <div className="dropdown dropstart d-none ">
          <a
            href="#"
            className={`d-flex align-items-center ${getThemedTextColor()} text-decoration-none dropdown-toggle mx-2`}
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            <img
              src="https://github.com/mdo.png"
              alt=""
              width="32"
              height="32"
              className="rounded-circle me-2"
            />
            <strong>User</strong>
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownUser1">
            <li>
              <a
                className="dropdown-item"
                href="#"
                role={"button"}
                onClick={() => {
                  toggleTheme();
                }}
              >
                change theme
              </a>
            </li>

            <li>
              <a className="dropdown-item" href="#">
                SignOut
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
