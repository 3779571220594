import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchSchoolData = createAsyncThunk(
  "fetchSchoolData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`school?currentPage=${model.currentPage || 0}&pageSize=${model?.pageSize || 0}&paging=${model?.paging}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddSchool = createAsyncThunk(
  "AddSchool",
  async (model, thunkApi) => {
    try {
      await getAxios().post("school", model);
      let res = await getAxios().get(`school?currentPage=${model.currentPage || 0}&pageSize=${model?.pageSize || 0}&paging=${model?.paging}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditSchool = createAsyncThunk(
  "EditSchool",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`school/${model?.id}`, model);
      let res = await getAxios().get(`school?currentPage=${model.currentPage || 0}&pageSize=${model?.pageSize || 0}&paging=${model?.paging}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteSchool = createAsyncThunk(
  "DeleteSchool",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`school/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(`school?currentPage=${model.currentPage || 0}&pageSize=${model?.pageSize || 0}&paging=${model?.paging}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
