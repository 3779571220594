import { Autocomplete, TextField } from "@mui/material";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const AutoCompleteComponent = ({
  onChange,
  value,
  options,
  id,
  title,
  disabled,
}) => {
  return (
    <>
      <Autocomplete
        disableClearable
        disablePortal
        disabled={disabled}
        id={id}
        value={value}
        onChange={onChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`${t("common.selectOnly")} ${title}`}
            size="small"
          />
        )}
      />
    </>
  );
};

export default AutoCompleteComponent;
