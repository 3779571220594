import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "src/helpers/validation";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { VerifyOtp, sendEmailOtp, signIn } from "src/thunks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetError } from "src/constant";
import logo from "src/assets/images/Group 178038.png";
import { useState } from "react";
import "./otp.scss";
import { useEffect } from "react";
import { t } from "i18next";

const SetOTP = ({ setShowContent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpData, _otpData] = useState({
    value: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    disable: true,
  });
  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    let interval = null;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timeLeft]);

  const handleChange = (value1, event) => {
    _otpData({
      ...otpData,
      [value1]: event.target.value,
    });
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleSubmitOtp = async () => {
    try {
      const finalOtp =
        otpData?.otp1 + otpData?.otp2 + otpData?.otp3 + otpData?.otp4;
      const res = await dispatch(
        VerifyOtp({ email: localStorage.getItem("email"), otp: finalOtp })
      ).unwrap();
      localStorage.setItem("verifyToken", res);
      if (res) {
        setShowContent("re-password");
      }
    } catch (error) {
      toast.error(GetError(error?.response?.data?.errors) || "something issue");
    }
  };

  const handleResendOtp = async () => {
    try {
      let res = await dispatch(
        sendEmailOtp({ email: localStorage.getItem("email") })
      ).unwrap();
      if (res) {
        toast.success("Otp sent");
        setTimeLeft(30);
      }
    } catch (error) {
      toast.error(GetError(error?.response?.data?.errors) || "something issue");
    }
  };

  return (
    <Grid
      item
      sx={{ backgroundColor: "white", paddingInline: "12px" }}
      xs={12}
      sm={7}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
        xs={12}
        sm={12}
        item
      >
        <Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: "490px",
            }}
            xs={12}
            sm={12}
            item
          >
            <Grid
              container
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <img
                  src={logo}
                  alt=""
                  style={{ width: "138px", height: "38.77px" }}
                />
              </Box>

              <Typography
                sx={{
                  fontFamily: "Albert Sans",
                  fontSize: { sm: "22px", xs: "20px" },
                  marginTop: "10px",
                  fontWeight: 700,
                }}
              >
                {t("common.otpMSG1")}
              </Typography>
              <div className="inputFormMain">
                <form>
                  <input
                    name="otp1"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otpData.otp1}
                    onChange={(e) => handleChange("otp1", e)}
                    tabIndex="1"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp2"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otpData.otp2}
                    onChange={(e) => handleChange("otp2", e)}
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp3"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otpData.otp3}
                    onChange={(e) => handleChange("otp3", e)}
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    name="otp4"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    value={otpData.otp4}
                    onChange={(e) => handleChange("otp4", e)}
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </form>
              </div>
              <div className="resendOtpContainer">
                {timeLeft == 0 ? (
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleResendOtp()}
                  >
                    {t("common.otpMSG2")}
                  </span>
                ) : (
                  <span>
                    {" "}
                    {t("common.otpMSG2")}({timeLeft})
                  </span>
                )}
              </div>
              <Box
                sx={{
                  fontSize: "15.84px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                }}
              >
                <Button
                  variant="text"
                  sx={{ color: "black" }}
                  onClick={() => setShowContent("email-id")}
                >
                  {t("common.goBack")}
                </Button>
                <Button
                  variant="text"
                  sx={{ color: "black" }}
                  onClick={() => navigate("/login")}
                >
                  {t("common.backToLogin")}{" "}
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  fullWidth
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    height: { sm: "64.25px", xs: "50px" },
                    fontSize: { sm: "17.6px", xs: "14px" },
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                  onClick={() => handleSubmitOtp()}
                >
                  {" "}
                  {t("common.verifyOTP")}{" "}
                </Button>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "15px",
                    color: "#000000",
                    fontSize: "12.34px",
                  }}
                >
                  {t("common.footer")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SetOTP;
