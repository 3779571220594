import { createSlice } from "@reduxjs/toolkit";
import { AddNofications } from "../thunks/notifications";

const initState = {
  loading: false,
  notificationData: [],
};

const NotificationSlice = createSlice({
  name: "Report",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddNofications.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddNofications.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationData = action.payload;
      })
      .addCase(AddNofications.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default NotificationSlice.reducer;
