import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FetchAllCities } from "src/thunks/common";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import _ from "lodash";
import { GetError } from "src/constant";
import { t } from "i18next";
const AllCity = require("src/constant/fi.json");

export const CommonCity = ({ onChange, value, id }) => {
  const [svalue, setsValue] = useState("");

  const orderByCity = _.orderBy(AllCity, ["city"], ["asc"]);

  useEffect(() => {
    let defaultData = _.find(orderByCity, (d) => d.city == `${value}`);
    setsValue(defaultData?.city || "");
  }, [value, AllCity]);

  return (
    <Autocomplete
      disableClearable
      disablePortal
      id={id ? id : ""}
      onChange={(data, value) => onChange(value?.value)}
      value={svalue}
      options={orderByCity?.map((d) => {
        return {
          label: d.city,
          value: d.city,
        };
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("common.cityName")}
          size="small"
        />
      )}
    />
  );
};
