import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchCompanyData = createAsyncThunk(
  "fetchCompanyData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `company?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddCompany = createAsyncThunk(
  "AddCompany",
  async (model, thunkApi) => {
    try {
      await getAxios().post("company", model);
      let res = await getAxios().get(
        `company?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditCompany = createAsyncThunk(
  "EditCompany",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`company/${model?.id}`, model);
      let res = await getAxios().get(
        `company?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteCompany = createAsyncThunk(
  "DeleteCompany",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`company/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `company?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
