import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { WithNavBar } from "src/layouts/index,";
import Dashboard from "src/pages/dashboard";
import Vehicle from "src/pages/vehicle";
import Driver from "src/pages/Driver";
import School from "src/pages/school";
import Company from "src/pages/company";
import Usermanagement from "src/pages/user";
import ContactPerson from "src/pages/contact";
import SchoolUser from "src/pages/school_user";
import UserProfile from "src/pages/profile";
import RoutePage from "src/pages/route";
import Order from "src/pages/order";
import Schedule from "src/pages/schedule";
import DriverMaster from "src/pages/DriverMaster/dashboard";
import ParentMaster from "src/pages/parent_master/dashboard";
import UpcomingRides from "src/pages/DriverMaster/upcoming_rides";
import Rides from "src/pages/rides";
import SchoolAdminMaster from "src/pages/SchoolAdminUser/dashboard";
import SchoolAdminRides from "src/pages/SchoolAdminUser/upcoming_rides";
import DriverPolices from "src/pages/DriverMaster/policies";
import ParentUpcomingRides from "src/pages/parent_master/upcoming_rides";
import Reports from "src/pages/report";
import Notifications from "src/pages/notifications";

import { useDispatch, useSelector } from "react-redux";
import { DriverShiftLog, signIn } from "src/thunks/auth";
import { toast } from "react-toastify";
import { GetError } from "src/constant";
import {
  GetCurrentRideByDriverId,
  GetCurrentRideForDriver,
} from "src/thunks/schedule";

const MainApp = (props) => {
  const userDetail = useSelector((state) => state?.Auth?.userDetail);

  const dispatch = useDispatch();
  const Location = useLocation();

  const getDriverShiftData = async (data) => {
    if (userDetail?.transactionId) {
      try {
        if (data?.driverId) {
          // await dispatch(
          //   DriverShiftLog({ id: userDetail?.transactionId })
          // ).unwrap();
          // await dispatch(
          //   GetCurrentRideForDriver({
          //     ...data,
          //     isTodaysSchedule: false,
          //   })
          // ).unwrap();
        } else {
          await dispatch(
            GetCurrentRideByDriverId({
              ...data,
              isUpcommingSchedule: false,
              isTodaysSchedule: true,
            })
          ).unwrap();
        }
      } catch (error) {
        toast.error(
          GetError(
            error.response?.data?.errors ||
              error?.message ||
              "Problem While Fetching Data. Please Try Again."
          )
        );
      }
    }
  };

  useEffect(() => {
    if (
      userDetail?.roles?.includes("SchoolCoordinator") ||
      userDetail?.roles?.includes("Driver") ||
      userDetail?.roles?.includes("Contacts")
    ) {
      const userRoleFind = userDetail?.roles.includes("Contacts")
        ? { contactId: userDetail?.transactionId }
        : userDetail?.roles.includes("SchoolCoordinator")
        ? { schoolUserId: userDetail?.transactionId }
        : { driverId: userDetail?.transactionId };

      getDriverShiftData(userRoleFind);
    }
  }, [userDetail]);

  return (
    <WithNavBar>
      <div className="container-fluid main-app-container mx-0">
        <Routes>
          <Route
            path={"/"}
            element={<Navigate replace to={`${Location.pathname}/home`} />}
          />
          <Route path={`home/*`} element={<Dashboard />} />
          <Route path={`driver/*`} element={<Driver />} />
          <Route path={`vehicle/*`} element={<Vehicle />} />
          <Route path={`school/*`} element={<School />} />
          <Route path={`company/*`} element={<Company />} />
          <Route path={`usermanagement/*`} element={<Usermanagement />} />
          <Route path={`contact/*`} element={<ContactPerson />} />
          <Route path={`schooluser/*`} element={<SchoolUser />} />
          <Route path={`profile/*`} element={<UserProfile />} />
          <Route path={`routes/*`} element={<RoutePage />} />
          <Route path={`order/*`} element={<Order />} />
          <Route path={`schedule/*`} element={<Schedule />} />
          <Route path={`driver/rides/*`} element={<Rides />} />
          <Route path={`drivermaster/*`} element={<DriverMaster />} />
          <Route path={`upcomingRides/*`} element={<UpcomingRides />} />
          <Route path={`driverpolices/*`} element={<DriverPolices />} />
          <Route path={`reports/*`} element={<Reports />} />
          <Route path={`parentmaster/*`} element={<ParentMaster />} />
          <Route path={`notifications/*`} element={<Notifications />} />

          <Route
            path={`parentUpcomingRides/*`}
            element={<ParentUpcomingRides />}
          />
          <Route path={`schoolAdminmaster/*`} element={<SchoolAdminMaster />} />
          <Route
            path={`schoolAdminUpcomingRides/*`}
            element={<SchoolAdminRides />}
          />
        </Routes>
      </div>
    </WithNavBar>
  );
};

export default MainApp;
