import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Close";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RouteValidationSchema } from "src/helpers/validation";
import { CommonDriver } from "src/components/dropdowns/Diver";
import { CommonCompany } from "src/components/dropdowns/Company";
import { CommonCity } from "src/components/dropdowns/City";
import { AddRoute, EditRoute } from "src/thunks/route";
import AllCites from "src/constant/fi.json";

import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import "./RouteStyle.scss";
import { InlineLoader } from "src/components/InlineLoader";
import { FetchCompanyData } from "src/thunks/company";
import AutoCompleteComponent from "src/components/AutoComplete";
import { FetchDriverData } from "src/thunks/driver";

const AddDriverForm = ({
  toggleHandler,
  editData,
  setEditFun,
  currentPage,
  pageSize,
}) => {
  // --------------- React Hooks -------------------
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.Route?.loading);
  const companyLoading = useSelector((state) => state?.Company?.loading);
  const driverLoading = useSelector((state) => state?.Driver?.loading);
  const userDetail = useSelector((state) => state?.Auth?.userDetail || []);
  const AllCompany = useSelector(
    (state) => state?.Company?.comapnyData?.result || []
  );
  const AllDrivers = useSelector(
    (state) => state?.Driver?.DriverData?.result || []
  );

  // --------------- Component Local States --------
  const [stops, setStops] = useState([
    {
      routeId: 0,
      name: "",
      sequence: 0,
    },
  ]);

  // ---------------- React Hook Forms -------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(RouteValidationSchema),
  });

  // -------------- API Call Functions -----------------

  const onSubmitHandler = async (data) => {
    // const stopss = stops.map((d, i) => {
    //   return {
    //     ...d,
    //     sequence: i + 1,
    //   };
    // });
    try {
      let newData = {
        name: data?.routeName || "",
        cityName: data?.cityName,
        companyId: parseInt(data?.companyId),
        driverId: parseInt(data?.driverID),
        isActive: true,
        // routeStops: [...stopss],
        pageSize: pageSize,
        currentPage: currentPage + 1,
        paging: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
      }
      const res = await dispatch(
        editData?.id ? EditRoute({ ...newData }) : AddRoute({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.routeEdited") : t("toast.routeAdded")
        );
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    if (data) {
      reset({
        routeName: data?.name || "",
        cityName: data?.cityName,
        companyId: data?.companyId,
        driverID: data?.driverId,
      });
      setStops([...(data?.routeStops || [])]);
    }
  };

  // ---------------- Local Helper Functions --------

  const addNewStop = () => {
    setStops([
      ...stops,
      {
        routeId: 0,
        name: "",
        sequence: uuidv4(),
      },
    ]);
  };

  const removeStop = (index) => {
    const updatedStops = stops.filter((_, i) => i !== index);
    setStops(updatedStops);
  };
  const getDropdownMetaData = async () => {
    try {
      const res = await dispatch(
        FetchCompanyData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      await dispatch(
        FetchDriverData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      if (res) {
        if (
          userDetail?.roles?.includes("Admin") ||
          userDetail?.roles?.includes("Organizer")
        ) {
          setValue("companyId", `${userDetail?.companyId}`);
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${
      defaultData?.name || defaultData?.city || defaultData?.userName || ``
    }`;
  };

  // ----------- Component Life Cycle Methods ---------------

  useEffect(() => {
    setEditData(editData);
    getDropdownMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      {companyLoading ? <InlineLoader /> : ""}
      {driverLoading ? <InlineLoader /> : ""}

      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("route.routeName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="routeName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("route.routeName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.routeName?.message
                  ? errorMessageChangeInFinish("route.routeName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.cityName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.companyName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="companyId"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Comapany"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllCompany, "name", "id")}
                    title={t("company.company")}
                    value={getValue(AllCompany, value, "id")}
                    disabled={
                      userDetail?.roles?.includes("Admin") ||
                      userDetail?.roles?.includes("Organizer")
                        ? true
                        : false
                    }
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.companyId?.message
                  ? errorMessageChangeInFinish("company.companyName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("route.driverName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="driverID"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Drivers"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllDrivers, "userName", "id")}
                    title={t("driver.driver")}
                    value={getValue(AllDrivers, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.driverID?.message
                  ? errorMessageChangeInFinish("route.driverName")
                  : ""}
              </span>
            </Grid>

            {/* <Grid item xs={12}>
              {stops.map((stop, index) => {
                return (
                  <div key={index}>
                    <Typography className="forminputTitle">
                      {t("route.stop")} {index + 1}{" "}
                      <span className="asteric">*</span>
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id={`stop-${index}`}
                        placeholder={placeHolderMessage("route.stop")}
                        variant="outlined"
                        size="small"
                        type="text"
                        onChange={({ target }) => {
                          let newStops = _.cloneDeep(stops);
                          newStops[index]["name"] = target.value;
                          setStops([...newStops]);
                        }}
                        value={stop?.name}
                        fullWidth
                      />
                      <CancelIcon
                        color="black"
                        sx={{
                          marginLeft: "10px",
                          height: "25px",
                          width: "25px",
                          cursor: "pointer",
                          backgroundColor: "#00B3D1",
                          borderRadius: "100%",
                          color: "white",
                          padding: "5px",
                        }}
                        onClick={() => removeStop(index)}
                      />
                    </Grid>
                  </div>
                );
              })}
              <span className="errorMessageTitle">
                {errors?.stop?.message
                  ? errorMessageChangeInFinish("croute.stop")
                  : ""}
              </span>
            </Grid> */}
            {/* <Grid item xs={12}>
              <Grid>
                <Add
                  sx={{
                    backgroundColor: "#00B3D1",
                    borderRadius: "100%",
                    color: "white",
                    height: "22px",
                    width: "22px",
                  }}
                />
                <Button
                  sx={{
                    fontFamily: "Albert Sans",
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "black",
                    fontWeight: 600,
                  }}
                  size="small"
                  onClick={addNewStop}
                >
                  {t("route.addNewStops")}
                </Button>
              </Grid>
            </Grid> */}
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddDriverForm;
