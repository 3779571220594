import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProfileUserValidationSchema } from "src/helpers/validation";
import { useForm, Controller, Form } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import "./profileStyle.scss";

import { getUserById } from "src/thunks/auth";
import { CommonCity } from "src/components/dropdowns/City";
import { EditUser } from "src/thunks/user";
import { t } from "i18next";
import { ImageUploading } from "src/components/ImageUpload";
import { LoadingScreen } from "src/components/Loaders/componentLoader";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserData = useSelector((state) => state?.Auth?.userDetail || {});
  const loading = useSelector((state) => state?.User?.loading);
  const [selectedFile, setSelectedFile] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ProfileUserValidationSchema),
  });

  const getUserData = async () => {
    try {
      let id = localStorage.getItem("id");
      await dispatch(getUserById(id)).unwrap();
    } catch (error) {
      // toast.error(err?.message || "Error !!");
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };
  const setEditData = (data) => {
    reset({
      companyId: UserData?.company,
      firstName: data?.firstName,
      middleName: data?.middleName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phoneNumber,
      street1: UserData?.roles?.includes("Driver")
        ? "Driver"
        : data?.address?.street1 || "",
      street2: UserData?.roles?.includes("Driver")
        ? "Driver"
        : data?.address?.street2 || "",
      country: UserData?.roles?.includes("Driver")
        ? "Driver"
        : data?.address?.country,
      state: UserData?.roles?.includes("Driver")
        ? "Driver"
        : data?.address?.state || "",
      cityName: UserData?.roles?.includes("Driver")
        ? "Driver"
        : data?.address?.cityName,
      zipAreaCode: UserData?.roles?.includes("Driver")
        ? "Driver"
        : data?.address?.zipAreaCode || "",
      zip: UserData?.roles?.includes("Driver") ? "Driver" : data?.address?.zip,
      role: data?.role,
      company: UserData?.company?.id,
      language: data?.language ? data.language : "EN",
    });
    setSelectedFile(data?.profileUrl);
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    setEditData(UserData);
  }, [UserData]);

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        companyId: UserData.company,
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        email: UserData.email,
        phoneNumber: data?.phone,
        address: {
          street1: data?.street1 || "",
          street2: data?.street2 || "",
          country: data?.country || "",
          state: data?.state || "",
          cityName: data?.cityName,
          zip: data?.zip,
          plotNumber: "",
          zipAreaCode: data?.zipAreaCode || "",
        },
        role: UserData.role,
        profileUrl: selectedFile?.url || selectedFile || "",
        isActive: true,
        language: data?.language ? data.language : "EN",
      };
      if (UserData?.id) {
        newData.id = UserData?.id;

        newData["address"]["id"] = UserData?.address?.id;
        newData["addressId"] = UserData?.address?.id;
        newData["companyId"] = UserData?.company?.id;
      }
      const res = await dispatch(EditUser({ ...newData })).unwrap();
      if (res) {
        toast.success(t("toast.userEdited"));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      // toast.error(error?.message);
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: window.innerHeight - 101,
      }}
      className="mainContainerProfile"
    >
      {loading ? <LoadingScreen /> : ""}
      <Grid sm={12} xs={12} container className="main-container-sub-header">
        <Grid className="sub-container-1" sm={4} xs={11}>
          <Grid className="heading-container">
            <Typography className="user-name">
              {t("common.profileDetails")}
            </Typography>
            <Typography className="heading-sub-text">
              {t("common.profileSubtext")}
            </Typography>
          </Grid>

          {/**
           * Details
           */}

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid
              container
              sm={12}
              xs={12}
              spacing={2}
              className="form-container"
            >
              <Grid item xs={12}>
                <ImageUploading
                  type="Company"
                  onChange={setSelectedFile}
                  value={selectedFile}
                  title="Upload Logo"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="forminputTitle">
                  {t("common.firstName")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.firstName")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.firstName?.message
                    ? errorMessageChangeInFinish("common.firstName")
                    : ""}
                </span>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Typography className="forminputTitle">
                  {t("common.lastName")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.lastName")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.lastName?.message
                    ? errorMessageChangeInFinish("common.lastName")
                    : ""}
                </span>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className="forminputTitle">
                  {t("common.phone")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.phone")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.phone?.message
                    ? errorMessageChangeInFinish("common.phone")
                    : ""}
                </span>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className="forminputTitle">
                  {t("common.email")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.email")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      disabled
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                style={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.street1")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="street1"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.street1")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.street1?.message
                    ? errorMessageChangeInFinish("common.street1")
                    : ""}
                </span>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.street2")}
                </Typography>
                <Controller
                  control={control}
                  name="street2"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.street2")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.cityName")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="cityName"
                  render={({ field: { onChange, value } }) => (
                    <CommonCity onChange={onChange} value={value} />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.cityName?.message
                    ? errorMessageChangeInFinish("common.cityName")
                    : ""}
                </span>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.state")}
                </Typography>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.state")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.Language")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="language"
                  defaultValue={UserData?.id ? UserData.language : ""}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      fullWidth
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        {placeHolderMessage(t("common.Language"))}
                      </MenuItem>
                      <MenuItem value={"EN"}>{t("common.english")}</MenuItem>
                      <MenuItem value={"FN"}>{t("common.finish")}</MenuItem>
                    </Select>
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.language?.message
                    ? errorMessageChangeInFinish("common.Language")
                    : ""}
                </span>
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.country")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.country")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.country?.message
                    ? errorMessageChangeInFinish("common.country")
                    : ""}
                </span>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.zip")}
                  <span className="red-dot">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="zip"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("common.zip")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.zip?.message
                    ? errorMessageChangeInFinish("common.zip")
                    : ""}
                </span>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: UserData?.roles?.includes("Driver") && "none",
                }}
              >
                <Typography className="forminputTitle">
                  {t("common.zipAreaDescription")}
                </Typography>
                <Controller
                  control={control}
                  name="zipAreaCode"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      placeholder={`${t("common.enter")} ${t(
                        "common.zipAreaDescription"
                      )}`}
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid className="footerButtons" container>
              <Button
                type="submit"
                className="saveButton"
                sx={{
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                size="large"
              >
                {t("common.updateProfile")}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilePage;
