import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

// Get by id order items data
export const getByIdScheduleItems = createAsyncThunk(
  "getByIdScheduleItems",
  async (model, thunkApi) => {
    try {
      // let res = await getAxios().get(
      //   `ScheduleItem/GetByScheduleId?scheduleId=${model?.id}&itemName=${model?.itemName}&currentPage=1&pageSize=1000`
      // );

      let res = await getAxios().get(`schedule/${model?.id}/item`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteScheduleItemById = createAsyncThunk(
  "deleteScheduleItemById",
  async (model, thunkApi) => {
    try {
      // await getAxios().delete(`ScheduleItem/${model?.id}`);
      // let res = await getAxios().get(
      //   `ScheduleItem/GetByScheduleId?scheduleId=${model?.listId}&currentPage=1&pageSize=1000`
      // );
      await getAxios().delete(`schedule/${model?.orderId}/item/${model?.id}`);
      let res = await getAxios().get(`schedule/${model?.orderId}/item`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditScheduleOrder = createAsyncThunk(
  "EditScheduleOrder",
  async (model, thunkApi) => {
    try {
      // await getAxios().put(`ScheduleItem/${model?.id}`, model);
      // let res = await getAxios().get(
      //   `ScheduleItem/GetByScheduleId?scheduleId=${model?.id}&itemName=${model?.itemName}&currentPage=1&pageSize=1000`
      // );
      await getAxios().put(
        `schedule/${model?.orderId}/item/${model?.id}`,
        model
      );

      let res = await getAxios().get(`schedule/${model?.orderId}/item`);

      return res?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const sendUploadItemPaths = createAsyncThunk(
  "sendUploadItemPaths",
  async (model, thunkApi) => {
    try {
      // let data = await getAxios().post(`ScheduleItem`, {
      //   ...model,
      // });
      let data = await getAxios().put(`schedule/${model?.id}`, {
        ...model,
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const MoveScheduleItem = createAsyncThunk(
  "MoveScheduleItem",
  async (model, thunkApi) => {
    try {
      // await getAxios().post(`ScheduleItem/MoveScheduleItems`, model);
      // let res = await getAxios().get(
      //   `ScheduleItem/GetByScheduleId?scheduleId=${model?.moveToScheduleId}&currentPage=1&pageSize=1000`
      // );
      const res = await getAxios().put(
        `schedule/${model?.moveToScheduleId}/item/move`,
        model
      );

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
