import { createSlice } from "@reduxjs/toolkit";
import {
  AddSchedule,
  CopySchedule,
  DeleteSchedule,
  EditSchedule,
  FetchScheduleData,
  GetCurrentRideByDriverId,
  GetCurrentRideForDriver,
  GetScheduleByDriverId,
  GetScheduleBySchoolUserId,
  ReachedStop,
  RouteTravelHistoryForAddItemHistrory,
  StartDriverJourny,
} from "../thunks/schedule";

const initState = {
  loading: false,
  orderData: [],
  driverScheduleData: [],
  currentRide: [],
  schoolScheduleData: [],
};

const Schedule = createSlice({
  name: "Schedule",
  initialState: initState,
  reducers: {
    resetCurrentState: (state, action) => {
      state.driverScheduleData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchScheduleData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchScheduleData.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(FetchScheduleData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddSchedule.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(AddSchedule.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteSchedule.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(DeleteSchedule.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditSchedule.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(EditSchedule.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(CopySchedule.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CopySchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(CopySchedule.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetScheduleByDriverId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetScheduleByDriverId.fulfilled, (state, action) => {
        state.loading = false;
        state.driverScheduleData = action.payload;
      })
      .addCase(GetScheduleByDriverId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetCurrentRideByDriverId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetCurrentRideByDriverId.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRide = action.payload;
      })
      .addCase(GetCurrentRideByDriverId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetCurrentRideForDriver.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetCurrentRideForDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRide = action.payload;
      })
      .addCase(GetCurrentRideForDriver.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(StartDriverJourny.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(StartDriverJourny.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRide = action.payload;
      })
      .addCase(StartDriverJourny.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(
        RouteTravelHistoryForAddItemHistrory.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        RouteTravelHistoryForAddItemHistrory.fulfilled,
        (state, action) => {
          state.loading = false;
          state.currentRide = action.payload;
        }
      )
      .addCase(
        RouteTravelHistoryForAddItemHistrory.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(GetScheduleBySchoolUserId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetScheduleBySchoolUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolScheduleData = action.payload;
      })
      .addCase(GetScheduleBySchoolUserId.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { resetCurrentState } = Schedule.actions;

export default Schedule.reducer;
