import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchoolValidationSchema } from "src/helpers/validation";
import { AddSchool, EditSchool } from "src/thunks/school";
import { CommonCity } from "src/components/dropdowns/City";
import AllCites from "src/constant/fi.json";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import "./SchoolStyle.scss";
import { t } from "i18next";
import { InlineLoader } from "src/components/InlineLoader";
import _ from "lodash";
import AutoCompleteComponent from "src/components/AutoComplete";

const AddNewSchoolForm = ({
  toggleHandler,
  editData,
  setEditFun,
  currentPage,
  pageSize,
}) => {
  // ------------------ React Hooks ----------------------

  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.School?.loading);

  // ------------------ React Hook Form ------------------

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SchoolValidationSchema),
  });

  // ----------------- API Call Functions ---------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        name: data?.schoolName || "",
        webSite: "string",
        address: {
          street1: data?.street1 || "",
          street2: data?.street2 || "",
          country: data?.country || "",
          state: data?.state || "",
          cityName: data?.cityName || "",
          zip: data?.zip || "",
          plotNumber: data?.street1 || "",
          zipAreaCode: data?.zipAreaCode || "",
        },
        pageSize: pageSize,
        currentPage: currentPage + 1,
        paging: true,
        isActive: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
      }
      const res = await dispatch(
        editData?.id ? EditSchool({ ...newData }) : AddSchool({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.schoolEdited") : t("toast.schoolAdded")
        );
        setEditFun({});
        reset({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    reset({
      schoolName: data?.name || "",
      isActive: true,
      street1: data?.address?.street1 || "",
      street2: data?.address?.street2 || "",
      cityName: data?.address?.cityName || "",
      zip: data?.address?.zip || "",
      state: data?.address?.state || "",
      country: data?.address?.country || "",
      zipAreaCode: data?.address?.zipAreaCode || "",
    });
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  // --------------- Component Life Cycle Methods ---------------

  useEffect(() => {
    setEditData(editData);
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("school.schoolName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="schoolName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("school.schoolName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.schoolName?.message
                  ? errorMessageChangeInFinish("school.schoolName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street1")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="street1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street1")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.street1?.message
                  ? errorMessageChangeInFinish("common.street1")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street2")}{" "}
              </Typography>
              <Controller
                control={control}
                name="street2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street2")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.country")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.country")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.country?.message
                  ? errorMessageChangeInFinish("common.country")
                  : ""}
              </span>
            </Grid>

            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.cityName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => {
                  return <CommonCity onChange={onChange} value={value} />;
                }}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid> */}
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.cityName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutoCompleteComponent
                      id={"Cities"}
                      onChange={(e, value) => onChange(value?.value)}
                      options={getOptions(
                        _.orderBy(AllCites, ["city"], ["asc"]),
                        "city",
                        "city"
                      )}
                      title={t("common.cityName")}
                      value={getValue(AllCites, value, "city")}
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.state")}
              </Typography>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.state")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zip")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="zip"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.zip")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.zip?.message
                  ? errorMessageChangeInFinish("common.zip")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zipAreaDescription")}
              </Typography>
              <Controller
                control={control}
                name="zipAreaCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewSchoolForm;
