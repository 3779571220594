import { createSlice } from "@reduxjs/toolkit";
import {
  FetchVehicleData,
  AddVehicle,
  DeleteVehicle,
  EditVehicle,
} from "../thunks/vehicle";

const initState = {
  loading: false,
  vehicleData: [],
};

const VehicleSlice = createSlice({
  name: "Vehicle",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchVehicleData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchVehicleData.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleData = action.payload;
      })
      .addCase(FetchVehicleData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddVehicle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleData = action.payload;
      })
      .addCase(AddVehicle.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteVehicle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleData = action.payload;
      })
      .addCase(DeleteVehicle.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditVehicle.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicleData = action.payload;
      })
      .addCase(EditVehicle.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default VehicleSlice.reducer;
