import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyValidationSchema } from "src/helpers/validation";
import AllCites from "src/constant/fi.json";
import { AddCompany, EditCompany } from "src/thunks/company";
import AutoCompleteComponent from "src/components/AutoComplete";
import { ImageUploading } from "src/components/ImageUpload/index";
import { GetError, errorMessageChangeInFinish } from "src/constant";
import { InlineLoader } from "src/components/InlineLoader";
import "./CompanyStyle.scss";

const AddCompanyForm = ({
  toggleHandler,
  editData,
  setEditFun,
  rowsPerPage,
  page,
  currentPage,
}) => {
  // ---------------- Global States & Hooks -----------------
  const loading = useSelector((state) => state?.Company?.loading);
  const dispatch = useDispatch();

  // --------------- Local States --------------------------------
  const [selectedFile, setSelectedFile] = useState("");

  // ----------------- React Hook Form -----------------

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(CompanyValidationSchema),
  });

  // ----------------- Add & Edit API Calls -----------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        name: data?.companyName || "",
        webSite: data?.webSite || "",
        address: {
          street1: data?.street1 || "",
          street2: data?.street2 || "",
          country: data?.country || "",
          state: data?.State || "",
          cityName: data?.cityName || "",
          zip: data?.zip || "",
          plotNumber: data?.street1 || "",
          zipAreaCode: data?.zipAreaCode || "",
        },
        rowsPerPage: rowsPerPage,
        page: page,
        pageSize: rowsPerPage,
        currentPage: currentPage + 1,
        paging: true,
        logo: selectedFile?.url || selectedFile || "",
        isActive: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
      }
      const res = await dispatch(
        editData?.id ? EditCompany({ ...newData }) : AddCompany({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.companyEdited") : t("toast.companyAdded")
        );
        reset({});
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ----------------- Set Edit Data To React Hook Form -----------------

  const setEditData = (data) => {
    reset({
      companyName: data?.name || "",
      street1: data?.address?.street1 || "",
      street2: data?.address?.street2 || "",
      cityName: data?.address?.cityName || "",
      zip: data?.address?.zip || "",
      State: data?.address?.state || "",
      country: data?.address?.country || "",
      companyLogo: data?.logo || "",
      webSite: data?.webSite || "",
      zipAreaCode: data?.address?.zipAreaCode || "",
    });
    setSelectedFile(data?.logo || "");
  };

  // ---------------- Local Helper Functions --------------
  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  // ---------------- Life Cycle Methods -------------------

  useEffect(() => {
    setEditData(editData);
  }, [editData]);

  // ----------------- Return Statement -----------------

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2} className="formFilledSubContainer">
            <Grid item xs={12}>
              <span className="forminputTitle">{t("company.companyLogo")}</span>
              <ImageUploading
                type="Company"
                onChange={setSelectedFile}
                value={selectedFile}
                title="Upload Logo"
              />
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">
                {t("company.companyName")} <span className="asteric">*</span>
              </span>
              <Controller
                control={control}
                name="companyName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "company.companyName"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.companyName?.message
                  ? errorMessageChangeInFinish("company.companyName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">
                {t("company.companyWebSite")}
              </span>
              <Controller
                control={control}
                name="webSite"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "company.companyWebSite"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">
                {t("common.street1")}
                <span className="asteric">*</span>
              </span>
              <Controller
                control={control}
                name="street1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t("common.street1")}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.street1?.message
                  ? t("common.street1") + " " + t("common.isrequired")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">{t("common.street2")}</span>
              <Controller
                control={control}
                name="street2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t("common.street2")}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">
                {t("common.cityName")}
                <span className="asteric">*</span>
              </span>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? t("common.cityName") + " " + t("common.isrequired")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">
                {t("common.zip")}
                <span className="asteric">*</span>
              </span>
              <Controller
                control={control}
                name="zip"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t("common.zip")}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.zip?.message
                  ? t("common.zip") + " " + t("common.isrequired")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">
                {t("common.zipAreaDescription")}
              </span>
              <Controller
                control={control}
                name="zipAreaCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">{t("common.state")}</span>
              <Controller
                control={control}
                name="State"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t("common.state")}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="forminputTitle">{t("common.country")}</span>
              <span className="asteric">*</span>
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t("common.country")}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.country?.message
                  ? t("common.country") + " " + t("common.isrequired")
                  : ""}
              </span>
            </Grid>
          </Grid>
        </div>

        <div className="formfooterButton">
          <Button
            disabled={loading}
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCompanyForm;
