import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Checkbox, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { FetchLookupValuesForDropdown } from "src/thunks/common";
import { InlineLoader } from "../InlineLoader";
import { toast } from "react-toastify";
import "./lictypestyle.scss";
const LicenceType = ({ licData, editData }) => {
  // ---------------- React Hooks ---------------------
  const dispatch = useDispatch();

  // ----------------- Local States -------------------
  const [licTypes, setLicTypes] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTypes = async () => {
    try {
      const data = await dispatch(
        FetchLookupValuesForDropdown({ type: "License_Type" })
      ).unwrap();
      setStateForLicennce(data.data);
      setLoading(false);
    } catch (error) {
      toast.error(t("common.errorMessage"));
    }
  };

  const setStateForLicennce = async (data) => {
    let newData = [];
    data?.map((items, index) => {
      let newObj = {
        licenseTypeId: items?.id,
        expiryDate: "",
        isActive: true,
        isDelete: true,
        isDisabled: true,
        name: items?.name,
      };
      newData.push(newObj);
    });
    setLicTypes(newData);
  };

  const setStateForDisabled = (id) => {
    let newState = licTypes?.filter((items, index) => {
      if (items?.licenseTypeId == id) {
        items.isDisabled = !items.isDisabled;

        if (items?.isDisabled) {
          items.expiryDate = "";
        }
        if (items?.isDisabled == false) {
          items.id = 0;
          setSelectedOptions([...selectedOptions, { ...items }]);
        } else {
          const newSelectedItems = selectedOptions?.filter(
            (newitems, index) => {
              if (newitems?.licenseTypeId != id) {
                return newitems;
              }
            }
          );
          setSelectedOptions(newSelectedItems);
        }
        return items;
      } else {
        return items;
      }
    });
    setLicTypes(newState);
  };

  const handleDateChange = (e, data) => {
    const newData = [];
    selectedOptions?.map((ele, i) => {
      if (ele?.licenseTypeId == data?.licenseTypeId) {
        ele.expiryDate = e.target.value;
        newData.push(ele);
      } else {
        newData.push(ele);
      }
      setSelectedOptions(newData);
    });
    const newArray = [];
    licTypes?.map((ele, i) => {
      if (ele?.licenseTypeId == data?.licenseTypeId) {
        ele.expiryDate = e.target.value;
        newArray.push(ele);
      } else {
        newArray.push(ele);
      }
      setLicTypes(newArray);
    });
  };

  const setDataFromEdit = async (data) => {
    try {
      const fetchedData = await dispatch(
        FetchLookupValuesForDropdown({ type: "License_Type" })
      ).unwrap();

      setLoading(false);

      let newArray = [];
      let selecteds = [];

      fetchedData?.data?.forEach((fData, i) => {
        let push = false;
        data?.forEach((eData, index) => {
          if (fData?.id == eData?.licenseTypeId) {
            push = true;
            newArray.push({
              ...fData,
              id: eData?.id || 0,
              licenseTypeId: fData.id,
              isDisabled: false,
              expiryDate: eData?.expiryDate.split("T")[0],
              isDelete: false,
              driverId: editData?.id,
            });

            selecteds.push({
              ...fData,
              id: eData?.id || 0,
              licenseTypeId: fData.id,
              // expiryDate: eData?.expiryDate.split("T")[0],
              expiryDate: eData?.expiryDate,
              isDelete: false,
              driverId: editData?.id,
            });
          }
        });
        if (!push) {
          newArray.push({
            ...fData,
            id: 0,
            licenseTypeId: fData.id,
            isDisabled: true,
            expiryDate: "",
            isDelete: false,
            driverId: editData?.id,
          });
        }
      });

      setSelectedOptions(selecteds);

      setLicTypes(newArray);
    } catch (error) {}
  };

  useEffect(() => {
    if (!editData?.driverLicenses?.length > 0) {
      getTypes();
    }
  }, []);

  useEffect(() => {
    licData(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (editData?.driverLicenses?.length > 0) {
      setDataFromEdit(editData?.driverLicenses || []);
    }
  }, [editData]);

  return (
    <div className="lic-type-container">
      {loading ? <InlineLoader /> : ""}
      <Typography className="forminputTitle">
        {t("common.selectlicdata")}
      </Typography>
      <table>
        <tr>
          <th className="forminputTitle">{t("common.actin")}</th>
          <th className="forminputTitle">{t("driver.licenceType")}</th>
          <th className="forminputTitle">{t("driver.licenceExpDate")}</th>
        </tr>
        {licTypes?.map((items, index) => {
          return (
            <tr>
              <td>
                <Checkbox
                  onClick={() => {
                    setStateForDisabled(items?.licenseTypeId);
                  }}
                  checked={items?.isDisabled ? false : true}
                />
              </td>
              <td>{items?.name}</td>
              <td>
                <TextField
                  type="date"
                  size="small"
                  disabled={items?.isDisabled}
                  onChange={(e) => handleDateChange(e, items)}
                  value={!items?.isDisabled ? items?.expiryDate : ""}
                />
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default LicenceType;
