import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadRouteItem } from "src/thunks/route";
import { GetError } from "src/constant";
import { toast } from "react-toastify";
import { imageApi } from "src/settings";
import "./ImageUpload.scss";
import { LoadingScreen } from "../Loaders/componentLoader";

export const ImageUploading = ({ onChange, value, type, title }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    try {
      setLoading(true);
      let res = await dispatch(
        uploadRouteItem({ formData: formData, type: type })
      ).unwrap();
      if (res) {
        setLoading(false);
      }
      onChange(res[0]);
    } catch (error) {
      setLoading(false);
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            "Error In Uploading Route Item. Please Try Again."
        )
      );
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    document.getElementById("fileInput").click();
  };

  return (
    <Grid>
      {loading && <LoadingScreen />}
      <label htmlFor="fileInput" onClick={handleClick}>
        <Grid
          id="imageContainer"
          style={{
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          {value ? (
            <div className="circle">
              <img
                src={`${imageApi}${value?.url || value}`}
                alt="Selected"
                className="profile-pic"
              />
            </div>
          ) : (
            <div className="circle">
              <img
                className="profile-pic"
                src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
              />
            </div>
          )}
        </Grid>
      </label>
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </Grid>
  );
};
