import React, { useState, useEffect } from "react";
import _ from "lodash";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddDriverFrom from "./AddDriverForm.jsx";
import { FetchDriverData, DeleteDriver } from "src/thunks/driver.js";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { GetError } from "src/constant/index.js";
import Table from "../../../components/Table/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./DriverStyle.scss";

const DriverList = () => {
  // ---------------------- Table Columns -------------------------------

  let columns = [
    { title: t("company.company"), field: "companyName" },
    { title: t("common.fullName"), field: "fullName" },
    { title: t("common.email"), field: "email" },
    { title: t("common.phoneNumber"), field: "phoneNumber" },
    { title: t("driver.licenceNumber"), field: "licNo" },
    // { title: t("driver.licenceType"), field: "licenseType.name" },
    // {
    //   title: t("driver.licenceExpDate"),
    //   field: "licExpirationDate",
    //   render: (rowData) =>
    //     moment(rowData?.licExpirationDate).format("DD-MM-YYYY"),
    // },
    { title: t("common.note"), field: "notes" },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    driverStateData?.DriverData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // ----------------------- React Global States ------------------------
  const driverStateData = useSelector((state) => state?.Driver || []);
  const loading = useSelector((state) => state?.Driver?.loading);

  // ------------------------ React Hooks -------------------------------
  const dispatch = useDispatch();

  // ------------------- Component Local States ----------------------------

  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // --------------------- API Call Functions ------------------------------
  const getAllDriverData = async () => {
    try {
      await dispatch(
        FetchDriverData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteDriver({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.driverDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ---------------- Local Helper Functions --------------------

  const mapData = (data) => {
    return data.map((driverData) => {
      return {
        ...driverData,
        fullName: `${driverData?.user?.firstName || ""}  ${
          driverData?.user?.lastName || ""
        }`,
        profileUrl: `${driverData?.user?.profileUrl || ""}`,
        email: `${driverData?.user?.email || ""}`,
        phoneNumber: `${driverData?.user?.phoneNumber || ""}`,
        companyName: driverData?.user?.company?.name || "",
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  // ------------------- Life Cycle Methods ----------------------

  // useEffect(() => {
  //   getAllDriverData();
  // }, []);

  useEffect(() => {
    getAllDriverData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      {/* {loading ? <InlineLoader /> : ""} */}

      <Table
        loading={driverStateData?.loading}
        headerTitle={t("common.driver")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        Data={mapData(driverStateData?.DriverData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={driverStateData?.DriverData?.totalPages}
        totalItems={driverStateData?.DriverData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={editData?.id ? t("driver.editDriver") : t("driver.addNewDriver")}
        subtitle={
          editData?.id
            ? t("driver.editDriverProfile")
            : t("driver.addNewDriverProfile")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddDriverFrom
          currentPage={page + 1}
          pageSize={rowsPerPage}
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are You Sure To Delete?"}
        subtitle={"Yes"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={loading ? true : false}
      />
    </div>
  );
};
export default DriverList;
