import { createSlice } from "@reduxjs/toolkit";
import { FetchSchoolUserData, AddSchoolUser, DeleteSchoolUser, EditSchoolUser } from "../thunks/schoolUser";

const initState = {
    loading: false,
    schoolUserData: [],
};

const SchoolSlice = createSlice({
    name: "school",
    initialState: initState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(FetchSchoolUserData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(FetchSchoolUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.schoolUserData = action.payload;
            })
            .addCase(FetchSchoolUserData.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(AddSchoolUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(AddSchoolUser.fulfilled, (state, action) => {
                state.loading = false;
                state.schoolUserData = action.payload;
            })
            .addCase(AddSchoolUser.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(DeleteSchoolUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(DeleteSchoolUser.fulfilled, (state, action) => {
                state.loading = false;
                state.schoolUserData = action.payload;
            })
            .addCase(DeleteSchoolUser.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(EditSchoolUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(EditSchoolUser.fulfilled, (state, action) => {
                state.loading = false;
                state.schoolUserData = action.payload;
            })
            .addCase(EditSchoolUser.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default SchoolSlice.reducer;
