import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import AddRouteForm from "./AddRouteForm.jsx";
import Table from "../../../components/Table/index.js";
import AddNewStops from "./AddNewStops.jsx";
import { FetchRouteData, DeleteRoute } from "src/thunks/route.js";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import DetailPanel from "./DetailPanel/index.js";
import { GetError } from "src/constant/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./RouteStyle.scss";

const RouteList = () => {
  // --------------- Table Columns --------------------
  let columns = [
    { title: t("route.routeName"), field: "name" },
    { title: t("route.assignedDriver"), field: "driverName" },
    { title: t("company.companyName"), field: "companyName" },
    { title: t("common.cityName"), field: "cityName" },
    // {
    //   title: "Add Stops",
    //   field: "addNewStops",
    //   render: (rowData) => (
    //     <div
    //       onClick={() => {
    //         openAndCloseStopModel();
    //         setDataForAddNewStop(rowData);
    //       }}
    //       style={{ cursor: "pointer" }}
    //     >
    //       <Add
    //         style={{
    //           height: "22px",
    //           width: "22px",
    //           backgroundColor: "#DB1F28",
    //           color: "white",
    //           borderRadius: "100%",
    //         }}
    //       />
    //       <span style={{ textDecoration: "underline", marginLeft: "10px" }}>
    //         Add New Stops
    //       </span>
    //     </div>
    //   ),
    // },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                let data = _.find(
                  routeStateData?.routeData?.result,
                  (d) => d.id === rowData?.id
                );
                setEditData(data);
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // ------------- Reac Global States ------------------
  const routeStateData = useSelector((state) => state?.Route || []);
  const loading = useSelector((state) => state?.Route?.loading);

  // -------------- React Hooks ------------------------
  const dispatch = useDispatch();

  // -------------- Component Local States -------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [stopModal, setStopModal] = useState(false);
  const [dataForAddNewStop, setDataForAddNewStop] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // -------------- API Call Functions -----------------

  const getAllRouteData = async () => {
    try {
      await dispatch(
        FetchRouteData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteRoute({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.routeDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ------------------- Local Helper Functions ----------------------

  const mapData = (data) => {
    return data.map((route) => {
      return {
        ...route,
        address: `${
          route?.user?.address?.street1 || route?.user?.address?.street2 || ""
        } ${route?.user?.address?.cityName || ""} ${
          route?.user?.address?.state || ""
        } ${route?.user?.address?.country || ""} ${
          route?.user?.address?.zip || ""
        }`,
        fullName: `${route?.user?.firstName || ""} ${
          route?.user?.middleName || ""
        } ${route?.user?.lastName || ""}`,
        profileUrl: `${route?.user?.profileUrl || ""}`,
        email: `${route?.user?.email || ""}`,
        phoneNumber: `${route?.user?.phoneNumber || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const openAndCloseStopModel = () => {
    setStopModal(!stopModal);
  };

  // ------------------ Component Life Cycle Methods -----------------

  // useEffect(() => {
  //   getAllRouteData();
  // }, []);

  useEffect(() => {
    getAllRouteData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      <Table
        loading={loading}
        headerTitle={t("route.route")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        Data={mapData(routeStateData?.routeData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
        // detailPanel={(rowData) => (
        //   <DetailPanel
        //     rowData={rowData}
        //     currentPage={page}
        //     pageSize={rowsPerPage}
        //   />
        // )}
      />
      <PaginationComponent
        count={routeStateData?.routeData?.totalPages}
        totalItems={routeStateData?.routeData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={editData?.id ? t("route.editRoute") : t("route.addNewRoute")}
        subtitle={
          editData?.id ? t("route.editRouteDetails") : t("route.createNewRoute")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddRouteForm
          currentPage={page}
          pageSize={rowsPerPage}
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
        />
      </CommonModal>

      <CommonModal
        togal={stopModal}
        title={t("route.addStops")}
        subtitle={t("route.addNewStops")}
        toggleHandler={() => openAndCloseStopModel()}
      >
        <AddNewStops
          currentPage={page}
          pageSize={rowsPerPage}
          toggleHandler={() => openAndCloseStopModel()}
          dataForAddNewStop={dataForAddNewStop}
          setEditFun={setEditData}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are You Sure To Delete?"}
        subtitle={"Yes"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={routeStateData?.loading}
      />
    </div>
  );
};
export default RouteList;
