import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";
import axios from "axios";
import { AppConfigs } from "../settings";

// Get by id order items data
export const getByIdOrderItems = createAsyncThunk(
  "getByIdOrderItems",
  async (model, thunkApi) => {
    try {
      // let res = await getAxios().get(
      //   `OrderItems/GetByOrderId?orderId=${model?.id}&itemName=${
      //     model?.itemName || ""
      //   }&currentPage=1&pageSize=1000`
      // );
      let res = await getAxios().get(
        `order/${model?.id}/item?orderId=${model?.id}&itemName=${
          model?.itemName || ""
        }&currentPage=1&pageSize=1000`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteOrderItemById = createAsyncThunk(
  "deleteOrderItemById",
  async (model, thunkApi) => {
    try {
      // await getAxios().delete(`OrderItems/${model?.id}`);
      // let res = await getAxios().get(
      //   `OrderItems/GetByOrderId?orderId=${model?.listId}&currentPage=1&pageSize=1000`
      // );

      await getAxios().delete(`order/${model?.orderId}/item/${model?.id}`);
      let res = await getAxios().get(
        `order/${model?.orderId}/item?orderId=${model?.orderId}&itemName=${
          model?.itemName || ""
        }&currentPage=1&pageSize=1000`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditItemOrder = createAsyncThunk(
  "EditItemOrder",
  async (model, thunkApi) => {
    try {
      // await getAxios().put(`OrderItems/${model?.id}`, model);
      // let res = await getAxios().get(
      //   `OrderItems/GetByOrderId?orderId=${model?.orderId}&currentPage=1&pageSize=1000`
      // );
      await getAxios().put(`order/${model?.orderId}/item/${model?.id}`, model);
      let res = await getAxios().get(
        `order/${model?.orderId}/item?orderId=${
          model?.orderId
        }&itemName=${""}&currentPage=1&pageSize=1000`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const senOrderItemPath = createAsyncThunk(
  "senOrderItemPath",
  async (model, thunkApi) => {
    try {
      // let data = await getAxios().post(`OrderItems`, {
      //   ...model,
      // });
      const data = await getAxios().put(`order/${model?.id}`, model);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAllOrderItem = createAsyncThunk(
  "getAllOrderItem",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`order/item`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
