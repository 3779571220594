import React from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import "./deletemodal.scss";
import deleteIcon from "src/assets/images/ModalDeleteIcon.png";
import { t } from "i18next";
import { InlineLoader } from "../InlineLoader";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";

export const CancelModal = ({
  togal,
  toggleHandler,
  deleteButtonHandler,
  loading,
  message,
  resetState,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "398px",
    // height: "280px",
    bgcolor: "background.paper",
    borderRadius: "18px",
  };

  return (
    <Modal
      open={togal}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sm: "398px", xs: "300px" },
          height: { sm: "280px", xs: "290px" },
          bgcolor: "background.paper",
          borderRadius: "18px",
          paddingInline: "10px",
        }}
      >
        {loading == true ? <InlineLoader /> : ""}
        <div style={{}} className="deleteModalMain">
          <div className="deletemainHeaderDelete">
            <div
              style={{
                paddingTop: 10,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBlock: "10px",
                }}
              >
                {resetState ? (
                  <SettingsBackupRestoreOutlinedIcon
                    style={{ width: "55px", height: "55px", color: "green" }}
                  />
                ) : (
                  <CancelOutlinedIcon
                    style={{ width: "55px", height: "55px", color: "red" }}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid className="title" sx={{ width: "350px" }}>
                  <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                    {" "}
                    {message}
                  </Typography>
                </Grid>
              </div>
            </div>
            <Grid
              sx={{
                marginTop: "1rem",
                paddingInline: { sm: "3rem", xs: "0.5rem" },
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "black",
                  fontSize: "15px",
                  width: "122px",
                  color: "white",
                  borderRadius: "22px",
                  "&:hover": {
                    backgroundColor: "black", // Change to the desired hover color
                  },
                }}
                color="primary"
                onClick={() => deleteButtonHandler()}
                fullWidth
              >
                {t("common.yes")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "black",
                  fontSize: "15px",
                  width: "122px",
                  color: "white",
                  borderRadius: "22px",
                  "&:hover": {
                    backgroundColor: "black", // Change to the desired hover color
                  },
                }}
                onClick={() => toggleHandler()}
                fullWidth
              >
                {t("common.no")}
              </Button>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
