import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "src/helpers/validation";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { signIn } from "src/thunks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetError } from "src/constant";
import loginImage from "src/assets/images/loginImage.png";
import EmailComponent from "./EmailComponent";
import SetOTP from "./SetOTP";
import RenterPassword from "./RenterPassword";
import { t } from "i18next";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState("email-id");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmitHandler = async (data) => {
    try {
      let loginRes = await dispatch(signIn({ ...data })).unwrap();
      if (loginRes.token) {
        navigate("/app");
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors || error?.message || "Problem In Login"
        )
      );
    }
  };

  const renderComponent = () => {
    let showData;
    switch (showContent) {
      case "set-otp":
        showData = <SetOTP setShowContent={setShowContent} />;
        break;
      case "re-password":
        showData = <RenterPassword setShowContent={setShowContent} />;
        break;
      default:
    }

    return showData;
  };

  return (
    <Grid
      style={{
        height: window.innerHeight - 5,
        backgroundColor: "white",
      }}
      className="login-main-container"
    >
      <Grid>
        <Grid container>
          <Grid
            item
            sx={{
              backgroundImage: `url(${loginImage})`,

              height: window.innerHeight - 1,
              color: "white",
              display: { sm: "flex", xs: "none" },
            }}
            xs={5}
          >
            <Grid
              sx={{
                background:
                  "linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.615025) 43.24%, rgba(0, 0, 0, 0) 79.9%)",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: "100px",
              }}
              sm={12}
            >
              <Typography
                style={{
                  fontFamily: "Albert Sans",
                  fontSize: "28.91px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                {" "}
                {t("common.logInMsg1")} <br /> {t("common.logInMsg2")}
              </Typography>
              <Typography
                sx={{ fontSize: "11px", fontWeight: 300, color: "#FFFFFF" }}
              >
                {" "}
                {t("common.footer")}
              </Typography>
            </Grid>
          </Grid>
          {
            //------------
          }
          {showContent == "email-id" ? (
            <EmailComponent setShowContent={setShowContent} />
          ) : (
            renderComponent()
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
