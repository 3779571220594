import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import moment from "moment/moment.js";
import DetailedPanel from "./DetailView/index.js";
import { GetScheduleByDriverId, cancelRideStop } from "src/thunks/schedule.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import Table from "src/components/Table/index.js";
import DriverTable from "src/components/DriverTable/index.js";
import { CancelModal } from "src/components/IsCancelModule/index.js";
import { GetError } from "src/constant/index.js";
import "./SchoolUpcomingRideStyle.scss";

const ScheduleList = () => {
  // ------------- Table Columns ---------------------

  let columns = [
    {
      title: t("common.dayAndDate"),
      field: "dayWithDate",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.dayWithDate);
      },
    },
    {
      title: t("driver.itemName"),
      field: "itemName",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.itemName);
      },
    },
    {
      title: t("driver.sourceAddress"),
      field: "sourceAddress",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.sourceAddress);
      },
    },
    {
      title: t("driver.startTime"),
      field: "startTime",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.startTime);
      },
    },
    {
      title: t("schedule.destinationAddress"),
      field: "destinationAddress",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.destinationAddress);
      },
    },
    {
      title: t("schedule.endTime"),
      field: "endTime",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.endTime);
      },
    },

    {
      title: t("driver.status"),
      field: "driverName",
      render: (rowData) => {
        return (
          <div>
            {rowData?.isCancel ? (
              <SettingsBackupRestoreOutlinedIcon
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "4px",
                  marginLeft: "4px",
                  color: "green",
                }}
                onClick={() => {
                  openAndCloseCancelModel();
                  seCancelState(rowData?.isCancel);
                  setSelectedId(rowData?.id);
                  setRowData(rowData);
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "4px",
                  marginLeft: "1px",
                  color: "red",
                }}
                onClick={() => {
                  openAndCloseCancelModel();
                  seCancelState(rowData?.isCancel);
                  setSelectedId(rowData?.id);
                  setRowData(rowData);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  // let mobileScreenColumns = [
  //   {
  //     title: t("driver.date"),
  //     field: "date",
  //     render: (rowData) => moment(rowData?.fromDate).format("DD-MM-YYYY"),
  //   },

  //   {
  //     title: t("driver.startTime"),
  //     field: "startTime",
  //     render: (rowData) => rowData?.startTime,
  //   },
  //   {
  //     title: t("driver.vehicle"),
  //     field: "vehicleNo",
  //   },
  // ];

  // -------------------------- Global States --------------
  const scheduleStateData = useSelector(
    (state) => state?.Schedule?.driverScheduleData
  );

  // ------------------------ React Hooks ----------------------
  const dispatch = useDispatch();
  const selectedItem = React.useRef([]);

  // ----------------------- Component Local States -----------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [selectedRowData, setSelectedId] = useState(null);
  const [cancelState, seCancelState] = useState(false);
  const [rowData, setRowData] = useState({});

  // ----------------------- API Call Functions ---------------

  const getAllScheduleData = async () => {
    try {
      let newObject = JSON.parse(window.localStorage.getItem("userDetail"));
      await dispatch(
        GetScheduleByDriverId({
          schoolUserId: newObject?.transactionId,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
          isUpcommingSchedule: true,
          isTodaysSchedule: false,
        })
      ).unwrap();
    } catch (err) {
      toast.error(err?.message || t("toast.errorMessage"));
    }
  };

  const cancelRide = async () => {
    try {
      let requestObject = {
        isCancel: !cancelState,
        scheduleId: scheduleStateData?.result?.[0].id,
        scheduleItemIds: [selectedRowData],
      };

      let sucessResponse = await dispatch(
        cancelRideStop({ ...requestObject })
      ).unwrap();
      if (sucessResponse) {
        setIsCancelModal(!isCancelModal);
        if (cancelState) {
          toast.success(t("toast.scheduleItemActivated"));
        } else {
          toast.success(t("toast.scheduleItemCanceled"));
        }
        getAllScheduleData();
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ------------------- Local Helper Functions -------------------------

  // const mapData = (data) => {
  //   return data?.map((o) => {
  //     return {
  //       ...o,
  //       address: `${
  //         o?.user?.address?.street1 || o?.user?.address?.street2 || ""
  //       } ${o?.user?.address?.cityName || ""} ${
  //         o?.user?.address?.state || ""
  //       } ${o?.user?.address?.country || ""} ${o?.user?.address?.zip || ""}`,
  //       fullName: `${o?.user?.firstName || ""} ${o?.user?.middleName || ""} ${
  //         o?.user?.lastName || ""
  //       }`,
  //       profileUrl: `${o?.user?.profileUrl || ""}`,
  //       email: `${o?.user?.email || ""}`,
  //       phoneNumber: `${o?.user?.phoneNumber || ""}`,
  //     };
  //   });
  // };

  const checkIsCanceled = (isCancel, data) => {
    return isCancel ? <s>{data}</s> : <span>{data}</span>;
  };

  const openAndCloseCancelModel = () => {
    setIsCancelModal(!isCancelModal);
  };

  // function getDateForDay(startDate, targetDayNumber) {
  //   const daysOfWeek = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   const startDateObj = new Date(startDate);
  //   const startDayNumber = startDateObj.getDay();

  //   const daysToAdd = (targetDayNumber - startDayNumber + 7) % 7;

  //   const targetDate = new Date(startDateObj);
  //   targetDate.setDate(startDateObj.getDate() + daysToAdd);

  //   const result = `${
  //     daysOfWeek[targetDayNumber]
  //   } - ${targetDate.getFullYear()}-${(targetDate.getMonth() + 1)
  //     .toString()
  //     .padStart(2, "0")}-${targetDate.getDate().toString().padStart(2, "0")} `;

  //   return result;
  // }

  const manipulateDataForTable = (requireData) => {
    const newData = [];
    const data = requireData?.map((item, index) => {
      return item?.scheduleItems?.map((scheduleItems, index) => {
        newData.push({
          ...scheduleItems,
          dayWithDate: `${moment(
            scheduleItems?.scheduleItemDate,
            "YYYY-MM-DD"
          ).format("dddd")} - ${
            scheduleItems?.scheduleItemDate?.split("T")[0] || ""
          }`,
          itemName: scheduleItems?.itemName,
          sourceAddress: `${scheduleItems?.sourceAddress?.street1}, ${scheduleItems?.sourceAddress?.street2},${scheduleItems?.sourceAddress?.cityName}, ${scheduleItems?.sourceAddress?.zip}, ${scheduleItems?.sourceAddress?.country}`,
          startTime: scheduleItems?.startTime,
          destinationAddress: `${scheduleItems?.destinationAddress?.street1}, ${scheduleItems?.destinationAddress?.street2},${scheduleItems?.destinationAddress?.cityName}, ${scheduleItems?.destinationAddress?.zip}, ${scheduleItems?.destinationAddress?.country}`,
          endTime: scheduleItems?.endTime,
          isCancel: scheduleItems?.isCancel,
          scheduleName: item?.scheduleNo,
          fromDate: item?.fromDate,
          toDate: item?.toDate,
        });
        return {
          ...scheduleItems,
          dayWithDate: `${moment(
            scheduleItems?.scheduleItemDate,
            "YYYY-MM-DD"
          ).format("dddd")} - ${scheduleItems?.scheduleItemDate || ""}`,
          itemName: scheduleItems?.itemName,
          sourceAddress: `${scheduleItems?.sourceAddress?.street1}, ${scheduleItems?.sourceAddress?.street2},${scheduleItems?.sourceAddress?.cityName}, ${scheduleItems?.sourceAddress?.zip}, ${scheduleItems?.sourceAddress?.country}`,
          startTime: scheduleItems?.startTime,
          destinationAddress: `${scheduleItems?.destinationAddress?.street1}, ${scheduleItems?.destinationAddress?.street2},${scheduleItems?.destinationAddress?.cityName}, ${scheduleItems?.destinationAddress?.zip}, ${scheduleItems?.destinationAddress?.country}`,
          endTime: scheduleItems?.endTime,
          isCancel: scheduleItems?.isCancel,
        };
      });
    });

    return data?.length > 0 ? newData : [];
  };

  const getMessage = () => {
    return `${
      rowData?.isCancel
        ? t("common.cancelItemMessage4")
        : t("common.cancelItemMessage1")
    } ${rowData?.itemName} ${t("common.cancelItemMessage2")} ${
      rowData?.scheduleName
    } ${t("driver.from")} ${moment(rowData?.fromDate).format("DD-MMM-YY")} ${t(
      "driver.to"
    )} ${moment(rowData?.toDate).format("DD-MMM-YY")} ${t(
      "common.cancelItemMessage3"
    )} ${moment(rowData?.scheduleItemDate).format("dddd")}`;
  };
  // -------------- Component Life Cycle Methods ---------------

  useEffect(() => {
    getAllScheduleData(page, rowsPerPage);
  }, []);

  useEffect(() => {
    getAllScheduleData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <div className="schedule-main-container">
      <div className="schedule-main-header">
        <div className="schedule-heading">{t("driver.upcomingRides")} </div>
        <div style={{ display: "flex" }}>
          {/* <div style={{ marginLeft: "15px" }}>
            <span>Sort By</span>
          </div> */}
        </div>
      </div>
      <div>
        {
          // Desktop View Compnent
        }
        <Grid
          container
          sx={{ display: { sm: "flex", xs: "none" }, flexDirection: "column" }}
        >
          {/* <Table
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={columns}
            Data={mapData(scheduleStateData?.result || [])}
            detailPanel={(rowdata) => (
              <DetailedPanel
                rowData={rowdata}
                getAllScheduleData={() => getAllScheduleData()}
              />
            )}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
              // selection: true,
              // actions: true,
            }}
          /> */}
          <Table
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={columns}
            Data={manipulateDataForTable(scheduleStateData?.result || [])}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
            }}
          />
          <div style={{ display: "none" }}>
            <PaginationComponent
              count={scheduleStateData?.totalPages}
              totalItems={scheduleStateData?.totalItems}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </Grid>

        {/* <Grid container sx={{ display: { sm: "none", xs: "block" } }}>
          <DriverTable
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={mobileScreenColumns}
            Data={mapData(scheduleStateData?.result || [])}
            detailPanel={(rowdata) => <DetailedPanel rowData={rowdata} />}
            options={{
              paging: false,
              search: false,
              toolbar: false,
              // selection: true,
              // actions: true,
            }}
          />
        </Grid> */}
      </div>
      <CancelModal
        togal={isCancelModal}
        toggleHandler={() => openAndCloseCancelModel()}
        deleteButtonHandler={() => cancelRide()}
        message={getMessage()}
        resetState={rowData?.isCancel}
      />
    </div>
  );
};
export default ScheduleList;
