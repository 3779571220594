import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FetchSchoolData } from "src/thunks/school";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import { GetError } from "src/constant";
import { t } from "i18next";

export const CommonSchool = ({ onChange, value }) => {
  const dispatch = useDispatch();

  const AllSchools = useSelector(
    (state) => state?.School?.schoolData?.result || []
  );

  const [svalue, setsValue] = useState("");

  useEffect(() => {
    let defaultData = _.find(AllSchools, (d) => d.id == `${value}`);
    setsValue(defaultData?.name || "");
  }, [value, AllSchools]);

  const getAllSchoolData = async () => {
    try {
      await dispatch(
        FetchSchoolData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  useEffect(() => {
    getAllSchoolData();
  }, []);

  return (
    <Autocomplete
      disableClearable
      disablePortal
      onChange={(e, value) => onChange(value?.value || "")}
      value={svalue}
      options={AllSchools?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("common.selectSchool")}
          size="small"
        />
      )}
    />
  );
};
