import { t } from "i18next";

export const GetError = (err) => {
  let string = "";
  if (err.length === 1) {
    return err[0];
  } else if (Object.keys(err).length === 0 || typeof err === "string") {
    return err;
  } else {
    Object.keys(err).map((d) => {
      string = string + err[d].join(",");
      return "";
    });
    return string;
  }
};

export const weekdays = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
];

export const errorMessageChangeInFinish = (newMessage) => {
  return t(`${newMessage}`) + " " + t("common.isrequired");
};

export const placeHolderMessage = (newMessage) => {
  return t("common.enter") + " " + t(`${newMessage}`);
};

export const joditPlaceHolderMessage = t("common.startTyping");
