import { TablePagination } from "@mui/material";

export const PaginationComponent = ({
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <TablePagination
      component="div"
      count={totalItems}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
