import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DriverMasterList from "./components/list";

const ParestMasterHome = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>
          <Route path={"list"} element={<DriverMasterList />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default ParestMasterHome;
