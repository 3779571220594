import React from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import "./EndJourneyModalStyle.scss";
import EndJourneyModalImage from "../../assets/images/EndJourneyModalImage.png";
import { t } from "i18next";

export const JourneyEndModal = ({
  togal,
  toggleHandler,
  title,
  subtitle,
  children,
  onsubmitHandler,
  endjournyFunction,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "280px",
    bgcolor: "background.paper",
    // overflowY: "scroll",
    borderRadius: "18px",
    boxShadow: "0px 3px 18px 0px rgba(23, 28, 69, 0.26)",
    paddingInline: "60px",
    paddingTop: "26px",
    paddingBottom: "35px",
  };

  return (
    <Modal
      open={togal}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-container"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sm: "400px", xs: "300px" },
          height: "280px",
          bgcolor: "background.paper",
          // overflowY: "scroll",
          borderRadius: "18px",
          boxShadow: "0px 3px 18px 0px rgba(23, 28, 69, 0.26)",
          paddingInline: { sm: "60px", xs: "20px" },
          paddingTop: "26px",
          paddingBottom: "35px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={EndJourneyModalImage}
            style={{ height: "62px", width: "45px" }}
            alt="Img Not Found"
          ></img>
        </Grid>
        <Grid>
          <Typography className="end-journey-text">
            {t("common.endJourneyModal")}
          </Typography>
        </Grid>
        <Grid className="buttons-container">
          <Button
            onClick={() => endjournyFunction()}
            className="yes-button"
            sx={{
              "&:hover": {
                background: "black",
              },
            }}
          >
            {t("common.yes")}
          </Button>
          <Button className="no-button" onClick={() => toggleHandler()}>
            {t("common.no")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
