import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

export default function NewDatePicker() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [t, I18n] = useTranslation();

  const changeLanguage = (lnCode) => {
    I18n.changeLanguage(lnCode);
  };

  const handleClose = (code) => {
    window.location.reload();
    // code ? changeLanguage(code) : "en";
    if (code === "en" || code === "fi") {
      localStorage.setItem("Languagecode", code);
      changeLanguage(code);
    } else {
      changeLanguage(localStorage.getItem("Languagecode"));
    }
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div>
      <span
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="language_text"
      >
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid black",
            "&:hover": {
              backgroundColor: "black",
              border: "1px solid black",
            },
            textTransform: "none",
          }}
          variant="outlined"
          size="small"
        >
          <Typography sx={{ display: { sm: "flex", xs: "none" } }}>
            {t("common.Language")}
          </Typography>
          <LanguageIcon sx={{ display: { sm: "none", xs: "flex" } }} />
        </Button>
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClose("en")}>
          {t("common.english")}
        </MenuItem>
        <MenuItem onClick={() => handleClose("fi")}>
          {t("common.finish")}
        </MenuItem>
      </Menu>
    </div>
  );
}
