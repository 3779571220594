import React from "react";
import MaterialTable from "material-table";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./CustomTable.scss"; // Import the CSS file
import { t } from "i18next";
import { disable } from "workbox-navigation-preload";

const Table = ({
  columns,
  Data,
  tableTittle,
  action,
  options,
  detailPanel,
  handleRowSelect,
  openModelForAdd,
  headerTitle,
  issearch,
  loading,
  searchingComponent,
  hideAddButton,
  isSchedule,
  isContract,
}) => {
  const rowStyle = (rowData, index) => {
    if (index % 2 === 1) {
      return { backgroundColor: "#F8F8F8" }; // Apply a background color to even rows
    }
    return {};
  };

  const headerStyle = {
    backgroundColor: "#EEF9FF",
    color: "#404040",
    fontWeight: 600,
    fontSize: "14px",
    borderBottom: "0px",
  };
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSelectionChange = (rows) => {
    if (rows.length > 0) {
      setSelectedRow(rows[0].tableData.id);
    } else {
      setSelectedRow(null);
    }
  };

  const tableOptions = {
    selectionProps: (rowData) => ({
      disabled: rowData.tableData.id !== selectedRow,
    }),
  };

  return (
    <div
      className="common-table-main-container"
      style={{
        height:
          isSchedule == true
            ? "calc(100vh - 295px)"
            : isContract == true
            ? "calc(100vh - 160px)"
            : "calc(100vh - 160px)",
      }}
    >
      {(headerTitle || openModelForAdd) && (
        <div className="table-header-main-container">
          <div className="second-container">
            {headerTitle && <div className="header-heading">{headerTitle}</div>}
            {openModelForAdd && (
              <div style={{ display: "flex" }}>
                {!hideAddButton && (
                  <div>
                    <Add className="header-add-logo" />
                    <Button
                      onClick={() => openModelForAdd()}
                      className="header-add-button"
                      size="large"
                    >
                      {t("common.addnew")} {headerTitle}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          {searchingComponent && (
            <div className="searching-container">{searchingComponent}</div>
          )}
          {issearch}
        </div>
      )}

      <div
        className="common-table"
        style={{
          height:
            isSchedule == true
              ? "calc(100vh - 295px)"
              : isContract == true
              ? "calc(100vh - 290px)"
              : "calc(100vh - 230px)",
        }}
      >
        <MaterialTable
          isLoading={loading}
          title={tableTittle}
          columns={columns}
          data={Data}
          options={{
            ...options,
            rowStyle: rowStyle,
            headerStyle: headerStyle,
            stickyHeader: true,
            detailPanelType: "single",
            showSelectAllCheckbox: false,
          }}
          actions={action}
          detailPanel={detailPanel}
          onSelectionChange={(data) => handleRowSelect(data)}
        />
      </div>
    </div>
  );
};

export default Table;
