import React, { useState, useEffect } from "react";
import moment from "moment/moment.js";
import _ from "lodash";
import { t } from "i18next";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Table from "src/components/Table/index.js";
import { FetchOrderData, DeleteOrder } from "src/thunks/order.js";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { ImportDataMdoal } from "src/components/ImportDataModal";
import { GetError } from "src/constant/index.js";
import SearchingComponent from "src/components/SearchingComponent/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import AddOrderForm from "./AddOrderForm.jsx";
import DetailedPanel from "./DetailView/index.js";
import "./OrderStyle.scss";

const OrderList = () => {
  // ---------------- Global States --------------
  const orderStateData = useSelector((state) => state?.Order || []);

  // ---------------- React Hooks ----------------
  const dispatch = useDispatch();

  // -------------------- Table Columns ----------------------------------

  let columns = [
    {
      title: t("common.action"),
      field: "customActions",
      maxWidth: 500,
      render: (rowData) => {
        return (
          <div className="table-icons-container">
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    orderStateData?.orderData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                setDeleteid(rowData?.id);
                openAndCloseDeleteModel();
              }}
            />
            <FileUploadOutlinedIcon
              className="uploadIcon"
              onClick={() => {
                setUploadingId(rowData);
                openAndCloseUploadmOdal();
              }}
            />
          </div>
        );
      },
    },
    { title: t("contract.contractName"), field: "orderNo" },
    { title: t("common.cityName"), field: "cityName" },
    { title: t("company.companyName"), field: "companyName" },
    {
      title: t("contract.startContract"),
      field: "fromDate",
      render: (rowData) => moment(rowData?.fromDate).format("DD-MM-YYYY"),
    },
    {
      title: t("contract.endContract"),
      field: "toDate",
      render: (rowData) => moment(rowData?.toDate).format("DD-MM-YYYY"),
    },
    { title: t("contract.category"), field: "categoryName" },
    { title: t("contract.totalItems"), field: "itemCount" },
    { title: t("contract.areaDescription"), field: "areaDescription" },
  ];

  // ---------------- Local States ---------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadingId, setUploadingId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchingValues, setSearchingValues] = useState({
    contractNo: "",
    itemName: "",
    fromDate: "",
    toDate: "",
  });

  // -------------- API Caller Functions ----------

  const getAllOrderData = async (reset) => {
    try {
      if (!reset) {
        await dispatch(
          FetchOrderData({
            currentPage: page + 1,
            pageSize: rowsPerPage,
            paging: true,
            ...searchingValues,
          })
        ).unwrap();
      } else {
        await dispatch(
          FetchOrderData({
            currentPage: page + 1,
            pageSize: rowsPerPage,
            paging: true,
          })
        ).unwrap();
        setSearchingValues({
          contractNo: "",
          itemName: "",
          fromDate: "",
          toDate: "",
        });
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorWhileFetchingContract")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteOrder({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();

      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.orderDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorInDeletingContract")
        )
      );
    }
  };

  // -------------- Local Helper Functions ----------

  const mapData = (data) => {
    return data?.map((contractInfo) => {
      return {
        ...contractInfo,
        address: `${
          contractInfo?.user?.address?.street1 ||
          contractInfo?.user?.address?.street2 ||
          ""
        } ${contractInfo?.user?.address?.cityName || ""} ${
          contractInfo?.user?.address?.state || ""
        } ${contractInfo?.user?.address?.country || ""} ${
          contractInfo?.user?.address?.zip || ""
        }`,
        fullName: `${contractInfo?.user?.firstName || ""} ${
          contractInfo?.user?.lastName || ""
        }`,
        profileUrl: `${contractInfo?.user?.profileUrl || ""}`,
        email: `${contractInfo?.user?.email || ""}`,
        phoneNumber: `${contractInfo?.user?.phoneNumber || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const openAndCloseUploadmOdal = () => {
    setIsUploadModal(!isUploadModal);
  };

  // ----------------- Life Cylce Methods ------------------------

  useEffect(() => {
    getAllOrderData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    getAllOrderData();
  }, []);

  return (
    <div className="allcontainer">
      <Table
        loading={orderStateData?.loading}
        headerTitle={t("contract.contract2")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        isContract={true}
        issearch={
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBlock: "10px",
              marginTop: "-15px",
            }}
            sm={12}
          >
            {" "}
            <SearchingComponent
              value={(t) => {
                setSearchingValues({ ...searchingValues, ...t });
              }}
              onSubmit={() => getAllOrderData()}
              clearFields={() => {
                let reset = true;
                getAllOrderData(reset);
              }}
            />
          </Grid>
        }
        Data={mapData(orderStateData?.orderData?.result || [])}
        detailPanel={(rowdata) => (
          <DetailedPanel
            rowdataId={rowdata?.id}
            itemName={searchingValues?.itemName}
          />
        )}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={orderStateData?.orderData?.totalPages}
        totalItems={orderStateData?.orderData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={
          editData?.id
            ? t("contract.editContract")
            : t("contract.addNewContract")
        }
        subtitle={
          editData?.id
            ? t("contract.editContractDetails")
            : t("contract.createNewContract")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddOrderForm
          currentPage={page}
          pageSize={rowsPerPage}
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are You Sure To Delete?"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={() => deleteButtonHandler()}
        loading={orderStateData?.loading}
      />

      <ImportDataMdoal
        fetchMasterData={() => getAllOrderData()}
        moduleName="order"
        uploadingId={uploadingId?.id}
        togal={isUploadModal}
        title={"Update Modal"}
        toggleHandler={() => openAndCloseUploadmOdal()}
        children={"Hii"}
        rowData={uploadingId}
      />
    </div>
  );
};
export default OrderList;
