import { configureStore, compose } from "@reduxjs/toolkit";
import AuthReducer from "../slice/auth";
import VehicleReducer from "../slice/vehicle";
import CompanyReducer from "../slice/company";
import SchoolReducer from "../slice/school";
import DriverReducer from "../slice/driver";
import CommonReducer from "../slice/common";
import UserReducer from "../slice/user";
import OrderReducer from "../slice/order";
import OrderItemReducer from "../slice/orderItems";
import SchoolUserReducer from "../slice/schoolUser";
import RouteReduce from "../slice/route";
import ScheduleReduce from "../slice/schedule";
import ScheduleItemsReduce from "../slice/ScheduleItems";
import Report from "../slice/report";
import Contact from "../slice/contact";
import Notification from "../slice/notifications";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = configureStore({
  reducer: {
    Vehicle: VehicleReducer,
    Company: CompanyReducer,
    School: SchoolReducer,
    Driver: DriverReducer,
    Auth: AuthReducer,
    common: CommonReducer,
    User: UserReducer,
    Order: OrderReducer,
    OrderItem: OrderItemReducer,
    Route: RouteReduce,
    SchoolUser: SchoolUserReducer,
    Schedule: ScheduleReduce,
    ScheduleItems: ScheduleItemsReduce,
    Report: Report,
    Contact: Contact,
    Notification: Notification,
  },
  composeEnhancers: composeEnhancers(),
});

export default Store;
