import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";
import _ from "lodash";
import moment from "moment";

export const FetchScheduleData = createAsyncThunk(
  "FetchScheduleData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `schedule?&contractNo=${model?.contractNo || ""}&itemName=${
          model?.itemName || ""
        }&fromDate=${model?.fromDate || ""}&toDate=${
          model?.toDate || ""
        }&currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddSchedule = createAsyncThunk(
  "AddSchedule",
  async (model, thunkApi) => {
    try {
      await getAxios().post("schedule", model);
      let res = await getAxios().get(
        `schedule?&currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 1
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditSchedule = createAsyncThunk(
  "EditSchedule",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`schedule/${model?.id}`, model);
      let res = await getAxios().get(
        `schedule?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.pagging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteSchedule = createAsyncThunk(
  "DeleteSchedule",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`schedule/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `schedule?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const CopySchedule = createAsyncThunk(
  "CopySchedule",
  async (model, thunkApi) => {
    try {
      // await getAxios().post("schedule//CopySchedule", model);
      // let res = await getAxios().get(
      //   `schedule?currentPage=${model?.currentPage || 0}&pageSize=${
      //     model?.pageSize
      //   }&paging=${model?.paging}`
      // );
      await getAxios().post(`schedule/${model?.scheduleId}/copy`, model);
      let res = await getAxios().get(`schedule`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const GetScheduleByDriverId = createAsyncThunk(
  "GetScheduleByDriverId",
  async (model, thunkApi) => {
    try {
      let url = `schedule/byFilter?isTodaysSchedule=${
        model?.isTodaysSchedule || false
      }&currentPage=${model?.currentPage || 0}&pageSize=${
        model?.pageSize || 0
      }&paging=${model?.paging || false}&isUpcommingSchedule=${
        model?.isUpcommingSchedule || false
      }&fromDate=${model?.fromDate || ""}&toDate=${model?.toDate || ""}`;
      if (model?.schoolUserId) {
        url = `${url}&schoolUserId=${model?.schoolUserId}`;
      } else if (model?.driverId) {
        url = `${url}&driverId=${model?.driverId}`;
      } else if (model?.contactId) url = `${url}&contactId=${model?.contactId}`;
      let res = await getAxios().get(url);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const GetScheduleBySchoolUserId = createAsyncThunk(
  "GetScheduleBySchoolUserId",
  async (model, thunkApi) => {
    try {
      let url = `schedule/byFilter?isTodaysSchedule=false&currentPage=${
        model?.currentPage || 0
      }&pageSize=${model?.pageSize || 0}&paging=${model?.paging || false}`;
      if (model?.schoolUserId) {
        url = `${url}&schoolUserId=${model?.schoolUserId}`;
      } else if (model?.driverId) {
        url = `${url}&driverId=${model?.driverId}`;
      } else if (model?.schoolUserId)
        url = `${url}&schoolUserId=${model?.schoolUserId}`;
      let res = await getAxios().get(url);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const GetCurrentRideByDriverId = createAsyncThunk(
  "GetCurrentRideByDriverId",
  async (model, thunkApi) => {
    try {
      let url = `schedule/byFilter?isTodaysSchedule=${
        model?.isTodaysSchedule || false
      }&currentPage=${model?.currentPage || 0}&pageSize=${
        model?.pageSize || 0
      }&paging=${model?.paging || false}&isUpcommingSchedule=${
        model?.isUpcommingSchedule || false
      }&fromDate=${model?.fromDate || ""}&toDate=${model?.toDate || ""}`;
      if (model?.driverId) {
        url = `${url}&driverId=${model?.driverId}`;
      } else if (model?.contactId) url = `${url}&contactId=${model?.contactId}`;
      let res = await getAxios().get(url);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const GetCurrentRideForDriver = createAsyncThunk(
  "GetCurrentRideForDriver",
  async (model, thunkApi) => {
    try {
      let url = `Driver/${model?.driverId}/schedule?isTodaysSchedule=${
        model?.isTodaysSchedule || false
      }&driverId=${model?.driverId}&scheduleId=${
        model?.scheduleId || 0
      }&isUpcommingSchedule=${model?.isUpcommingSchedule}`;
      let res = await getAxios().get(url);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const StartDriverJourny = createAsyncThunk(
  "StartDriverJourny",
  async (model, thunkApi) => {
    try {
      let url = `driver/${model?.driverId}/journey`;
      await getAxios().post(url, model);
      let url1 = `Driver/${model?.driverId}/schedule?isTodaysSchedule=${
        model?.isTodaysSchedule || false
      }&driverId=${model?.driverId}&scheduleId=${
        model?.scheduleId || 0
      }&isUpcommingSchedule=${model?.isUpcommingSchedule}`;
      let res = await getAxios().get(url1);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const RouteTravelHistoryForAddItemHistrory = createAsyncThunk(
  "RouteTravelHistoryForAddItemHistrory",
  async (model, thunkApi) => {
    try {
      // await getAxios().post(`RouteTravelHistory`, model);
      // let url1 = `Driver/${model?.driverId}/GetSchedule`;
      // let res = await getAxios().get(url1);
      // return res.data;
      await getAxios().post(
        `Driver/${model?.driverId}/RouteTravelHistory`,
        model
      );
      let url1 = `Driver/${model?.driverId}/schedule?isTodaysSchedule=${
        model?.isTodaysSchedule || false
      }&driverId=${model?.driverId}&scheduleId=${model?.scheduleId || 0}`;
      let res = await getAxios().get(url1);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const ReachedStop = createAsyncThunk(
  "ReachedStop",
  async (model, thunkApi) => {
    try {
      await getAxios().post(`Driver/${model?.driverId}/Reached`, model);
      let url1 = `Driver/${model?.driverId}/schedule`;
      let res = await getAxios().get(url1);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cancelRideStop = createAsyncThunk(
  "cancelRideStop",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().put(
        `schedule/${model?.scheduleId}/item/cancel`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
