import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchedualItemValidationSchema } from "src/helpers/validation";
import { CommonCity } from "src/components/dropdowns/City";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
  weekdays,
} from "src/constant";
import { EditScheduleOrder } from "src/thunks/scheduleItem";
import AllCites from "src/constant/fi.json";

import { t } from "i18next";
import { InlineLoader } from "src/components/InlineLoader";
import TimePicker from "src/components/TimeComponent";
import AutoCompleteComponent from "src/components/AutoComplete";

const EditScheduletemForm = ({
  toggleHandler,
  editData,
  setEditFun,
  rowData,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.ScheduleItems?.loading);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SchedualItemValidationSchema),
  });

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        id: editData?.id,
        startTime: data?.startTime || "",
        endTime: data?.endTime || "",
        sourceAddressId: editData?.sourceAddress?.id,
        sourceStreet1: data?.sourceStreet1,
        sourceStreet2: data?.sourceStreet2,
        sourceCountry: data?.sourceCountry,
        sourceState: data?.sourceState,
        sourceCityName: data?.sourceCityName,
        cityName: data?.sourceAddress,
        sourceZip: data?.sourceZipCode,
        source_ZipAreaCode: data?.sourceZipArea || "",
        sourceDescription: data?.sourceDescription,
        sourceStopId: data?.sourceStop || "",
        destinationAddressId: editData?.destinationAddress?.id,
        destinationStreet1: data?.destinationStreet1,
        destinationStreet2: data?.destinationStreet2,
        destinationCountry: data?.destinationCountry,
        destinationState: data?.destinationState,
        destinationCityName: data?.destinationCityName,
        destinationZip: data?.destinationZipCode,
        destination_ZipAreaCode: data?.destinationZipArea || "",
        destinationDescription: data?.destinationDescription || "",
        destinationStopId: data?.destinationStop || "",
        arrivalTime: data?.arrivalTime || "",
        departureTime: data?.departureTime,
        transportTypeId: data?.transportType,
        weekDayNo: data?.weekDayNo || "",
        isCancel: data?.isCancel || false,
        isActive: true,
        orderId: rowData?.id,
      };
      const res = await dispatch(EditScheduleOrder({ ...newData })).unwrap();
      if (res) {
        toggleHandler();
        toast.success(t("toast.scheduleItemEdited"));
        setEditFun({});
      }
    } catch (error) {
      // toast.success(error?.message);
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    reset({
      itemName: data?.itemName || "",
      startTime: data?.startTime || "",
      endTime: data?.endTime || "",
      destinationStreet1: data?.destinationAddress?.street1 || "",
      destinationStreet2: data?.destinationAddress?.street2 || "",
      destinationCityName: data?.destinationAddress?.cityName || "",
      destinationState: data?.destinationAddress?.state || "",
      destinationCountry: data?.destinationAddress?.country || "",
      destinationZipCode: data?.destinationAddress?.zip || "",
      destinationDescription: data?.destinationDescription || "",
      destinationStop: data?.destinationStopId || "",
      destinationZipArea: data?.destinationAddress?.zipAreaCode || "",

      sourceStreet1: data?.sourceAddress?.street1 || "",
      sourceStreet2: data?.sourceAddress?.street2 || "",
      sourceCityName: data?.sourceAddress?.cityName || "",
      sourceState: data?.sourceAddress?.state || "",
      sourceCountry: data?.sourceAddress?.country || "",
      sourceZipCode: data?.sourceAddress?.zip || "",
      sourceZipArea: data?.sourceAddress?.zipAreaCode || "",

      sourceDescription: data?.sourceDescription || "",
      sourceStop: data?.sourceStopId || "",
      arrivalTime: data?.arrivalTime || "",
      departureTime: data?.departureTime || "",
      transportType: data?.transportTypeId || "",
      weekDayNo: data?.weekDayNo || "",
      isCancel: data?.isCancel || false,
    });
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  useEffect(() => {
    setEditData(editData);
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} style={{ height: "100%" }}>
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="forminputTitle">
                {t("contract.itemName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="itemName"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      disabled
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.itemName?.message}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Typography className="forminputTitle">
                {t("schedule.weekDay")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="weekDayNo"
                render={({ field: { onChange, value } }) => {
                  let selectedValue = _.find(
                    weekdays,
                    (d) => d.value == value
                  )?.label;
                  if (selectedValue) {
                    return (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        onChange={(e, value) => onChange(value?.value || "")}
                        value={_.find(weekdays, (d) => d.value == value) || ""}
                        options={weekdays}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={placeHolderMessage("schedule.weekDay")}
                            size="small"
                          />
                        )}
                      />
                    );
                  } else {
                    return (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        onChange={(e, value) => onChange(value?.value || "")}
                        value={_.find(weekdays, (d) => d.value == value) || ""}
                        options={weekdays}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={placeHolderMessage("schedule.weekDay")}
                            size="small"
                          />
                        )}
                      />
                    );
                  }
                }}
              />
              <span className="errorMessageTitle">
                {errors?.weekDayNo?.message
                  ? errorMessageChangeInFinish("schedule.weekDay")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.startTime")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TimePicker
                      onTimeSelected={onChange}
                      changedValue={value}
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.startTime?.message
                  ? errorMessageChangeInFinish("schedule.startTime")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.endTime")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="endTime"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TimePicker
                      onTimeSelected={onChange}
                      changedValue={value}
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.endTime?.message
                  ? errorMessageChangeInFinish("schedule.endTime")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={12}>
              <Divider>{t("schedule.sourceDetails")}</Divider>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.street1")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="sourceStreet1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street1")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.sourceStreet1?.message
                  ? errorMessageChangeInFinish("schedule.sourceDetails")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.street2")}{" "}
              </Typography>
              <Controller
                control={control}
                name="sourceStreet2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street2")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.cityName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="sourceCityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.sourceCityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.state")}
              </Typography>
              <Controller
                control={control}
                name="sourceState"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.state")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.country")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="sourceCountry"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.country")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.sourceCountry?.message
                  ? errorMessageChangeInFinish("common.country")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zip")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="sourceZipCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.zip")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.sourceZipCode?.message
                  ? errorMessageChangeInFinish("common.zip")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zipAreaDescription")}
              </Typography>
              <Controller
                control={control}
                name="sourceZipArea"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.description")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="sourceDescription"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("schedule.description")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.sourceDescription?.message
                  ? errorMessageChangeInFinish("schedule.description")
                  : ""}
              </span>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.stopName")}
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                control={control}
                name="sourceStop"
                render={({ field: { onChange, value } }) => (
                  <CommonStops
                    onChange={onChange}
                    value={value}
                    rowData={rowData}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.sourceStop?.message
                  ? errorMessageChangeInFinish("schedule.stopName")
                  : ""}
              </span>
            </Grid> */}

            <Grid item xs={12}>
              <Divider>{t("schedule.destinationDetails")}</Divider>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.street1")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="destinationStreet1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street1")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.destinationStreet1?.message
                  ? errorMessageChangeInFinish("common.street1")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.street2")}{" "}
              </Typography>
              <Controller
                control={control}
                name="destinationStreet2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street2")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.cityName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="destinationCityName"
                render={({ field: { onChange, value } }) => (
                  <CommonCity
                    onChange={onChange}
                    value={value}
                    id="destinationCityName"
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.destinationCityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.state")}{" "}
              </Typography>
              <Controller
                control={control}
                name="destinationState"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.state")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.country")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="destinationCountry"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.country")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.destinationCountry?.message
                  ? errorMessageChangeInFinish("common.country")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zip")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="destinationZipCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.zip")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.destinationZipCode?.message
                  ? errorMessageChangeInFinish("common.zip")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zipAreaDescription")}
              </Typography>
              <Controller
                control={control}
                name="destinationZipArea"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.description")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="destinationDescription"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("schedule.description")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.destinationDescription?.message
                  ? errorMessageChangeInFinish("schedule.description")
                  : ""}
              </span>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.stopName")}
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                control={control}
                name="destinationStop"
                render={({ field: { onChange, value } }) => (
                  <CommonStops
                    onChange={onChange}
                    value={value}
                    rowData={rowData}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.destinationStop?.message
                  ? errorMessageChangeInFinish("schedule.stopName")
                  : ""}
              </span>
            </Grid> */}
          </Grid>
        </div>

        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type="submit"
            className="saveButton ms-2"
            sx={{
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
          >
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditScheduletemForm;
