import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginForm from "./components/login";
import ForgetPassword from "./components/ForgotPassword";

const Home = () => {
  return (
    <div className="main-page-container">
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/password" element={<ForgetPassword />} />
      </Routes>
    </div>
  );
};

export default Home;
