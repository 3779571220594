import { createSlice } from "@reduxjs/toolkit";
import {
  signIn,
  getUserById,
  DriverShiftLog,
  DriverShiftStartAndEnd,
} from "../thunks/auth";

const initState = {
  loading: false,
  token: localStorage.getItem("token"),
  userDetail: JSON.parse(localStorage.getItem("userDetail")),
  driverShift: {},
  shiftStarted: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload?.token;
        state.userDetail = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        let userDetail = {
          ...state.userDetail,
          ...action.payload,
        };
        localStorage.setItem("userDetail", JSON.stringify(userDetail));
        state.loading = false;
        state.userDetail = { ...userDetail };
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DriverShiftLog.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DriverShiftLog.fulfilled, (state, action) => {
        let driverShift = {
          ...action.payload,
        };
        state.shiftStarted = action?.payload?.id == 0 ? false : true;
        state.loading = false;
        state.driverShift = { ...driverShift };
      })
      .addCase(DriverShiftLog.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DriverShiftStartAndEnd.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DriverShiftStartAndEnd.fulfilled, (state, action) => {
        let driverShift = {
          ...action.payload,
        };

        state.shiftStarted = driverShift?.shiftState;
        state.loading = false;
        state.driverShift = { ...driverShift };
      })
      .addCase(DriverShiftStartAndEnd.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default AuthSlice.reducer;
