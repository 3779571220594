import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import AddSchoolForm from "./AddSchoolForm.jsx";
import { DeleteSchool, FetchSchoolData } from "src/thunks/school.js";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { GetError } from "src/constant/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import Table from "src/components/Table/index.js";
import "./SchoolStyle.scss";

const SchoolList = () => {
  // ---------------- Table Columns -------------------------
  let column = [
    { title: t("school.schoolName"), field: "name" },
    { title: t("company.address"), field: "address" },
    { title: t("company.city"), field: "city" },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    schoolStateData?.schoolData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // -------------------- React Global States -----------------------
  const schoolStateData = useSelector((state) => state?.School || []);

  // -------------------- React Hooks -------------------------------
  const dispatch = useDispatch();

  // -------------------- Component Local States --------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // ------------------- API Call Functions --------------------------

  const getAllSchoolData = async () => {
    try {
      let res = await dispatch(
        FetchSchoolData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessages")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteSchool({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.schoolDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            "Something Went Wrong."
        )
      );
    }
  };

  // ----------------- Local Helper Functions -------------------------

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const mapData = (data) => {
    return data.map((school) => {
      return {
        ...school,
        address: `${school?.address?.street1 || ""}, ${
          school?.address?.street2 || ""
        }, ${school?.address?.zip}, ${school?.address?.zipAreaCode}, ${
          school?.address?.cityName
        }, ${school?.address?.state || ""}, ${school?.address?.country || ""} `,
        city: school?.address?.cityName,
      };
    });
  };
  // ---------------- Component Life Cycle Methods ---------------------

  // useEffect(() => {
  //   getAllSchoolData();
  // }, []);

  useEffect(() => {
    getAllSchoolData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      <Table
        loading={schoolStateData?.loading || false}
        headerTitle={t("school.school")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={column}
        Data={mapData(schoolStateData?.schoolData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={schoolStateData?.schoolData?.totalPages}
        totalItems={schoolStateData?.schoolData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={editData?.id ? t("school.editSchool") : t("school.addNewSchool")}
        subtitle={
          editData?.id
            ? t("school.editSchoolProfile")
            : t("school.createNewSchoolProfile")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddSchoolForm
          currentPage={page}
          pageSize={rowsPerPage}
          setEditFun={setEditData}
          editData={editData}
          toggleHandler={() => openAndCloseModel()}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are You Sure To Delete?"}
        subtitle={"Yes"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={schoolStateData?.loading}
      />
    </div>
  );
};

export default SchoolList;
