import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import RideList from "./components/list";

const RidesHome = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>
          <Route path={"list"} element={<RideList />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default RidesHome;
