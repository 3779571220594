import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Grid, Typography } from "@mui/material";
import { t } from "i18next";

export default function Tags({ dropdownData, title, setApiIds }) {
  const setIds = (dataArray) => {
    if (title == "route") {
      let data = dataArray?.map((ele, i) => {
        return ele.id;
      });
      setApiIds({ parentIds: [], schoolIds: [], routeIds: data });
    } else if (title == "institute") {
      let data = dataArray?.map((ele, i) => {
        return ele.id;
      });
      setApiIds({ parentIds: [], schoolIds: data, routeIds: [] });
    } else if (title == "individual") {
      let data = dataArray?.map((ele, i) => {
        return ele.contactId;
      });
      setApiIds({ parentIds: data, schoolIds: [], routeIds: [] });
    }
  };
  return (
    <Stack>
      <Autocomplete
        multiple
        size="small"
        id="tags-outlined"
        options={dropdownData}
        onChange={(ev, value) => {
          setIds(value);
        }}
        getOptionLabel={(option) => option.title}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("notification.chooserecipient")}
          />
        )}
      />
    </Stack>
  );
}
