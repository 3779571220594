import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useThemeContext } from "src/contexts/theme";
import Table from "src/components/Table/index.js";
import { useSelector } from "react-redux";
import "./style.scss";
import { t } from "i18next";
import moment from "moment";


const rows = [
  {
    schoolName: "Halikko Upper Secondary School",
    busNo: "HLk 709",
    pickupStop: "Prismantie 2, 24800",
    pickupTime: "07:00 AM",
    assignedDriver: "Brandon M.",
    status: "CONFIRM",
  },
  {
    schoolName: "Halikko Upper Secondary School",
    busNo: "HLk 709",
    pickupStop: "Prismantie 2, 24800",
    pickupTime: "07:00 AM",
    assignedDriver: "Brandon M.",
    status: "CANCELLED",
  },
];

const DriverList = () => {
  const themeObj = useThemeContext();

  const UserData = useSelector((state) => state?.Auth?.userDetail);

  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };

  return (
    <div className="dashboard-main-container">
      <div>
        <div style={{ paddingLeft: 5 }} className={`${getThemedBgColor()}`}>
          <div
            style={{
              height: 300,
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className="welcomeMessage">
                  <div className="message1">{t("common.welcomeBack")}</div>
                  <div className="message2">
                    {UserData?.firstName} {UserData?.lastName}
                  </div>
                  <div className="message3">{t("common.welcomeText")}</div>
                </div>
              </Grid>
              {/* <Grid item xs={7}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div className="overlap-wrapper">
                      <div className="overlap-3">
                        <div className="child-education">Ongoing Rides</div>
                        <p className="child-education-2">
                          Rides reaching <br />
                          to their destination
                        </p>
                        <div className="child-education-3">16+</div>
                        <div className="ellipse-3" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="overlap-wrapper">
                      <div className="overlap-3">
                        <div className="child-education-4">Upcoming Rides</div>
                        <p className="child-education-2">
                          Rides have yet to <br />
                          start their journey
                        </p>
                        <div className="child-education-3">09</div>
                        <div className="ellipse-3" />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container xs={2.5} className="schedule-update">
                      Hii
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ marginInline: "90px", marginTop: "46px" }}>
        {/* <Grid container spacing={4}>
          <Grid item sm={9}>
            <Grid container>
              <Grid item sm={12}>
                <Typography className="table-heading">
                  Today's Schedule
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Table
                  tableTittle={false}
                  columns={columns}
                  Data={[...rows, ...rows, ...rows, ...rows, ...rows, ...rows]}
                  options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    search: false,
                    toolbar: false,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={3} sx={{ marginTop: "74px" }}>
            <Grid className="monthly-analysis">
              <Typography className="monthly-analysis-heading">
                Montly analysis
              </Typography>
              <Typography className="monthly-analysis-sub-heading">
                Overview of newly added users
              </Typography>
            </Grid>

            <Grid className="recently-added-drivers" sm={12}>
              <Grid>
                <Typography className="recently-added-drivers-heading">
                  Recently Added Drivers
                </Typography>
                <Typography className="recently-added-drivers-sub-heading">
                  Overview of newly added drivers
                </Typography>
              </Grid>
              <Grid className="recently-added-drivers-container" sm={12}>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  sm={12}
                >
                  <Grid className="recently-added-drivers-image">
                    <img
                      className="recently-added-drivers-image"
                      src="https://xsgames.co/randomusers/assets/avatars/male/46.jpg"
                      alt="not found"
                    ></img>
                    <Grid>
                      <Typography className="recently-added-drivers-name">
                        Juha Mäkinen
                      </Typography>
                      <Typography className="recently-added-drivers-id">
                        #1234567896
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid>
                    <Typography className="recently-added-drivers-added-info">
                      Added
                    </Typography>
                    <Typography className="recently-added-drivers-added-info">
                      Yesterday
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </div>
    </div>
  );
};
export default DriverList;
