import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import UserProfile from "./components/profile";

const UserProfilePage = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route
            path={`/`}
            element={
              <Navigate replace="/" to={`${Location.pathname}/userProfile`} />
            }
          ></Route>
          <Route path={"userProfile"} element={<UserProfile />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default UserProfilePage;
