import { createSlice } from "@reduxjs/toolkit";
import { AddUser, DeleteUser, EditUser, FetchUserData } from "../thunks/user";

const initState = {
  loading: false,
  UserData: [],
};

const UserSlice = createSlice({
  name: "User",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.UserData = action.payload;
      })
      .addCase(FetchUserData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddUser.fulfilled, (state, action) => {
        state.loading = false;
        state.UserData = action.payload;
      })
      .addCase(AddUser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.UserData = action.payload;
      })
      .addCase(DeleteUser.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditUser.fulfilled, (state, action) => {
        state.loading = false;
        state.UserData = action.payload;
      })
      .addCase(EditUser.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default UserSlice.reducer;
