import React from "react";
import "./componentLoader.css";

export const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};
