import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema, sendEmailSchema } from "src/helpers/validation";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendEmailOtp, signIn } from "src/thunks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetError } from "src/constant";
import logo from "src/assets/images/Group 178038.png";
import { t } from "i18next";

const EmailComponent = ({ setShowContent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendEmailSchema),
  });

  const onEmailSubmitHandler = async (data) => {
    try {
      let res = await dispatch(sendEmailOtp({ email: data.email })).unwrap();
      if (res) {
        toast.success("Otp Sent Succesfully");
        setShowContent("set-otp");
        localStorage.setItem("email", data?.email);
      }
    } catch (error) {
      toast.error(
        GetError(
          GetError(
            error.response?.data?.errors ||
              error.response?.data?.detail ||
              error?.message ||
              "Error In Fetching Data."
          )
        )
      );
    }
  };

  return (
    <Grid item sx={{ backgroundColor: "white" }} sm={7} xs={12}>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          height: window.innerHeight - 1,
        }}
        container
        xs={12}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingInline: "16px",
            }}
            item
            sm={6.5}
            xs={12}
          >
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <img src={logo} alt="" className="busteri-logo" />
              </Box>

              <Typography
                style={{
                  fontFamily: "Albert Sans",
                  fontSize: "22px",
                  fontWeight: 700,
                }}
              >
                {t("common.emailWindowMSG")}
              </Typography>
              <form onSubmit={handleSubmit(onEmailSubmitHandler)}>
                <Box>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        id="outlined-basic"
                        placeholder={t("common.emailPlaceholder")}
                        variant="outlined"
                        className="email-textbox"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      />
                    )}
                  />
                  <label>{errors?.email?.message || ""}</label>
                </Box>

                <Box
                  sx={{
                    paddingBlock: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{ color: "black" }}
                    onClick={() => navigate("/login")}
                    size="small"
                  >
                    {t("common.backToLogin")}
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                    className="login-button"
                  >
                    {" "}
                    {t("common.getOtp")}
                  </Button>
                </Box>
              </form>

              <Typography className="trademark">
                {t("common.footer")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailComponent;
