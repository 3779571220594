import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { toast } from "react-toastify";
import _ from "lodash";
import Table from "src/components/Table/index.js";
import AddSchoolUserFrom from "./AddSchoolUserFrom.jsx";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import {
  DeleteSchoolUser,
  FetchSchoolUserData,
} from "src/thunks/schoolUser.js";
import { GetError } from "src/constant/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./SchoolUserStyle.scss";

const SchoolUserList = () => {
  // -------------------- Table Columns ------------------------------

  let columns = [
    { title: t("school.school"), field: "schoolName" },
    { title: t("common.fullName"), field: "fullName" },
    { title: t("common.cityName"), field: "City" },
    { title: t("common.address"), field: "address" },
    { title: t("common.email"), field: "email" },
    { title: t("common.phoneNumber"), field: "phoneNumber" },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    schoolUserStateData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // ------------------- Redux Global States --------------------------
  const schoolUserStateData = useSelector(
    (state) => state?.SchoolUser?.schoolUserData || []
  );

  const loading = useSelector((state) => state?.SchoolUser?.loading);

  // ------------------- React Hook ----------------------------------
  const dispatch = useDispatch();

  // ------------------- Component Local States ----------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // ------------------- API Call functions --------------------------

  const getAllSchoolUserData = async () => {
    try {
      await dispatch(
        FetchSchoolUserData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteSchoolUser({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.schoolUserDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // -------------------- Local Helper Functions -----------------------------

  const mapData = (data) => {
    return data.map((schoolUser) => {
      return {
        ...schoolUser,
        City: schoolUser?.user?.address?.cityName,
        address: `${schoolUser?.user?.address?.street1 || ""}, ${
          schoolUser?.user?.address?.street2 || ""
        }, ${schoolUser?.user?.address?.zip}, ${
          schoolUser?.user?.address?.zipAreaCode
        }, ${schoolUser?.user?.address?.cityName}, ${
          schoolUser?.user?.address?.state || ""
        }, ${schoolUser?.user?.address?.country || ""} `,
        fullName: `${schoolUser?.user?.firstName || ""} ${
          schoolUser?.user?.lastName || ""
        }`,
        profileUrl: `${schoolUser?.user?.profileUrl || ""}`,
        email: `${schoolUser?.user?.email || ""}`,
        phoneNumber: `${schoolUser?.user?.phoneNumber || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  // --------------------- Component Life Cycle Methods --------------------

  // useEffect(() => {
  //   getAllSchoolUserData();
  // }, []);

  useEffect(() => {
    getAllSchoolUserData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      <Table
        loading={loading}
        headerTitle={t("schoolUser.schoolUser")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        Data={mapData(schoolUserStateData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={schoolUserStateData?.totalPages}
        totalItems={schoolUserStateData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={
          editData?.id
            ? t("schoolUser.editSchoolUser")
            : t("schoolUser.addNewSchoolUser")
        }
        subtitle={
          editData?.id
            ? t("schoolUser.editSchoolUserProfile")
            : t("schoolUser.createNewSchoolUser")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddSchoolUserFrom
          currentPage={page}
          pageSize={rowsPerPage}
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are You Sure To Delete?"}
        subtitle={"Yes"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={loading}
      />
    </div>
  );
};
export default SchoolUserList;
