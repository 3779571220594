import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import { Add } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { StopValidationSchema } from "src/helpers/validation";
import { EditRoute } from "src/thunks/route";
import { GetError } from "src/constant";
import "./RouteStyle.scss";
import { InlineLoader } from "src/components/InlineLoader";

const AddNewStops = ({
  toggleHandler,
  editData,
  setEditFun,
  dataForAddNewStop,
  currentPage,
  pageSize,
}) => {
  // ---------------- React Global States ----------------
  const [stops, setStops] = useState([...dataForAddNewStop?.routeStops]);

  // ---------------- React Hooks ------------------------
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.Route?.loading);

  // ---------------- React Hook Form --------------------
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(StopValidationSchema),
  });

  // -------------- API Call Functions -----------------------

  const onSubmitHandler = async (data) => {
    const stopss = stops.map((d, i) => {
      return {
        ...d,
        sequence: i + 1,
      };
    });

    try {
      let newData = {
        ...dataForAddNewStop,
        isActive: true,
        routeStops: [...stopss],
        currentPage: currentPage,
        pageSize: pageSize,
        paging: true,
      };
      const res = await dispatch(EditRoute({ ...newData })).unwrap();
      if (res) {
        toggleHandler();
        toast.success(t("toast.stopAdded"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // --------------- Local Helper Functions ---------------
  const addNewStop = () => {
    setStops([
      ...stops,
      {
        routeId: 1,
        name: "",
        sequence: uuidv4(),
      },
    ]);
  };

  const removeStop = (index) => {
    const updatedStops = stops.filter((_, i) => i !== index);
    setStops(updatedStops);
  };

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} style={{ height: "100%" }}>
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {stops.map((stop, index) => {
                return (
                  <div key={index}>
                    <Typography className="forminputTitle">
                      {t("route.stop")} {index + 1}
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id={`stop-${index}`}
                        placeholder={`Enter Stop ${index + 1}`}
                        variant="outlined"
                        size="small"
                        type="text"
                        onChange={({ target }) => {
                          let newStops = _.cloneDeep(stops);
                          newStops[index]["name"] = target.value;
                          setStops([...newStops]);
                        }}
                        value={stop?.name}
                        fullWidth
                      />
                      <CancelIcon
                        color="black"
                        sx={{
                          marginLeft: "10px",
                          height: "25px",
                          width: "25px",
                          cursor: "pointer",
                          backgroundColor: "#00B3D1",
                          borderRadius: "100%",
                          color: "white",
                          padding: "5px",
                        }}
                        onClick={() => removeStop(index)}
                      />
                    </Grid>
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <Grid>
                <Add
                  sx={{
                    backgroundColor: "#00B3D1",
                    borderRadius: "100%",
                    color: "white",
                    height: "22px",
                    width: "22px",
                  }}
                />
                <Button
                  sx={{
                    fontFamily: "Albert Sans",
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "black",
                    fontWeight: 600,
                  }}
                  size="small"
                  onClick={addNewStop}
                >
                  {t("route.addNewStops")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewStops;
