import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";
import axios from "axios";
import { AppConfigs } from "../settings";

export const FetchAllCities = createAsyncThunk(
  "FetchAllCities",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post("common/cities", { isActive: true });
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const FetchLookupValuesForDropdown = createAsyncThunk(
  "FetchLookupValuesForDropdown",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post("common/lookup", {
        type: model?.type,
        isActive: true,
      });
      return {
        data: res.data,
        type: model?.type,
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DownloadFile = createAsyncThunk(
  "DownloadFile",
  async (model, thunkApi) => {
    try {
      let userDetails = localStorage.getItem("token");
      let res = await axios.get(
        `${AppConfigs.apiBaseUrl}common/download?filePath=${model?.filePath}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails}`,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = model?.fileName || "ErrorFile.csv";
      link.click();
      return res?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
