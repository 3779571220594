/* global tinymce */

import React, { useState, useEffect, useRef } from "react";
import "./NotificationsStyle.scss";
import { useDispatch } from "react-redux";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Dropdowns from "src/components/AutoCompleteWithMultipleSelect/index";
import { AddNofications } from "src/thunks/notifications";
import { FetchRouteData } from "src/thunks/route.js";
import { FetchSchoolData } from "src/thunks/school";
import { FetchContactData } from "src/thunks/contact";
import { getAllOrderItem } from "src/thunks/orderItems";
import { LoadingScreen } from "src/components/Loaders/componentLoader";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { GetError } from "src/constant";
import { t } from "i18next";
import JoditEditotRichText from "src/components/JoditEditor/inde";

const NotificationsList = () => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const radioRef = useRef(null);

  const [dropdownData, setDropDownData] = useState([]);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState({
    parentIds: [],
    schoolIds: [],
    routeIds: [],
    subject: "",
    message: "",
    fromDate: "",
    toDate: "",
  });

  const getDropdownData = async (data) => {
    try {
      if (data === "route") {
        setDropDownData([]);
        setApiData({
          parentIds: [],
          schoolIds: [],
          routeIds: [],
          subject: "",
          message: "",
          fromDate: "",
          toDate: "",
        });
        setLoading(true);
        setTitle("route");
        let routeResponse = await dispatch(
          FetchRouteData({ currentPage: 1, pageSize: 10, paging: false })
        ).unwrap();
        if (routeResponse) {
          setLoading(false);
          let response = routeResponse?.result?.map((data) => {
            let newData = { ...data };
            newData.title = data.name;
            return newData;
          });
          setDropDownData([...response]);
        }
      } else if (data === "institute") {
        setDropDownData([]);
        setApiData({
          parentIds: [],
          schoolIds: [],
          routeIds: [],
          subject: "",
          message: "",
          fromDate: "",
          toDate: "",
        });
        setTitle("institute");
        setLoading(true);
        let schoolResponse = await dispatch(
          FetchSchoolData({ currentPage: 1, pageSize: 10, paging: false })
        ).unwrap();
        if (schoolResponse) {
          setDropDownData([]);

          setLoading(false);
          let response = schoolResponse?.result?.map((data) => {
            let newData = { ...data };
            newData.title = data.name;
            return newData;
          });
          setDropDownData([...response]);
        }
      } else if (data == "individual") {
        setDropDownData([]);
        setApiData({
          parentIds: [],
          schoolIds: [],
          routeIds: [],
          subject: "",
          message: "",
        });
        setTitle("individual");
        setLoading(true);
        let contactResponse = await dispatch(getAllOrderItem()).unwrap();
        console.log("Response : ", contactResponse);
        if (contactResponse) {
          setLoading(false);
          let response = contactResponse?.map((data) => {
            let newData = { ...data };
            newData.title = `${data.itemName}`;
            return newData;
          });
          setDropDownData([...response]);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorInDeletingContract")
        )
      );
    }
  };

  const postNotificationData = async () => {
    try {
      if (buttonDisabledFunction()) {
        toast.error("Please enter receptent, subject, message");
        return;
      }
      setLoading(true);
      let notificationResponse = await dispatch(
        AddNofications(apiData)
      ).unwrap();

      if (notificationResponse) {
        setLoading(false);
        toast.success("Notifications Sends Successfully!!");
        setDropDownData([]);
        setApiData({
          parentIds: [],
          schoolIds: [],
          routeIds: [],
          subject: "",
          message: "",
          fromDate: "",
          toDate: "",
        });
        setTitle("");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorInDeletingContract")
        )
      );
    }
  };

  const buttonDisabledFunction = () => {
    return (apiData?.parentIds?.length > 0 ||
      apiData?.routeIds?.length > 0 ||
      apiData?.schoolIds?.length > 0) &&
      apiData?.message?.length > 0 &&
      apiData?.subject?.length > 0
      ? false
      : true;
  };

  const showEmailData = () => {
    if (dropdownData.length > 0) {
      return title == "route" || title == "institute"
        ? apiData?.fromDate == "" || apiData?.toDate == ""
          ? false
          : true
        : dropdownData?.length > 0
        ? true
        : false;
    } else {
      return false;
    }
  };
  console.log("apidata : ", apiData);

  return (
    <div className="notifications-master-container">
      {loading && <LoadingScreen />}
      <Grid className="heading-container">
        <Grid className="heading-content-container">
          <Typography className="heading-text">
            {t("notification.notification")}
          </Typography>
        </Grid>
        <Grid sx={{ display: "flex" }}>
          <Grid className="options-container">
            <FormControl className="options-form-control">
              <Grid className="options-header-container">
                <Typography className="option-headingtext">
                  {t("notificiation.notifyuser")}
                </Typography>
              </Grid>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(ev, value) => {
                  getDropdownData(value);
                }}
                sx={{ "& .MuiSvgIcon-root": { color: "#00b3d1" } }}
                ref={radioRef}
              >
                <FormControlLabel
                  value="route"
                  control={<Radio />}
                  label={t("notification.byroute")}
                />
                <FormControlLabel
                  value="institute"
                  control={<Radio />}
                  label={t("notification.byinstitute")}
                />
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label={t("notification.individual")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {title == "route" || title == "institute" ? (
            <Grid className="options-container">
              <Grid className="options-form-control d-flex">
                <Grid sx={{ paddingRight: "5px" }}>
                  <Grid className="options-header-container d-flex  ">
                    <Typography className="option-headingtext">
                      {t("schedule.fromDate")}
                    </Typography>
                    <div className="asteric " style={{ paddingLeft: "5px" }}>
                      *
                    </div>
                  </Grid>
                  <TextField
                    type="date"
                    size="small"
                    onChange={(ev) =>
                      setApiData({ ...apiData, fromDate: ev.target.value })
                    }
                    value={apiData?.fromDate}
                  />
                </Grid>
                <Grid>
                  <Grid className="options-header-container d-flex">
                    <Typography className="option-headingtext">
                      {t("schedule.toDate")}
                    </Typography>
                    <div className="asteric " style={{ paddingLeft: "5px" }}>
                      *
                    </div>
                  </Grid>
                  <TextField
                    type="date"
                    size="small"
                    onChange={(ev) =>
                      setApiData({ ...apiData, toDate: ev.target.value })
                    }
                    value={apiData?.toDate}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      {showEmailData() ? (
        <Grid className="body-container">
          <Grid className="receptent-container">
            <Grid className="receptent-text-container">
              <Typography className="receptent-text">
                {t("notification.chooserecipient")}
              </Typography>
            </Grid>
            <Dropdowns
              dropdownData={dropdownData}
              title={title}
              setApiIds={(data) => setApiData({ ...apiData, ...data })}
            />
          </Grid>

          <Grid className="subject-container">
            <Grid className="subject-text-container">
              <Typography className="subject-text">
                {t("notification.subject")}
              </Typography>
            </Grid>
            <Grid className="input-container">
              <TextField
                size="small"
                fullWidth
                onChange={(ev) =>
                  setApiData({ ...apiData, subject: ev.target.value })
                }
                placeholder={t("notification.subject")}
              />
            </Grid>
          </Grid>

          <Grid className="body-message-container">
            <Grid className="message-text-container">
              <Typography className="message-text">
                {" "}
                {t("notification.message")}{" "}
              </Typography>
            </Grid>
            <Grid className="editor-container">
              {/* <Editor
                apiKey="tgli5sxtxyr5nnfjuzdncfgob1udb7yxzm4ygxb5m0egfu8j"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={apiData.message}
                onEditorChange={(content) =>
                  setApiData({ ...apiData, message: content })
                }
                init={{
                  plugins:
                    "ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                  tinycomments_mode: "embedded",
                  tinycomments_author: "Author name",
                  menubar: false,

                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                  branding: false,
                }}
              /> */}
              <JoditEditotRichText
                joditEditorValue={(content) => {
                  setApiData({ ...apiData, message: content });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <Button
              className="sendButton"
              onClick={() => {
                postNotificationData();
              }}
              disabled={buttonDisabledFunction()}
            >
              {t("notification.send")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Typography className="message-container">
          {t("notification.message2")}
        </Typography>
      )}
    </div>
  );
};

export default NotificationsList;
