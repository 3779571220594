import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchOrderData = createAsyncThunk(
  "FetchOrderData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `order?&contractNo=${model?.contractNo || ""}&itemName=${
          model?.itemName || ""
        }&fromDate=${model?.fromDate || ""}&toDate=${
          model?.toDate || ""
        }&currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddOrder = createAsyncThunk(
  "AddOrder",
  async (model, thunkApi) => {
    try {
      await getAxios().post("order", model);
      let res = await getAxios().get(
        `order?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditOrder = createAsyncThunk(
  "EditOrder",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`order/${model?.id}`, model);
      let res = await getAxios().get(
        `order?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteOrder = createAsyncThunk(
  "DeleteOrder",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`order/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `order?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize
        }&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
