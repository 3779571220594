import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FetchScheduleData } from "src/thunks/schedule";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import { GetError } from "src/constant";
import { t } from "i18next";

export const CommonSchedule = ({ onChange, value }) => {
  const dispatch = useDispatch();

  const AllSchedule = useSelector(
    (state) => state?.Schedule?.orderData?.result || []
  );
  const [svalue, setsValue] = useState("");

  useEffect(() => {
    let defaultData = _.find(AllSchedule, (d) => d.id == `${value}`);
    setsValue(defaultData?.scheduleNo || "");
  }, [value, AllSchedule]);

  const getAllScheduleData = async () => {
    try {
      await dispatch(
        FetchScheduleData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();
    } catch (error) {
      // toast.error(err?.message || "something issue");
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  useEffect(() => {
    getAllScheduleData();
  }, []);

  return (
    <Autocomplete
      disableClearable
      disablePortal
      id="combo-box-demo"
      onChange={(e, value) => onChange(value?.value || "")}
      value={svalue}
      options={AllSchedule?.map((d) => {
        return {
          label: d?.scheduleNo,
          value: d?.id,
        };
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("common.selectSchedule")}
          size="small"
        />
      )}
    />
  );
};
