import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Add, Event, Search } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import _ from "lodash";
import moment from "moment/moment.js";
import Table from "src/components/Table/index.js";
import AddScheduleForm from "./AddScheduleForm.jsx";
import { CommonModal } from "src/components/Modal/index.js";
import { ScheduleModal } from "src/components/AssignScheduleModal/index.js";
import { NewModal } from "src/components/NewModal/index.js";
import { ImportDataMdoal } from "src/components/ImportDataModal/index.js";
import AssignSchedule from "../components/AssignSchedule/index.js";
import DetailedPanel from "./DetailView/index.js";
import { DeleteSchedule, FetchScheduleData } from "src/thunks/schedule.js";
import { GetError } from "src/constant/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import SearchingComponent from "src/components/SearchingComponent/index.js";
import "./ScheduleStyle.scss";
import DetailView from "./DetailView2/index.js";

const ScheduleList = () => {
  // ------------------ Table Columns ---------------------

  let columns = [
    {
      title: t("common.action"),
      field: "customActions",
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    scheduleStateData?.orderData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
                deleteMessage(rowData);
              }}
            />
            <FileUploadOutlinedIcon
              className="uploadIcon"
              onClick={() => {
                setUploadingId(rowData?.id);
                setImportModalData(rowData);
                openAndCloseUploadmOdal();
              }}
            />
          </div>
        );
      },
    },
    {
      title: t("schedule.scheduleName"),
      field: "scheduleNo",
    },
    {
      title: t("schedule.fromDate"),
      field: "fromDate",
      render: (rowData) => moment(rowData?.fromDate).format("DD-MM-YYYY"),
    },
    {
      title: t("schedule.toDate"),
      field: "toDate",
      render: (rowData) => moment(rowData?.toDate).format("DD-MM-YYYY"),
    },
    // {
    //   title: t("schedule.startTime"),
    //   field: "startTime",
    //   render: (rowData) => rowData?.startTime,
    // },
    // {
    //   title: t("schedule.endTime"),
    //   field: "endTime",
    //   render: (rowData) => rowData?.endTime,
    // },

    {
      title: t("schedule.contract"),
      field: "orderNo",
    },
    {
      title: t("route.route"),
      field: "routeName",
    },
    {
      title: t("vehicle.vehicle"),
      field: "vehicleNo",
    },
    {
      title: t("schedule.driverName"),
      field: "driverName",
    },
  ];

  // ---------------- Redux Global States ----------------------
  const scheduleStateData = useSelector((state) => state?.Schedule || []);
  const loading = useSelector((state) => state?.Schedule?.loading);

  // ------------- React Hooks ---------------------
  const dispatch = useDispatch();
  const selectedItem = React.useRef([]);
  let DetailSelectedItem = React.useRef([]);

  // ------------------ Component Local States------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isScheduleModal, setIsScheduleModal] = useState(false);
  const [uploadingId, setUploadingId] = useState("");
  const [page, setPage] = React.useState(0);
  const [importModalData, setImportModalData] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteMessageState, setDeleteMessage] = useState("");
  const [searchingValues, setSearchingValues] = useState({
    contractNo: "",
    itemName: "",
    fromDate: "",
    toDate: "",
  });

  // -------------- API Call Functions ------------------------

  const getAllScheduleData = async (reset) => {
    try {
      if (!reset) {
        await dispatch(
          FetchScheduleData({
            currentPage: page + 1,
            pageSize: rowsPerPage,
            paging: true,
            ...searchingValues,
          })
        ).unwrap();
      } else {
        await dispatch(
          FetchScheduleData({
            currentPage: page + 1,
            pageSize: rowsPerPage,
            paging: true,
          })
        ).unwrap();
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteSchedule({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.scheduleDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // --------------------- Local Helper Functions ---------------------------

  const mapData = (data) => {
    return data?.map((schedule) => {
      return {
        ...schedule,
        address: `${
          schedule?.user?.address?.street1 ||
          schedule?.user?.address?.street2 ||
          ""
        } ${schedule?.user?.address?.cityName || ""} ${
          schedule?.user?.address?.state || ""
        } ${schedule?.user?.address?.country || ""} ${
          schedule?.user?.address?.zip || ""
        }`,
        fullName: `${schedule?.user?.firstName || ""} ${
          schedule?.user?.lastName || ""
        }`,
        profileUrl: `${schedule?.user?.profileUrl || ""}`,
        email: `${schedule?.user?.email || ""}`,
        phoneNumber: `${schedule?.user?.phoneNumber || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
    setDeleteMessage();
  };

  const openAndCloseUploadmOdal = () => {
    setIsUploadModal(!isUploadModal);
  };

  const openAndCloseSchedulemOdal = () => {
    if (isScheduleModal == true) {
      DetailSelectedItem.current = [];
      selectedItem.current = [];
    }

    if (isScheduleModal) {
      document.getElementById("assignSchedule").style.display = "none";
      document.getElementById("moveItems").style.display = "none";
    }
    setIsScheduleModal(!isScheduleModal);
  };

  const deleteMessage = (data) => {
    let newMessage =
      t("toast.scheduleDeleteModal1") +
      data?.scheduleNo +
      t("toast.scheduleDeleteModal2");

    setDeleteMessage({
      message: newMessage,
    });
  };

  // -------------------- Component Life Cycle Methods --------------------

  // useEffect(() => {
  //   getAllScheduleData();
  // }, []);

  useEffect(() => {
    getAllScheduleData();
  }, [page, rowsPerPage]);

  console.log("selectedItem?.current", selectedItem?.current);

  return (
    <div className="allcontainer">
      <div className="m-schedule-main-container">
        <div className="schedule-main-header">
          <div className="schedule-heading">{t("schedule.schedule")}</div>
          <div className="schedule-buttons-container">
            <div id="assignSchedule" style={{ display: "none" }}>
              <Event className="event-button" />
              <Button
                onClick={() => openAndCloseSchedulemOdal()}
                className="add-schedule-button"
                size="small"
              >
                {t("schedule.cloneSchedule")}
              </Button>
            </div>

            <div id="moveItems" style={{ display: "none" }}>
              <Event className="event-button" />
              <Button
                onClick={() => openAndCloseSchedulemOdal()}
                className="add-schedule-button"
                size="small"
              >
                {t("schedule.moveScheduleItem")}
              </Button>
            </div>

            <div className="add-new-schedule-container">
              <Add className="add-schedule-logo" />
              <Button
                onClick={() => openAndCloseModel()}
                className="add-schedule-button"
                size="small"
              >
                {t("schedule.addNewSchedule")}
              </Button>
            </div>
          </div>
        </div>

        <div
          className="searching-component-container"
          style={{ marginTop: "-25px" }}
        >
          <Grid className="searching-subcontainer" sm={12} container>
            {" "}
            <SearchingComponent
              value={(t) => {
                setSearchingValues({ ...searchingValues, ...t });
              }}
              onSubmit={() => getAllScheduleData()}
              clearFields={() => {
                let reset = true;
                getAllScheduleData(true);
              }}
            />
          </Grid>
        </div>
        <div>
          {/* <Table
            handleRowSelect={(rows) => {
              document.getElementById("assignSchedule").style.display =
                rows?.length === 1 ? "block" : "none";
              selectedItem.current = rows;
            }}
            loading={loading}
            tableTittle={false}
            columns={columns}
            Data={mapData(scheduleStateData?.orderData?.result || [])}
            detailPanel={(rowdata) => (
              <DetailedPanel
                rowData={rowdata}
                DetailSelectedItem={DetailSelectedItem}
                itemName={searchingValues?.itemName}
              />
            )}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
              selection: true,
              actions: true,
            }}
          /> */}
          <Table
            handleRowSelect={(rows) => {
              document.getElementById("assignSchedule").style.display =
                rows?.length === 1 ? "block" : "none";
              selectedItem.current = rows;
            }}
            isSchedule={true}
            loading={loading}
            tableTittle={false}
            columns={columns}
            Data={mapData(scheduleStateData?.orderData?.result || [])}
            detailPanel={(rowdata) => (
              <DetailedPanel
                rowData={rowdata}
                DetailSelectedItem={DetailSelectedItem}
                itemName={searchingValues?.itemName}
              />
              // <DetailView
              //   rowData={rowdata}
              //   DetailSelectedItem={DetailSelectedItem}
              //   itemName={searchingValues?.itemName}
              // />
            )}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
              selection: true,
              actions: true,
            }}
          />
          <PaginationComponent
            count={scheduleStateData?.orderData?.totalPages}
            totalItems={scheduleStateData?.orderData?.totalItems}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
        <CommonModal
          togal={isModal}
          title={
            editData?.id
              ? t("schedule.editSchedule")
              : t("schedule.addNewSchedule")
          }
          subtitle={
            editData?.id
              ? t("schedule.editScheduleDetails")
              : t("schedule.createNewSchedule")
          }
          toggleHandler={() => openAndCloseModel()}
        >
          <AddScheduleForm
            currentPage={page}
            pageSize={rowsPerPage}
            toggleHandler={() => openAndCloseModel()}
            editData={editData}
            setEditFun={setEditData}
          />
        </CommonModal>

        <ScheduleModal
          togal={isScheduleModal}
          title={
            selectedItem?.current?.length > 0
              ? t("schedule.cloneSchedule")
              : t("schedule.moveItemFromSchedule")
          }
          toggleHandler={() => openAndCloseSchedulemOdal()}
        >
          <AssignSchedule
            currentPage={page}
            pageSize={rowsPerPage}
            toggleHandler={() => openAndCloseSchedulemOdal()}
            selectedItem={selectedItem}
            DetailSelectedItem={DetailSelectedItem}
          />
        </ScheduleModal>

        <NewModal
          togal={isDeleteModal}
          title={true}
          toggleHandler={() => openAndCloseDeleteModel()}
          deleteButtonHandler={deleteButtonHandler}
          message={deleteMessageState}
          loading={loading}
        />

        <ImportDataMdoal
          moduleName="Schedule"
          uploadingId={uploadingId}
          togal={isUploadModal}
          title={"Update Modal"}
          toggleHandler={() => openAndCloseUploadmOdal()}
          rowData={importModalData}
        />
      </div>
    </div>
  );
};
export default ScheduleList;
