import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Grid } from "@mui/material";
import moment from "moment/moment.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PreviewIcon from "@mui/icons-material/Preview";
import DetailedPanel from "./DetailView/index.js";
import Table from "src/components/Table/index.js";
import {
  GetCurrentRideByDriverId,
  GetCurrentRideForDriver,
  GetScheduleByDriverId,
} from "src/thunks/schedule.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import DriverTable from "src/components/DriverTable/index.js";
import "./DriverStyle.scss";
import { LoadingScreen } from "src/components/Loaders/componentLoader.js";
import { CommonModal } from "src/components/Modal/index.js";
import { MobileModal } from "src/components/MobileModal/index.js";
import JourneyStarted from "./JourneyStarted.jsx";
import _ from "lodash";
import { DriverShiftLog } from "src/thunks/auth.js";
import { GetError } from "src/constant/index.js";

const UpcomingRides = () => {
  // ---------------- Table Columns -----------------------

  let columns = [
    {
      title: t("schedule.schedule"),
      field: "scheduleNo",
    },
    {
      title: t("driver.from"),
      field: "date",
      render: (rowData) => moment(rowData?.fromDate).format("DD-MMM-YYYY"),
    },
    {
      title: t("driver.to"),
      field: "date",
      render: (rowData) => moment(rowData?.toDate).format("DD-MMM-YYYY"),
    },
    // {
    //   title: t("driver.startTime"),
    //   field: "startTime",
    //   render: (rowData) => rowData?.startTime,
    // },
    // {
    //   title: t("driver.endTime"),
    //   field: "endTime",
    //   render: (rowData) => rowData?.endTime,
    // },

    {
      title: t("route.route"),
      field: "routeName",
    },
    {
      title: t("vehicle.vehicle"),
      field: "vehicleNo",
    },
    {
      title: t("common.journey"),
      field: "vehicleNo",
      render: (rowData) => {
        return (
          <div
            onClick={() => {
              setScheduleId(rowData);
              colloborateDataForDriverShift(rowData?.id);
              openAndCloseModel();
            }}
          >
            <button className="btn btn-dark btn-sm">{t("common.view")}</button>
          </div>
        );
      },
    },
  ];

  const mobileScreenColumns = [
    {
      title: t("common.action"),
      field: "endtime",
      render: (rowData) => {
        return (
          <div onClick={() => openAndCloseMobileModel()}>
            <PreviewIcon />
          </div>
        );
      },
    },
    {
      title: t("driver.scheduleNo"),
      field: "scheduleNo",
    },
    {
      title: t("driver.from"),
      field: "fromDate",
      render: (rowData) => moment(rowData?.fromDate).format("DD-MM-YYYY"),
    },
    {
      title: t("driver.to"),
      field: "toDate",
      render: (rowData) => moment(rowData?.toDate).format("DD-MM-YYYY"),
    },
    // {
    //   title: t("driver.startTime"),
    //   field: "startTime",
    //   render: (rowData) => rowData?.startTime,
    // },
    // {
    //   title: t("driver.endTime"),
    //   field: "endTime",
    //   render: (rowData) => rowData?.endTime,
    // },
  ];

  // ---------------- Redux States -------------------------
  const scheduleStateData = useSelector(
    (state) => state?.Schedule?.driverScheduleData || []
  );

  const loading = useSelector((state) => state?.Schedule?.loading);
  const UpcommingRidedata = useSelector(
    (state) => state?.Schedule?.currentRide
  );

  const UserData = useSelector((state) => state?.Auth?.userDetail);

  // ---------------- React Hooks --------------------------
  const dispatch = useDispatch();
  const selectedItem = React.useRef([]);

  // ---------------- Local Component States ----------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isModal, setIsModal] = useState(false);
  const [mobileModal, setMobileModal] = useState(false);
  const [currentSchedual, setCurrentSchedual] = useState([]);
  const [scheduleId, setScheduleId] = useState(0);
  const [ScheduleRoutes, setScheduleRoutes] = useState([]);

  // ---------------- Local Helper Functions -----------------

  const getAllScheduleData = async () => {
    try {
      let newObject = JSON.parse(window.localStorage.getItem("userDetail"));
      await dispatch(
        GetScheduleByDriverId({
          driverId: newObject?.transactionId,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
          isUpcommingSchedule: true,
        })
      ).unwrap();
    } catch (err) {
      toast.error(err?.message || t("toast.errorMessage"));
    }
  };

  const mapData = (data) => {
    return data?.map((userData) => {
      return {
        ...userData,
        address: `${
          userData?.user?.address?.street1 ||
          userData?.user?.address?.street2 ||
          ""
        } ${userData?.user?.address?.cityName || ""} ${
          userData?.user?.address?.state || ""
        } ${userData?.user?.address?.country || ""} ${
          userData?.user?.address?.zip || ""
        }`,
        fullName: `${userData?.user?.firstName || ""} ${
          userData?.user?.middleName || ""
        } ${userData?.user?.lastName || ""}`,
        profileUrl: `${userData?.user?.profileUrl || ""}`,
        email: `${userData?.user?.email || ""}`,
        phoneNumber: `${userData?.user?.phoneNumber || ""}`,
      };
    });
  };

  const getUpcomingRideData = () => {
    if (UpcommingRidedata?.length > 0) {
      let orderUpcommingSchedualData = _.orderBy(
        UpcommingRidedata,
        ["startTime"],
        ["asc"]
      );

      setCurrentSchedual(orderUpcommingSchedualData || []);
      setScheduleRoutes([
        ..._.filter(
          orderUpcommingSchedualData,
          (d) => d.driverItems.length > 0
        ),
      ]);
    }
  };

  const openAndCloseModel = () => {
    setIsModal(!isModal);
  };

  const openAndCloseMobileModel = () => {
    setMobileModal(!mobileModal);
  };

  const colloborateDataForDriverShift = (scheduleId) => {
    if (
      UserData?.roles?.includes("SchoolCoordinator") ||
      UserData?.roles?.includes("Driver") ||
      UserData?.roles?.includes("Contact")
    ) {
      const userRoleFind = UserData?.roles.includes("Contact")
        ? { contactId: UserData?.transactionId }
        : UserData?.roles.includes("SchoolCoordinator")
        ? { schoolUserId: UserData?.transactionId }
        : { driverId: UserData?.transactionId };

      getDriverShiftData(userRoleFind, scheduleId);
    }
  };

  const getDriverShiftData = async (data, scheduleId) => {
    if (UserData?.transactionId) {
      try {
        if (data?.driverId) {
          await dispatch(
            DriverShiftLog({ id: UserData?.transactionId })
          ).unwrap();
          const response = await dispatch(
            GetCurrentRideForDriver({
              ...data,
              isTodaysSchedule: false,
              scheduleId: scheduleId,
              driverId: data?.driverId,
              isUpcommingSchedule: true,
            })
          ).unwrap();
        } else {
          await dispatch(
            GetCurrentRideByDriverId({
              ...data,
              isTodaysSchedule: false,
            })
          ).unwrap();
        }
      } catch (error) {
        toast.error(
          GetError(
            error.response?.data?.errors ||
              error?.message ||
              "Problem While Fetching Data. Please Try Again."
          )
        );
      }
    }
  };

  // ------------------ Component Life Cycle Methods -------------

  // useEffect(() => {
  //   getAllScheduleData(page, rowsPerPage);
  // }, []);

  useEffect(() => {
    getAllScheduleData(page, rowsPerPage);
    getUpcomingRideData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    getUpcomingRideData();
  }, [UpcommingRidedata]);

  return (
    <div className="d-driver-upcomingrides-container">
      {loading ? <LoadingScreen /> : ""}
      <div className="heding-container">
        <div className="schedule-heading">{t("driver.rideSchedule")}</div>
      </div>
      <div>
        {
          // Desktop View Compnent
        }
        <Grid container className="table-container">
          <Table
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={columns}
            Data={mapData(scheduleStateData?.result || [])}
            detailPanel={(rowdata) => <DetailedPanel rowData={rowdata} />}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
            }}
          />
          <PaginationComponent
            count={scheduleStateData?.totalPages}
            totalItems={scheduleStateData?.totalItems}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Grid>

        <Grid container className="table-container-2">
          <DriverTable
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={mobileScreenColumns}
            Data={mapData(scheduleStateData?.result || [])}
            detailPanel={(rowdata) => <DetailedPanel rowData={rowdata} />}
            options={{
              paging: false,
              search: false,
            }}
          />
        </Grid>
      </div>
      <CommonModal
        togal={isModal}
        title={t("driver.stopDetails")}
        // subtitle={
        //   editData?.id
        //     ? t("driver.editDriverProfile")
        //     : t("driver.addNewDriverProfile")
        // }
        toggleHandler={() => openAndCloseModel()}
      >
        {/* <JourneyStarted routeDetails={currentSchedual} /> */}
        {currentSchedual?.map((ele, i) => {
          if (ele?.driverItems?.length > 0) {
            return <JourneyStarted routeDetails={ele} />;
          }
        })}
      </CommonModal>
      <MobileModal
        togal={mobileModal}
        title={t("driver.stopDetails")}
        // subtitle={
        //   editData?.id
        //     ? t("driver.editDriverProfile")
        //     : t("driver.addNewDriverProfile")
        // }
        toggleHandler={() => openAndCloseMobileModel()}
      >
        {currentSchedual?.map((ele, i) => {
          if (ele?.driverItems?.length > 0) {
            return <JourneyStarted routeDetails={ele} />;
          }
        })}
      </MobileModal>
    </div>
  );
};
export default UpcomingRides;
