import { createSlice } from "@reduxjs/toolkit";
import {
  AddRoute,
  DeleteRoute,
  EditRoute,
  FetchRouteData,
} from "../thunks/route";

const initState = {
  loading: false,
  routeData: [],
};

const RouteSlice = createSlice({
  name: "route",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchRouteData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchRouteData.fulfilled, (state, action) => {
        state.loading = false;
        state.routeData = action.payload;
      })
      .addCase(FetchRouteData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddRoute.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeData = action.payload;
      })
      .addCase(AddRoute.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteRoute.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeData = action.payload;
      })
      .addCase(DeleteRoute.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditRoute.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.routeData = action.payload;
      })
      .addCase(EditRoute.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default RouteSlice.reducer;
