import React, { useState } from "react";
import Table from "src/components/Table/index.js";
import { Grid } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { CancelModal } from "src/components/IsCancelModule/index.js";
import _ from "lodash";
import { CommonModal } from "src/components/Modal/index.js";
import {
  EditScheduleOrder,
  deleteScheduleItemById,
  getByIdScheduleItems,
} from "src/thunks/scheduleItem.js";
import EditScheduletemForm from "./EditScheduletemForm.jsx";
import { GetError, weekdays } from "src/constant/index.js";
import { t } from "i18next";
import moment from "moment";

const ScheduleItemList = ({ rowData, DetailSelectedItem, itemName }) => {
  let columns = [
    {
      title: t("common.action"),
      field: "customActions",
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("src/assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />

            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("src/assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    getByIdScheduleItemsData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            {rowData?.isCancel ? (
              <SettingsBackupRestoreOutlinedIcon
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "4px",
                  marginLeft: "4px",
                  color: "green",
                }}
                onClick={() => {
                  openAndCloseCancelModel();
                  setSelectedRowData(rowData);
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "4px",
                  marginLeft: "1px",
                  color: "red",
                }}
                onClick={() => {
                  openAndCloseCancelModel();
                  setSelectedRowData(rowData);
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: t("schedule.day"),
      field: "weekDayNo",
      render: (rowData) => {
        return checkIsCanceled(
          rowData?.isCancel,
          _.find(weekdays, (d) => d.value === rowData?.weekDayNo)?.label || ""
        );
      },
    },
    {
      title: t("schedule.itemName"),
      field: "itemName",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.itemName);
      },
    },

    {
      title: t("schedule.sourceAddress"),
      field: "sourceAdd",
      minWidth: 300,
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.sourceAdd);
      },
    },
    {
      title: t("schedule.sourceDescription"),
      field: "sourceDescription",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.sourceDescription);
      },
    },
    {
      title: t("schedule.destinationAddress"),
      field: "destinationAdd",
      minWidth: 300,
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.destinationAdd);
      },
    },
    {
      title: t("schedule.destinationDescription"),
      field: "destinationDescription",
      render: (rowData) => {
        return checkIsCanceled(
          rowData?.isCancel,
          rowData?.destinationDescription
        );
      },
    },
    {
      title: t("schedule.startTime"),
      field: "startTime",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.startTime);
      },
    },
    {
      title: t("schedule.endTime"),
      field: "endTime",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.endTime);
      },
    },
    {
      title: t("schedule.contactName"),
      field: "contactName",
      render: (rowData) => {
        return checkIsCanceled(
          rowData?.isCancel,
          `${rowData?.contactName} - ${rowData?.contactPhoneNumber}`
        );
      },
    },
    {
      title: t("schedule.transportType"),
      field: "transportType",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.transportType);
      },
    },

    // { title: t("schedule.sourceStop"), field: "sourceStopName" },

    // { title: t("schedule.destinationStop"), field: "destinationStopName" },
  ];

  const dispatch = useDispatch();

  const { getByIdScheduleItemsData } = useSelector(
    (state) => state?.ScheduleItems
  );
  const loading = useSelector((state) => state?.ScheduleItems?.loading);

  const [isModal, setIsModal] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [childRowData, setChildRowDAta] = useState([]);

  useEffect(() => {
    getScheduleItemsByIdData();
  }, []);

  const getScheduleItemsByIdData = async () => {
    try {
      await dispatch(
        getByIdScheduleItems({ id: rowData?.id, itemName: itemName })
      );
    } catch (error) {
      // toast.error(err?.message || "Error In Fetching Order!! Please Check!");
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const updateItemStatus = async () => {
    try {
      const newEditData = {
        id: selectedRowData?.id,
        sourceAddressId: selectedRowData?.sourceAddress?.id,
        sourceStreet1: selectedRowData?.sourceAddress?.street1,
        sourceStreet2: selectedRowData?.sourceAddress?.street2,
        sourceCountry: selectedRowData?.sourceAddress?.country,
        sourceState: selectedRowData?.sourceAddress?.state,
        sourceCityName: selectedRowData?.sourceAddress?.cityName || "",
        sourceZip: selectedRowData?.sourceAddress?.zip,
        sourceDescription: selectedRowData?.sourceDescription,
        destinationAddressId: selectedRowData?.destinationAddress?.id,
        destinationStreet1: selectedRowData?.destinationAddress?.street1,
        destinationStreet2: selectedRowData?.destinationAddress?.street2,
        destinationCountry: selectedRowData?.destinationAddress?.country,
        destinationState: selectedRowData?.destinationAddress?.state,
        destinationCityName: selectedRowData?.destinationAddress?.cityName,
        destinationZip: selectedRowData?.destinationAddress?.zip,
        destinationDescription: selectedRowData?.destinationDescription,
        endTime: selectedRowData?.endTime,
        startTime: selectedRowData?.startTime,
        transportTypeId: selectedRowData?.transportTypeId,
        weekDayNo: selectedRowData?.weekDayNo,
        isCancel: !selectedRowData?.isCancel,
        isActive: true,
        orderId: rowData?.id,
      };
      const res = await dispatch(
        EditScheduleOrder({ ...newEditData })
      ).unwrap();
      if (res) {
        openAndCloseCancelModel();
        if (selectedRowData?.isCancel) {
          toast.success(t("toast.scheduleItemActivated"));
          getScheduleItemsByIdData();
        } else {
          toast.success(t("toast.scheduleItemCanceled"));
          getScheduleItemsByIdData();
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const mapData = (data) => {
    return data.map((scheduleItem) => {
      return {
        ...scheduleItem,
        destinationAdd: `${scheduleItem?.destinationAddress?.street1 || ""}, ${
          scheduleItem?.destinationAddress?.street2 || ""
        },  ${scheduleItem?.destinationAddress?.cityName || ""},${
          scheduleItem?.destinationAddress?.state || ""
        }, ${scheduleItem?.destinationAddress?.country || ""}, ${
          scheduleItem?.destinationAddress?.zip || ""
        }  `,
        sourceAdd: `${scheduleItem?.sourceAddress?.street1 || ""}, ${
          scheduleItem?.sourceAddress?.street2 || ""
        },  ${scheduleItem?.sourceAddress?.cityName || ""},${
          scheduleItem?.sourceAddress?.state || ""
        }, ${scheduleItem?.sourceAddress?.country || ""}, ${
          scheduleItem?.sourceAddress?.zip || ""
        }  `,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const openAndCloseCancelModel = () => {
    setIsCancelModal(!isCancelModal);
  };

  const checkIsCanceled = (isCancel, data) => {
    return isCancel ? <s>{data}</s> : <span>{data}</span>;
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        deleteScheduleItemById({
          id: deleteid,
          listId: rowData?.id,
          orderId: rowData?.orderId,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.scheduleItemDeleted"));
        getScheduleItemsByIdData();
      }
    } catch (error) {
      // toast.error(err?.message || "Error In Fetching Order!! Please Check!");
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getMessage = () => {
    return `${
      selectedRowData?.isCancel
        ? t("common.cancelItemMessage4")
        : t("common.cancelItemMessage1")
    } ${selectedRowData?.itemName} ${t("common.cancelItemMessage2")} ${
      rowData?.scheduleNo
    } ${t("driver.from")} ${moment(rowData?.fromDate).format("DD-MMM-YY")} ${t(
      "driver.to"
    )} ${moment(rowData?.toDate).format("DD-MMM-YY")} ${t(
      "common.cancelItemMessage3"
    )} ${moment(selectedRowData?.scheduleItemDate).format("dddd")}`;
  };

  return (
    <div
      style={{
        marginTop: "5px",
        height: window.innerHeight - 100,
        backgroundColor: "white",
        borderRadius: "14px",
        overflow: "hidden",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: "15px",
        borderTop: "1px solid #E5EEF3",
      }}
    >
      <Grid
        sm={12}
        container
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid
          item
          sx={{
            fontSize: "20px",
            fontFamily: "Albert Sans",
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          {t("schedule.passengerDetails")}
        </Grid>
      </Grid>

      <div>
        <Table
          handleRowSelect={(rows) => {
            document.getElementById("moveItems").style.display =
              rows?.length > 0 ? "block" : "none";
            DetailSelectedItem.current = rows;
          }}
          tableTittle={false}
          columns={columns}
          Data={mapData(getByIdScheduleItemsData?.result || [])}
          options={{
            paging: false,
            search: false,
            toolbar: false,
            selection: true,
          }}
          loading={loading}
        />
        <CommonModal
          togal={isModal}
          title={t("schedule.editScheduleItem")}
          subtitle={t("schedule.editScheduleItem")}
          toggleHandler={() => openAndCloseModel()}
        >
          <EditScheduletemForm
            rowData={rowData}
            toggleHandler={() => openAndCloseModel()}
            editData={editData}
            setEditFun={setEditData}
          />
        </CommonModal>

        <DeleteModal
          togal={isDeleteModal}
          title={"Are You Sure To Delete?"}
          toggleHandler={() => openAndCloseDeleteModel()}
          deleteButtonHandler={deleteButtonHandler}
          loading={loading}
        />
        <CancelModal
          togal={isCancelModal}
          toggleHandler={() => openAndCloseCancelModel()}
          deleteButtonHandler={() => updateItemStatus()}
          message={getMessage()}
          resetState={selectedRowData?.isCancel}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default ScheduleItemList;
