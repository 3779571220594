import { Grid } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

const DetailView = ({ rowData }) => {
  return (
    <div>
      <Grid container xs={12} className="detail-panel-container">
        <Grid item xs={4} className="arival-container">
          <Grid item xs={12} className="arrival-heading">
            {t("driver.endTime")}
          </Grid>
          <Grid item xs={12} className="arrival-content">
            {rowData?.endTime}
          </Grid>
        </Grid>
        <Grid item xs={4} className="vehicle-container">
          <Grid item xs={12} className="vehicle-heading">
            {t("vehicle.vehicle")}
          </Grid>
          <Grid item xs={12} className="vehicle-content">
            {rowData?.typeOfVehicle}
          </Grid>
        </Grid>
        <Grid item xs={4} className="driver-container">
          <Grid item xs={12} className="driver-heading">
            {t("driver.driver")}
          </Grid>
          <Grid item xs={12} className="driver-content">
            {rowData?.driverName}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailView;
