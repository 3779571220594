import React, { useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { t } from "i18next";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScheduleValidationSchema } from "src/helpers/validation";
import { AddSchedule, EditSchedule } from "src/thunks/schedule";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import { InlineLoader } from "src/components/InlineLoader";
import "./ScheduleStyle.scss";
import _ from "lodash";
import { FetchOrderData } from "src/thunks/order";
import { FetchRouteData } from "src/thunks/route";
import { FetchVehicleData } from "src/thunks/vehicle";
import AutoCompleteComponent from "src/components/AutoComplete";

const AddScheduleForm = ({
  toggleHandler,
  editData,
  setEditFun,
  pageSize,
  currentPage,
}) => {
  // ----------------- Redux Global States ---------------------------
  const loading = useSelector((state) => state?.Schedule?.loading);
  const AllOrders = useSelector(
    (state) => state?.Order?.orderData?.result || []
  );
  const AllRoutes = useSelector(
    (state) => state?.Route?.routeData?.result || []
  );
  const AllVehicle = useSelector(
    (state) => state?.Vehicle?.vehicleData?.result || []
  );

  // ----------------- Reat Hooks ------------------------------------
  const dispatch = useDispatch();

  // ----------------- React Hook From --------------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ScheduleValidationSchema),
  });

  // ------------------ Local API Helper Functions --------------------
  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        scheduleNo: data?.ScheduleNo,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
        endTime: data?.endTime,
        startTime: data?.startTime,
        orderId: data?.orderId,
        routeId: data?.routeId,
        vehicleId: data?.vehicleId,
        isActive: true,
        isDelete: false,
        pageSize: pageSize,
        currentPage: currentPage + 1,
        pagging: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
      }
      const res = await dispatch(
        editData?.id
          ? EditSchedule({ ...newData })
          : AddSchedule({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.scheduleEdit") : t("toast.scheduleAdded")
        );
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    if (data && Object.keys(data)?.length > 0)
      reset({
        ScheduleNo: data?.scheduleNo,
        fromDate: data?.fromDate
          ? moment(data?.fromDate || "").format("YYYY-MM-DD")
          : "",
        toDate: data?.toDate
          ? moment(data?.toDate || "").format("YYYY-MM-DD")
          : "",
        endTime: data?.endTime || "",
        startTime: data?.startTime || "",
        orderId: data?.orderId || "",
        routeId: data?.routeId || "",
        vehicleId: data?.vehicleId || "",
      });
  };

  const getDropdownMetaData = async () => {
    try {
      await dispatch(
        FetchOrderData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      await dispatch(
        FetchRouteData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      await dispatch(
        FetchVehicleData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${
      defaultData?.name ||
      defaultData?.city ||
      defaultData?.orderNo ||
      defaultData?.vehicleNo ||
      ``
    }`;
  };

  // ------------------------ Component Life Cycle Methods -------------------
  useEffect(() => {
    setEditData(editData);
    getDropdownMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        style={{ height: "100%" }}
        className="form-container"
      >
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.scheduleName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="ScheduleNo"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("schedule.scheduleName")}
                      variant="outlined"
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.ScheduleNo?.message
                  ? errorMessageChangeInFinish("schedule.scheduleName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.contractName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="orderId"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Contract"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllOrders, "orderNo", "id")}
                    title={t("schedule.contractName")}
                    value={getValue(AllOrders, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.orderId?.message
                  ? errorMessageChangeInFinish("schedule.scheduleName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.fromDate")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="fromDate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("schedule.fromDate")}
                      variant="outlined"
                      size="small"
                      type="date"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.fromDate?.message
                  ? errorMessageChangeInFinish("schedule.fromDate")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.toDate")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="toDate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("schedule.toDate")}
                      variant="outlined"
                      size="small"
                      type="date"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.toDate?.message
                  ? errorMessageChangeInFinish("schedule.toDate")
                  : ""}
              </span>
            </Grid>

            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.startTime")}{" "}
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TimePicker
                      onTimeSelected={onChange}
                      changedValue={value}
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.startTime?.message
                  ? errorMessageChangeInFinish("schedule.startTime")
                  : ""}
              </span>
            </Grid> */}

            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.endTime")}{" "}
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  *
                </span>
              </Typography>
              <Controller
                control={control}
                name="endTime"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TimePicker
                      onTimeSelected={onChange}
                      changedValue={value}
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.endTime?.message
                  ? errorMessageChangeInFinish("schedule.endTime")
                  : ""}
              </span>
            </Grid> */}

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("route.routeName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="routeId"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Route"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllRoutes, "name", "id")}
                    title={t("route.routeName")}
                    value={getValue(AllRoutes, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.routeId?.message
                  ? errorMessageChangeInFinish("route.routeName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("schedule.vehicleNumber")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="vehicleId"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Vehicle"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllVehicle, "vehicleNo", "id")}
                    title={t("schedule.vehicleNumber")}
                    value={getValue(AllVehicle, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.vehicleId?.message
                  ? errorMessageChangeInFinish("schedule.vehicleNumber")
                  : ""}
              </span>
            </Grid>
          </Grid>
        </div>

        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}{" "}
          </Button>
          <Button
            type="submit"
            className="saveButton ms-2"
            sx={{
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
          >
            {t("common.save")}{" "}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddScheduleForm;
