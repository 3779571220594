import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchUserData = createAsyncThunk(
  "FetchUserData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `user?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddUser = createAsyncThunk("AddUser", async (model, thunkApi) => {
  try {
    await getAxios().post("user", model);

    let res = await getAxios().get(
      `user?currentPage=${model?.currentPage || 0}&pageSize=${
        model?.pageSize || 0
      }&paging=${model?.paging || false}`
    );
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const EditUser = createAsyncThunk(
  "EditUser",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`user/${model?.id}`, model);

      let res = await getAxios().get(
        `user?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteUser = createAsyncThunk(
  "DeleteUser",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`user/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `user?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
