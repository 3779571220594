import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { InlineLoader } from "src/components/InlineLoader";
import { DriverValidationSchema } from "src/helpers/validation";
import { CommonCompany } from "src/components/dropdowns/Company/index";
import { AddDriver, EditDriver } from "src/thunks/driver";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import "./DriverStyle.scss";
import LicenceType from "src/components/LicType";
import { FetchCompanyData } from "src/thunks/company";
import _ from "lodash";
import AutoCompleteComponent from "src/components/AutoComplete";

const AddDriverForm = ({
  toggleHandler,
  editData,
  setEditFun,
  currentPage,
  pageSize,
  rowsPerPage,
  page,
}) => {
  // ---------------- React Hooks -------------------------
  const dispatch = useDispatch();

  // ---------------- Global States -------------------------
  const loading = useSelector((state) => state?.Driver?.loading);
  const companyLoading = useSelector((state) => state?.Company?.loading);
  const licTypeLoading = useSelector((state) => state?.common?.loading);
  const userDetail = useSelector((state) => state?.Auth?.userDetail || []);
  const AllCompany = useSelector(
    (state) => state?.Company?.comapnyData?.result || []
  );

  // --------------- Const Licence Data -------------------------------

  const [licData, setLicData] = useState([]);

  // ---------------- React Hook Form ---------------------
  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(DriverValidationSchema),
  });

  console.log("defaultValues", defaultValues);

  // ------------------- API Call Functions----------------

  const onSubmitHandler = async (data) => {
    try {
      if (licData?.length > 0) {
        let state = false;
        licData?.map((ele, index) => {
          if (ele?.expiryDate == "") {
            state = true;
          }
        });
        if (state == true) {
          toast.error(t("common.licError1"));
          return;
        }
      } else {
        toast.error(t("common.licError2"));
        return;
      }
      let newData = {
        licNo: data?.licNo || "",
        companyId: data?.company || userDetail?.company?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: data?.phone,
        currentPage: currentPage,
        pageSize: pageSize,
        paging: true,
        role: "DRIVER",
        profileUrl: "",
        licExpirationDate: data?.licExpirationDate || "",
        isActive: true,
        notes: data?.notes || "",
        licenses: licData || [],
        licenseTypeId: data?.licType,
        rowsPerPage: rowsPerPage,
        page: page,
      };
      if (editData?.id) {
        newData.id = editData?.id;
        newData["userId"] = editData?.userId;
      }

      const res = await dispatch(
        editData?.id ? EditDriver({ ...newData }) : AddDriver({ ...newData })
      ).unwrap();

      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.driverEdited") : t("toast.driverAdded")
        );
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    if (data)
      reset({
        licType: data?.licenseTypeId,
        licNo: data?.licNo,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
        email: data?.user?.email,
        phone: data?.user?.phoneNumber,
        company: data?.user?.company?.id || "",
        notes: data?.notes,
        licExpirationDate: data?.licExpirationDate
          ? moment(data?.licExpirationDate).format("YYYY-MM-DD")
          : "",
      });
  };

  const getCompanyMetaData = async () => {
    try {
      const res = await dispatch(
        FetchCompanyData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      if (res) {
        if (
          userDetail?.roles?.includes("Admin") ||
          userDetail?.roles?.includes("Organizer")
        ) {
          setValue("company", `${userDetail?.companyId}`);
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };
  // ------------------------ Life Cycle Methods ------------------------

  useEffect(() => {
    setEditData({
      ...editData,
    });
    getCompanyMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      {companyLoading ? <InlineLoader /> : ""}
      {licTypeLoading ? <InlineLoader /> : ""}

      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2} className="footer-btn-styling">
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.firstName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.firstName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.firstName?.message
                  ? errorMessageChangeInFinish("common.firstName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.lastName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.lastName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.lastName?.message
                  ? errorMessageChangeInFinish("common.lastName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.email")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={editData?.id ? true : false}
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.email")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.email?.message
                  ? errorMessageChangeInFinish("common.email")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.phoneNumber")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.phoneNumber")}
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.phone?.message
                  ? errorMessageChangeInFinish("common.phone")
                  : ""}
              </span>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.company")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="company"
                render={({ field: { onChange, value } }) => (
                  <CommonCompany onChange={onChange} value={value} />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.company?.message
                  ? errorMessageChangeInFinish("company.company")
                  : ""}
              </span>
            </Grid> */}
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.company")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="company"
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutoCompleteComponent
                      id={"Comapany"}
                      onChange={(e, value) => onChange(value?.value)}
                      options={getOptions(AllCompany, "name", "id")}
                      title={t("company.company")}
                      value={getValue(AllCompany, value, "id")}
                      disabled={
                        userDetail?.roles?.includes("Admin") ||
                        userDetail?.roles?.includes("Organizer")
                          ? true
                          : false
                      }
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.company?.message
                  ? errorMessageChangeInFinish("company.company")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("driver.licenceNumber")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="licNo"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("driver.licenceNumber")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.licNo?.message
                  ? errorMessageChangeInFinish("driver.licenceNumber")
                  : ""}
              </span>
            </Grid>

            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("driver.licenceType")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="licType"
                render={({ field: { onChange, value } }) => (
                  <CommonLookupDropdown
                    title={t("driver.licenceType")}
                    onChange={onChange}
                    value={value}
                    type="License_Type"
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.licType?.message
                  ? errorMessageChangeInFinish("driver.licenceType")
                  : ""}
              </span>
            </Grid> */}
            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("driver.licenceExpDate")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="licExpirationDate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="outlined-basic"
                      placeholder={placeHolderMessage("driver.licenceExpDate")}
                      variant="outlined"
                      size="small"
                      type="date"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.licExpirationDate?.message
                  ? errorMessageChangeInFinish("driver.licenceExpDate")
                  : ""}
              </span>
            </Grid> */}
            <Grid item xs={12}>
              <Typography className="forminputTitle">
                {t("common.note")}
              </Typography>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.note")}
                    variant="outlined"
                    className="custom-textarea"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LicenceType
                licData={(data) => setLicData(data)}
                editData={editData}
              />
            </Grid>
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddDriverForm;
