import React, { useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { toast } from "react-toastify";
import { VehicleValidationSchema } from "src/helpers/validation";
import { AddVehicle, EditVehicle } from "src/thunks/vehicle";
import { CommonLookupDropdown } from "src/components/dropdowns/EngineType";
import { InlineLoader } from "src/components/InlineLoader";
import { CommonCompany } from "src/components/dropdowns/Company";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import "./VehicleStyle.scss";
import { FetchCompanyData } from "src/thunks/company";
import _ from "lodash";
import AutoCompleteComponent from "src/components/AutoComplete";
import { FetchLookupValuesForDropdown } from "src/thunks/common";

const AddVehicleForm = ({
  toggleHandler,
  editData,
  setEditFun,
  currentPage,
  pageSize,
}) => {
  // ------------------ React Hooks ---------------------------
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.Vehicle?.loading);
  const companyLoading = useSelector((state) => state?.Company?.loading);
  const commonLookupLoading = useSelector((state) => state?.common?.loading);
  const userDetail = useSelector((state) => state?.Auth?.userDetail || []);
  const AllCompany = useSelector(
    (state) => state?.Company?.comapnyData?.result || []
  );
  const LookupData = useSelector((state) => state?.common?.LookupValue || []);

  // ------------------ Form Controls --------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(VehicleValidationSchema),
  });

  //------------------- API Call functions ---------------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        companyId: data?.companyId,
        vehicleNo: data?.vehicleNumber,
        noOfSeats: parseInt(data?.noOfSeats),
        handicapSeats: parseInt(data?.handicapSeatNo),
        engineTypeId: parseInt(data?.engineType),
        typeOfVehicleId: parseInt(data?.typeOfVehicle),
        brand: data?.brand,
        model: data?.model,
        firstRegistrationDate: data?.firstRegistrationDate,
        emissionStandards: data?.emissionStandards,
        notes: data?.notes,
        currentPage: currentPage + 1,
        pageSize: pageSize,
        paging: true,
        isActive: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
      }
      const res = await dispatch(
        editData?.id ? EditVehicle({ ...newData }) : AddVehicle({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.vehicleEdited") : t("toast.vehicleAdded")
        );
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    reset({
      companyId: data?.company?.id,
      vehicleNumber: data?.vehicleNo,
      noOfSeats: data?.noOfSeats,
      handicapSeatNo: data?.handicapSeats,
      engineType: data?.engineTypeId,
      typeOfVehicle: data?.typeOfVehicleId,
      brand: data?.brand || "",
      model: data?.model || "",
      firstRegistrationDate: data?.firstRegistrationDate
        ? moment(data?.firstRegistrationDate).format("YYYY-MM-DD")
        : "",
      emissionStandards: data?.emissionStandards || "",
      notes: data?.notes || "",
      isActive: true,
    });
  };
  const getDropdownMetaData = async () => {
    try {
      const res = await dispatch(
        FetchCompanyData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      await dispatch(
        FetchLookupValuesForDropdown({ type: "Type_Of_Vehicle" })
      ).unwrap();

      await dispatch(
        FetchLookupValuesForDropdown({ type: "Engine_Type" })
      ).unwrap();

      if (res) {
        if (
          userDetail?.roles?.includes("Admin") ||
          userDetail?.roles?.includes("Organizer")
        ) {
          setValue("companyId", `${userDetail?.companyId}`);
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  // --------------------- Compnent Life Cycle Methods -----------------------

  useEffect(() => {
    setEditData(editData);
    getDropdownMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {
        // --------------------- Loader ---------------------------
      }

      {loading ? <InlineLoader /> : ""}
      {companyLoading ? <InlineLoader /> : ""}
      {commonLookupLoading ? <InlineLoader /> : ""}

      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.companyName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="companyId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutoCompleteComponent
                      id={"Comapany"}
                      onChange={(e, value) => onChange(value?.value)}
                      options={getOptions(AllCompany, "name", "id")}
                      title={t("company.company")}
                      value={getValue(AllCompany, value, "id")}
                      disabled={
                        userDetail?.roles?.includes("Admin") ||
                        userDetail?.roles?.includes("Organizer")
                          ? true
                          : false
                      }
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.companyId?.message
                  ? errorMessageChangeInFinish("company.companyName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.typeOfVehicle")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="typeOfVehicle"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"VehicleType"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      LookupData?.Type_Of_Vehicle,
                      "name",
                      "id"
                    )}
                    title={t("vehicle.vehicleType")}
                    value={getValue(LookupData?.Type_Of_Vehicle, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.typeOfVehicle?.message
                  ? errorMessageChangeInFinish("vehicle.typeOfVehicle")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.numberOfSeats")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="noOfSeats"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("vehicle.numberOfSeats")}
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.noOfSeats?.message
                  ? errorMessageChangeInFinish("vehicle.numberOfSeats")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.noOfHandicapSeats")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="handicapSeatNo"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage(
                      "vehicle.noOfHandicapSeats"
                    )}
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.handicapSeatNo?.message
                  ? errorMessageChangeInFinish("vehicle.noOfHandicapSeats")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.vehicleNumber")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="vehicleNumber"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("vehicle.vehicleNumber")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.vehicleNumber?.message
                  ? errorMessageChangeInFinish("vehicle.vehicleNumber")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.engineType")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="engineType"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"EngineType"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(LookupData?.Engine_Type, "name", "id")}
                    title={t("vehicle.engineType")}
                    value={getValue(LookupData?.Engine_Type, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.engineType?.message
                  ? errorMessageChangeInFinish("vehicle.engineType")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.brand")}
              </Typography>
              <Controller
                control={control}
                name="brand"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("vehicle.brand")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.model")}
              </Typography>
              <Controller
                control={control}
                name="model"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("vehicle.model")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.emmissionStandard")}{" "}
              </Typography>
              <Controller
                control={control}
                name="emissionStandards"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage(
                      "vehicle.emmissionStandard"
                    )}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("vehicle.firstRegistrationDate")}{" "}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="firstRegistrationDate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Date"
                      variant="outlined"
                      size="small"
                      type="date"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.firstRegistrationDate?.message
                  ? errorMessageChangeInFinish("vehicle.firstRegistrationDate")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={12}>
              <Typography className="forminputTitle">
                {t("common.note")}{" "}
              </Typography>
              <Controller
                control={control}
                name="notes"
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.note")}
                    variant="outlined"
                    className="custom-textarea"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddVehicleForm;
