import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const AddNofications = createAsyncThunk(
  "AddNofications",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(`notification`, model);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
