import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { SchoolUserValidationSchema } from "src/helpers/validation";
import { AddSchoolUser, EditSchoolUser } from "src/thunks/schoolUser";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import { InlineLoader } from "src/components/InlineLoader";
import "./SchoolUserStyle.scss";
import { FetchCompanyData } from "src/thunks/company";
import _ from "lodash";
import AutoCompleteComponent from "src/components/AutoComplete";
import { FetchSchoolData } from "src/thunks/school";
import AllCites from "src/constant/fi.json";

const AddSchoolUserForm = ({
  toggleHandler,
  editData,
  setEditFun,
  pageSize,
  currentPage,
}) => {
  // ---------------- React Hooks -----------------------------

  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.SchoolUser?.loading);
  const companyLoading = useSelector((state) => state?.Company?.loading);
  const schoolLoading = useSelector((state) => state?.School?.loading);
  const userDetail = useSelector((state) => state?.Auth?.userDetail || []);
  const AllCompany = useSelector(
    (state) => state?.Company?.comapnyData?.result || []
  );
  const AllSchools = useSelector(
    (state) => state?.School?.schoolData?.result || []
  );

  // ---------------- React Hook Forms -----------------------

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(SchoolUserValidationSchema),
  });

  // --------------- API Call Functions ----------------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        schoolId: parseInt(data?.school || 0),
        user: {
          firstName: data?.firstName || "",
          lastName: data?.lastName || "",
          email: data?.email || "",
          phoneNumber: data?.phone || "",
          address: {
            street1: data?.street1 || "",
            street2: data?.street2 || "",
            country: data?.country || "",
            state: data?.state || "",
            cityName: data?.cityName || 0,
            zip: data?.zip || "",
            zipAreaCode: data?.zipAreaCode || "",
            plotNumber: "ss",
          },
          role: "SchoolCoordinator",
          profileUrl: "",
          companyId: parseInt(data?.companyId),
          isActive: true,
        },
        currentPage: currentPage + 1,
        pageSize: pageSize,
        paging: true,
      };

      if (editData?.id) {
        newData.id = editData?.id;
        newData["user"]["id"] = editData?.user?.id;
        newData["user"]["addressId"] = editData?.user?.address?.id;
        newData["user"]["address"]["id"] = editData?.user?.address?.id;
      }
      const res = await dispatch(
        editData?.id
          ? EditSchoolUser({ ...newData })
          : AddSchoolUser({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.userEdited") : t("toast.userAdded")
        );
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    if (data)
      reset({
        firstName: data?.user?.firstName,
        school: data?.schoolId || "",
        lastName: data?.user?.lastName,
        email: data?.user?.email,
        phone: data?.user?.phoneNumber,
        companyId: data?.user?.company?.id || "",
        street1: data?.user?.address?.street1,
        street2: data?.user?.address?.street2,
        cityName: data?.user?.address?.cityName,
        state: data?.user?.address?.state,
        country: data?.user?.address?.country,
        zip: data?.user?.address?.zip,
        zipAreaCode: data?.user?.address?.zipAreaCode || "",
      });
  };

  const getDropdownMetaData = async () => {
    try {
      const res = await dispatch(
        FetchCompanyData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      await dispatch(
        FetchSchoolData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      if (res) {
        if (
          userDetail?.roles?.includes("Admin") ||
          userDetail?.roles?.includes("Organizer")
        ) {
          setValue("companyId", `${userDetail?.companyId}`);
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };
  // ------------------- Component Life Cycle Methods ----------------------------

  useEffect(() => {
    setEditData(editData);
    getDropdownMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      {companyLoading ? <InlineLoader /> : ""}
      {schoolLoading ? <InlineLoader /> : ""}

      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.firstName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.firstName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.firstName?.message
                  ? errorMessageChangeInFinish("common.firstName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.lastName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.lastName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.lastName?.message
                  ? errorMessageChangeInFinish("common.lastName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.email")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={editData?.id ? true : false}
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.email")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.email?.message
                  ? errorMessageChangeInFinish("common.email")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.phoneNumber")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.phoneNumber")}
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.phone?.message
                  ? errorMessageChangeInFinish("common.phoneNumber")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.companyName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="companyId"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Comapany"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllCompany, "name", "id")}
                    title={t("company.company")}
                    value={getValue(AllCompany, value, "id")}
                    disabled={
                      userDetail?.roles?.includes("Admin") ||
                      userDetail?.roles?.includes("Organizer")
                        ? true
                        : false
                    }
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.companyId?.message
                  ? errorMessageChangeInFinish("company.companyName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("school.school")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="school"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"School"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(AllSchools, "name", "id")}
                    title={t("school.school")}
                    value={getValue(AllSchools, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.school?.message
                  ? errorMessageChangeInFinish("school.school")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street1")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="street1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street1")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.street1?.message
                  ? errorMessageChangeInFinish("common.street1")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street2")}
              </Typography>
              <Controller
                control={control}
                name="street2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street2")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.cityName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.state")}
              </Typography>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.state")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.country")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.country")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.country?.message
                  ? errorMessageChangeInFinish("common.country")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zip")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="zip"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.zip")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.zip?.message
                  ? errorMessageChangeInFinish("common.zip")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zipAreaDescription")}
              </Typography>
              <Controller
                control={control}
                name="zipAreaCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddSchoolUserForm;
