import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { Circle, CircleRounded, Place } from "@mui/icons-material";
import busImage from "src/assets/images/busIcons.png";
import BusFrontIcons from "src/assets/images/BusFrontIcons.png";
import { useThemeContext } from "src/contexts/theme";
import { ScheduleUpdateModal } from "src/components/ScheduleUpdatesModal";
import Table from "src/components/Table";
import DriverTable from "src/components/DriverTable";
import DetailPanel from "./DetailView";
import "./SchoolAdminStyle.scss";

const SchoolAdminPage = () => {
  // --------------- Table Columns ------------------------------
  const columns = [
    {
      title: t("driver.date"),
      field: "name",
      align: "center",
      render: (rowdata) => moment().format("DD-MM-YYYY"),
    },
    // { title: t("driver.startTime"), field: "startTime", align: "center" },
    // { title: t("driver.endTime"), field: "endTime", align: "center" },
    { title: t("route.route"), field: "routeName", align: "center" },
    { title: t("vehicle.vehicle"), field: "typeOfVehicle", align: "center" },
    { title: t("driver.vehicleNo"), field: "vehicleNo", align: "center" },
    { title: t("driver.driver"), field: "driverName", align: "center" },
  ];

  const mobileScreenColumns = [
    {
      title: "Date",
      field: "name",
      render: (rowdata) => moment().format("DD-MM-YYYY"),
    },
    { title: "Start Time", field: "startTime" },
    { title: "Vehicle No", field: "typeOfVehicle" },
  ];

  // ------------------- Redux Global States ---------------
  const UserData = useSelector((state) => state?.Auth?.userDetail);
  const UpcommingRidedata = useSelector(
    (state) => state?.Schedule?.currentRide?.result
  );

  // ------------------ React Hooks ------------------------
  const themeObj = useThemeContext();
  const navigate = useNavigate();

  // ----------------- Local Component States -------------
  const [isModal, setIsModal] = useState(false);

  // ----------------- Local Helper Functions -------------

  const toggleHandler = () => {
    setIsModal(!isModal);
  };

  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };

  const mapData = (data) => {
    return data.map((o) => {
      return {
        ...o,
      };
    });
  };

  return (
    <div className="dashboard-main-container">
      <Grid
        sm={12}
        container
        sx={{ paddingInline: { sm: 10, xs: 2 } }}
        className={`${getThemedBgColor()}`}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            color: "white",
            paddingBlock: { sm: "60px", xs: "30px" },
          }}
          container
          sm={12}
        >
          <Grid
            container
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <Grid item sm={3.5} xs={12}>
              <Grid className="welcomeMessage">
                <Grid className="message1">{t("common.welcomeBack")}</Grid>
                <Grid className="message2">
                  {UserData?.firstName} {UserData?.lastName}
                </Grid>
                <Grid className="message3">{t("common.welcomeText")}</Grid>
                <Grid item className="upcoming-button">
                  {/* <Button
                    className="upcoming-ride-button"
                    onClick={() => navigate("/app/upcomingRides/list")}
                  >
                    Upcoming Ride
                  </Button>
                  <Typography
                    className="view-updates"
                    onClick={() => toggleHandler()}
                    sx={{ display: { xs: "flex", sm: "none" } }}
                  >
                    View Updates
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            {
              //card
            }
            {/* <Grid
              item
              sm={4.7}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                overflowX: "auto", // Enable horizontal scrolling
                marginTop: { xs: "30px" },
              }}
            >
              {
                //first card
              }
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { sm: "center" },
                  alignItems: "flex-start",
                }}
              >
                <Grid
                  item
                  sm={5.5}
                  xs={12}
                  className="next-ride-container"
                  sx={{ marginRight: { xs: "10px" } }}
                >
                  <Grid item sm={12} xs={12} sx={{ width: { xs: "250px" } }}>
                    <Grid sm={12} item className="next-ride-image-container">
                      <Grid item className="nextRide-image">
                        <img src={busImage} alt="Not found"></img>
                      </Grid>
                      <Grid item className="next-ride-text">
                        Canceled Rides
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} className="next-stops-text-container">
                    <Grid style={{ marginLeft: "20px" }}>
                      <Grid sm={10} item sx={{ display: "flex" }}>
                        <Grid item className="circle-rounded-stop-container">
                          <CircleRounded className="circle-rounded-stop" />
                        </Grid>
                        <Grid item className="start-stop-text-container">
                          <Typography className="start-stop-text">
                            {" "}
                            Rantakylä, 50600 Mikkeli
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid sm={10} item sx={{ display: "flex" }}>
                        <Grid item className="place-stop-container">
                          <Place className="place-rounded-stop" />
                        </Grid>
                        <Grid item className="start-stop-text-container">
                          <Typography className="end-stop-text">
                            {" "}
                            Lähemäen koulu
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {
                  //Second card
                }
                <Grid item sm={5.5} xs={12} className="upcoming-ride-container">
                  <Grid item sm={12} xs={12} sx={{ width: { xs: "300px" } }}>
                    <Grid
                      sm={12}
                      item
                      className="upcoming-ride-image-container"
                    >
                      <Grid item className="upcomingRide-image">
                        <img src={BusFrontIcons} alt="Not found"></img>
                      </Grid>
                      <Grid item className="upcoming-ride-text">
                        Upcoming Ride
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} className="upcoming-text-container">
                    <Grid
                      item
                      sm={3}
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="text-1">02</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={8}
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="text-2">
                        Rides in this shift have not yet begun.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            {
              //updates
            }
            {/* <Grid
              item
              sm={3.3}
              xs={12}
              sx={{ display: { sm: "flex", xs: "none" } }}
            >
              <Grid item xs={4} sx={{ display: { xs: "none", sm: "flex" } }}>
                <Grid
                  container
                  xs={2.5}
                  className="driver-schedule-update"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography className="driver-schedule-update-heading">
                    New Schedules Update
                  </Typography>

                  <Typography
                    className="driver-schedule-update-sub-content"
                    sx={{ height: "100px", overflow: "scroll" }}
                  >
                    The new schedules for the upcoming season will be published
                    on August 4th, 2023. Please check the times and if you
                    notice errors/deficiencies, please send a message to
                  </Typography>

                  <Typography className="driver-schedule-update-sub-email">
                    info@halikonliikken.fi
                  </Typography>
                  <Typography className="driver-schedule-update-sub-read-more">
                    Read More
                  </Typography>
                  <Grid className="driver-schedule-update-sub-user-details">
                    <Grid className="driver-schedule-update-sub-user-details-image"></Grid>
                    <Grid className="driver-schedule-update-sub-user-details-content">
                      <Typography className="driver-schedule-update-sub-user-details-content-heading">
                        Tommi Aumol
                      </Typography>
                      <Typography className="driver-schedule-update-sub-user-details-content-subcontent">
                        Sr. Manager @ Busteri{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          marginInline: { sm: "90px", xs: "10px" },
          marginTop: { sm: "56px", xs: "10px" },
        }}
        className="todays-ride-section"
      >
        <Typography className="todays-ride-heading">
          {t("driver.todaysRides")}
        </Typography>
        <Grid container sx={{ display: { sm: "block", xs: "none" } }}>
          <Table
            // headerTitle={"Company"}
            //  openModelForAdd={openAndCloseModel}
            //  tableTittle={false}
            columns={columns}
            Data={mapData(UpcommingRidedata || [])}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
            }}
          />
        </Grid>

        <Grid container sx={{ display: { sm: "none", xs: "block" } }}>
          <DriverTable
            // handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={mobileScreenColumns}
            Data={mapData(UpcommingRidedata || [])}
            detailPanel={(rowdata) => <DetailPanel rowData={rowdata} />}
            options={{
              paging: false,
              search: false,
              toolbar: false,
              // selection: true,
              // actions: true,
            }}
          />
        </Grid>
      </Grid>

      <ScheduleUpdateModal
        togal={isModal}
        toggleHandler={() => toggleHandler()}
      />
    </div>
  );
};
export default SchoolAdminPage;
