import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const GetReportData = createAsyncThunk(
  "GetReportData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `Report?vehicleId=${model?.vehicleId}&fromDate=${model?.fromDate}&toDate=${model?.toDate}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
