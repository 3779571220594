import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";
import { AppConfigs } from "../settings";
import axios from "axios";

export const FetchRouteData = createAsyncThunk(
  "FetchRouteData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `Route?currentPage=${model?.currentPage}&pageSize=${model?.pageSize}&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddRoute = createAsyncThunk(
  "AddRoute",
  async (model, thunkApi) => {
    try {
      await getAxios().post("Route", model);
      let res = await getAxios().get(
        `Route?currentPage=${model?.currentPage}&pageSize=${model?.pageSize}&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditRoute = createAsyncThunk(
  "EditRoute",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`Route/${model?.id}`, model);
      let res = await getAxios().get(
        `Route?currentPage=${model?.currentPage}&pageSize=${model?.pageSize}&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteRoute = createAsyncThunk(
  "DeleteRoute",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`Route/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `Route?currentPage=${model?.currentPage}&pageSize=${model?.pageSize}&paging=${model?.paging}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadRouteItem = createAsyncThunk(
  "uploadRouteItem",
  async (model, thunkApi) => {
    try {
      let userDetails = localStorage.getItem("token");
      let type = model?.imageType || "default";
      let res = await axios.post(
        `${AppConfigs.apiBaseUrl}common/file/upload?containerName=${type}`,
        model?.formData,
        {
          headers: {
            Authorization: `Bearer ${userDetails}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res?.data || [];
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// const config = {
//   headers: { Authorization: 'Bearer YourAccessTokenHere', },
//   responseType: 'blob',
//   // Ensure the response is treated as binary data};
//   // Make the GET request using
//   Axiosaxios.get(url, config).then((response) => {
//     // Extract the filename from the Content-Disposition header
//     const contentDisposition = response.headers['content-disposition'];
//     const filename = contentDisposition.split(';')[1].trim().split('=')[1];
//     // Create a Blob object with the response data
//     const blob = new Blob([response.data]);
//     // Create a temporary link to trigger the download
//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = filename;
//     // Trigger the click event on the link to initiate the download
//     link.click();
//   }).catch((error) => {
//     console.error('Request failed:', error);
//   });
