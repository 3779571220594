import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { placeHolderMessage } from "src/constant";
import { SearchingValidationSchema } from "src/helpers/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

const SearchingComponent = ({ value, onSubmit, clearFields }) => {
  const [sideState, setSideState] = useState(false);
  // ------------------------ React Hook Form -----------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SearchingValidationSchema),
  });

  const onSubmitHandler = async (data) => {
    try {
      if (data?.fromDate) {
        if (!data?.toDate) {
          toast.error("To Date required");
          return;
        }
      } else if (data?.toDate) {
        if (!data?.fromDate) {
          toast.error("From Date Required");
          return;
        }
      }
      value({ ...data });
      if (data) {
        setSideState(!sideState);
      }
    } catch (error) {
      toast.error("Cant find data");
    }
  };

  useEffect(() => {
    onSubmit();
  }, [sideState]);

  return (
    <Grid container md={12}>
      <Grid item md={12} sx={{}}>
        <form
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingInline: "10px",
          }}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Grid item md={2} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{t("contract.contractName")}</Typography>
            <Controller
              control={control}
              name="contractNo"
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="outlined-basic"
                  placeholder={placeHolderMessage("contract.contractName")}
                  variant="outlined"
                  size="small"
                  type="text"
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item md={2} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{t("driver.itemName")}</Typography>
            <Controller
              control={control}
              name="itemName"
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="outlined-basic"
                  placeholder={placeHolderMessage("driver.itemName")}
                  variant="outlined"
                  size="small"
                  type="text"
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item md={2} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{t("schedule.fromDate")}</Typography>
            <Controller
              control={control}
              name="fromDate"
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="outlined-basic"
                  placeholder={placeHolderMessage("schedule.fromDate")}
                  variant="outlined"
                  size="small"
                  type="date"
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item md={2} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{t("schedule.toDate")}</Typography>
            <Controller
              control={control}
              name="toDate"
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="outlined-basic"
                  placeholder={placeHolderMessage("schedule.toDate")}
                  variant="outlined"
                  size="small"
                  type="date"
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid
            item
            sm={2.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              size="medium"
              type="submit"
              fullWidth
              sx={{
                height: "44px",
                color: "white",
                borderRadius: "22px",
                background: "#000",
                "&:hover": {
                  backgroundColor: "black", // Change to the desired hover color
                },
              }}
            >
              <Search
                style={{
                  height: "44px",
                  width: "20px",
                  color: "white",
                  marginRight: "5px",
                }}
              />
              {t("sidebar.search")}
            </Button>
            <Button
              size="medium"
              color="error"
              variant="outlined"
              sx={{
                height: "44px",
                color: "#000",
                borderRadius: "22px",
                background: "white",
                marginLeft: "5px",
                border: "1px solid #000",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "white",
                  border: "1px solid black",
                },
              }}
              onClick={() => {
                clearFields();
                reset({
                  contractNo: "",
                  itemName: "",
                  fromDate: "",
                  toDate: "",
                });
              }}
              fullWidth
            >
              {" "}
              <HighlightOffIcon
                style={{
                  height: "22px",
                  width: "22px",
                  color: "black",
                  marginRight: "5px",
                }}
              />
              {t("sidebar.clear")}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SearchingComponent;
