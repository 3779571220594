import React from "react";
import "./policiesStyle.scss";
import { Typography } from "@mui/material";

const Policies = () => {
  return (
    <div
      className="policies-container"
      style={{ height: window.innerHeight - 100 }}
    >
      <div className="policies-container-2">
        <div className="policies-container-3">
          <div className="policies-heading-container">
            <Typography className="policies-heading-text">
              Terms and Policies
            </Typography>
          </div>

          <div className="policies-subcontent-container">
            <div className="policies-subcontent-container-2">
              <Typography className="policies-subcontent-headingtext">
                Acceptance of Terms:
              </Typography>
              <Typography className="policies-subcontent-text">
                By downloading, installing, or using the App, you agree to abide
                by these Terms of Service. If you do not agree with any part of
                these terms, you may not use the App.
              </Typography>
            </div>

            <div className="policies-subcontent-container-2">
              <Typography className="policies-subcontent-headingtext">
                Use of the App:
              </Typography>
              <Typography className="policies-subcontent-text">
                The App is designed to facilitate the pickup and drop-off of
                school children by providing real-time tracking and
                communication features. You agree to use the App for its
                intended purpose and not for any unlawful or prohibited
                activities.
              </Typography>
            </div>

            <div className="policies-subcontent-container-2">
              <Typography className="policies-subcontent-headingtext">
                User Registration:
              </Typography>
              <Typography className="policies-subcontent-text">
                To use the App, you may be required to register an account. You
                must provide accurate and complete information during the
                registration process. You are responsible for maintaining the
                confidentiality of your account information and are liable for
                any activity conducted under your account.
              </Typography>
            </div>

            <div className="policies-subcontent-container-2">
              <Typography className="policies-subcontent-headingtext">
                Privacy Policy:
              </Typography>
              <Typography className="policies-subcontent-text">
                Your use of the App is also governed by our Privacy Policy,
                which outlines how we collect, use, and disclose information. By
                using the App, you agree to the terms and conditions of our
                Privacy Policy.
              </Typography>
            </div>

            <div className="policies-subcontent-container-2">
              <Typography className="policies-subcontent-headingtext">
                User Conduct:
              </Typography>
              <Typography className="policies-subcontent-text">
                You agree not to engage in any of the following activities while
                using the App: <br /> a. Posting, sharing, or transmitting any
                content that is unlawful, obscene, or harmful. <br />
                b. Attempting to gain unauthorized access to any part of the App
                or its servers.
                <br />
                c. Disrupting or damaging the operation of the App.
                <br /> d. Engaging in any activity that violates the rights of
                others.
              </Typography>
            </div>

            <div className="policies-subcontent-container-2">
              <Typography className="policies-subcontent-headingtext">
                Fees and Payments:
              </Typography>
              <Typography className="policies-subcontent-text">
                The use of the App may be subject to fees. You agree to pay all
                fees associated with the services you use within the App, as
                described on our website or within the App itself. Payments are
                non-refundable unless otherwise stated.
              </Typography>
            </div>

            <div
              className="policies-subcontent-container-2"
              style={{ borderBottom: "none" }}
            >
              <Typography className="policies-subcontent-headingtext">
                Termination:
              </Typography>
              <Typography className="policies-subcontent-text">
                We reserve the right to terminate or suspend your access to the
                App without notice if you violate these Terms of Service or for
                any other reason.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policies;
