import React, { useMemo, useRef } from "react";
import JoditEditor from "jodit-react";
import { joditPlaceHolderMessage } from "src/constant";

function JoditEditotRichText({ joditEditorValue }) {
  const editor = useRef(null);

  const handleRichTextHtmlContent = (newContent) => {
    joditEditorValue(newContent);
  };

  const configMemot = useMemo(
    () => ({
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      minHeight: 500,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      placeholder: joditPlaceHolderMessage,
    }),
    [joditPlaceHolderMessage]
  );
  return (
    <>
      <JoditEditor
        ref={editor}
        config={configMemot}
        onBlur={(newContent) => {
          handleRichTextHtmlContent(newContent);
        }}
      />
    </>
  );
}

export default JoditEditotRichText;
