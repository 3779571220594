import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import _ from "lodash";
import Table from "src/components/Table/index.js";
import { DeleteCompany, FetchCompanyData } from "src/thunks/company.js";
import { CommonModal } from "src/components/Modal";
import AddCompanyForm from "./AddCompanyForm.jsx";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { GetError } from "src/constant/index.js";
import { ImageView } from "src/components/ImageView/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./CompanyStyle.scss";

const ComapnyList = () => {
  // ---------------- Redux global states ----------------------
  const dispatch = useDispatch();
  const comapnyStateData = useSelector(
    (state) => state?.Company?.comapnyData || []
  );
  const loading = useSelector((state) => state?.Company?.loading);

  // ---------------- Component local states ----------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // ---------------- Table Columns ----------------------
  let columns = [
    {
      title: t("company.logo"),
      field: "logo",
      maxWidth: 90,
      render: (rowdata) => <ImageView value={rowdata?.logo} />,
    },
    { title: t("company.companyName"), field: "name", maxWidth: 100 },
    { title: t("company.address"), field: "address", maxWidth: 250 },
    { title: t("company.city"), field: "city", maxWidth: 100 },
    { title: t("company.website"), field: "webSite", maxWidth: 150 },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(comapnyStateData?.result, (d) => d.id === rowData?.id)
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // ---------------- API Calls ----------------------

  const getAllComapnyData = async (page, rowsPerPage) => {
    try {
      await dispatch(
        FetchCompanyData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteCompany({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.companyDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // -------------------- Local Functions ------------------------------

  const openAndCloseModel = () => {
    setIsModal(!isModal);
    setEditData(null);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const mapData = (data) => {
    return data.map((o) => {
      return {
        ...o,
        address: `${o?.address?.street1 || ""}, ${o?.address?.street2 || ""}, ${
          o?.address?.zip
        }, ${o?.address?.zipAreaCode}, ${o?.address?.cityName}, ${
          o?.address?.state || ""
        }, ${o?.address?.country || ""} `,
        city: o?.address?.cityName,
      };
    });
  };

  // ---------------- Component Life Cycle Methods ----------------------

  useEffect(() => {
    getAllComapnyData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  // ---------------- Return Statements -----------------------------------

  return (
    <div className="allcontainer">
      <Table
        headerTitle={t("company.company")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        loading={loading}
        Data={mapData(comapnyStateData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={comapnyStateData?.totalPages}
        totalItems={comapnyStateData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={
          editData?.id ? t("company.editcompany") : t("company.addnewcompany")
        }
        subtitle={
          editData?.id
            ? t("company.editcompanysubtext")
            : t("company.createnewcompany")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddCompanyForm
          page={page}
          rowsPerPage={rowsPerPage}
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
          currentPage={page}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are you sure you want to delete the user profile?"}
        subtitle={"Yes"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={loading}
      />
    </div>
  );
};
export default ComapnyList;
