import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderItemValidationSchema } from "src/helpers/validation";
import { CommonCity } from "src/components/dropdowns/City";
import { EditItemOrder } from "src/thunks/orderItems";
import { InlineLoader } from "src/components/InlineLoader";
import AllCites from "src/constant/fi.json";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import { CommonSchool } from "src/components/dropdowns/School";
import "../OrderStyle.scss";
import _ from "lodash";
import AutoCompleteComponent from "src/components/AutoComplete";

const EditOrderItemForm = ({ toggleHandler, editData, setEditFun }) => {
  // ------------- Global States -------------------

  //--------------- React Hooks ---------------------
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.OrderItem?.loading);

  // --------------- React Hook Form -----------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(OrderItemValidationSchema),
  });

  // --------------- API Call Functions --------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        id: editData?.id,
        ContactFistName: data?.firstName,
        contactLastName: data?.lastName,
        schoolId: data?.schoolId || "",
        ItemCountry: data?.country,
        ItemState: data?.state,
        ItemStreet1: data?.street1,
        ItemStreet2: data?.street2 || "",
        ItemZip: data?.zipCode,
        Itemcity: data?.cityName,
        isCancel: data?.isCancel || false,
        itemZipAreaCode: data?.zipAreaCode || "",
        ...data,
      };

      const res = await dispatch(EditItemOrder({ ...newData })).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.orderItemEdited") : t("toast.orderItemAdded")
        );
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    if (data)
      reset({
        ...data,
        zipAreaCode: editData?.zipAreaCode || "",
      });
  };
  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  // ----------- Life Cycle Methods ----------------------

  useEffect(() => {
    setEditData(editData);
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("contract.itemName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="itemName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("contract.itemName")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.itemName?.message
                  ? errorMessageChangeInFinish("contract.itemName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("contract.school")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="schoolId"
                render={({ field: { onChange, value } }) => (
                  <CommonSchool onChange={onChange} value={value} />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.schoolId?.message
                  ? errorMessageChangeInFinish("contract.school")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("contract.contractItemFirstName")}
                <span className="asteric">*</span>
              </Typography>

              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage(
                      "contract.contractItemFirstName"
                    )}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.firstName?.message
                  ? errorMessageChangeInFinish("contract.contractItemFirstName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("contract.contractItemLastName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage(
                      "contract.contractItemLastName"
                    )}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.lastName?.message
                  ? errorMessageChangeInFinish("contract.contractItemLastName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.street1")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="street1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street1")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.street1?.message
                  ? errorMessageChangeInFinish("common.street1")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street2")}
              </Typography>
              <Controller
                control={control}
                name="street2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street2")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.cityName")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.state")}
              </Typography>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.state")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.country")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.country")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.country?.message
                  ? errorMessageChangeInFinish("common.country")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zip")} <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="zipCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.zip")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.zipCode?.message
                  ? errorMessageChangeInFinish("common.zip")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zipAreaDescription")}
              </Typography>
              <Controller
                control={control}
                name="zipAreaCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditOrderItemForm;
