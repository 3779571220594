import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "src/thunks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import loginImage from "src/assets/images/loginImage.png";
import logo from "src/assets/images/Group 178038.png";
import { GetError } from "src/constant";
import { loginValidationSchema } from "src/helpers/validation";
import "./LoginStyle.scss";
import { useTranslation } from "react-i18next";
import ColorToggleButton from "src/components/LanguagePicker";
import BasicMenu from "src/components/LanguagePicker";
import NewDatePicker from "src/components/LanguagePicker";
import { LoadingScreen } from "src/components/Loaders/componentLoader";

const Login = () => {
  // ---------------- Global States ---------------------------
  const loading = useSelector((state) => state?.Auth?.loading);

  // ---------------- React Hook Form -------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });

  // ---------------- React Hooks -----------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, I18n] = useTranslation();

  // ---------------- Component States ------------------------
  const [showPassword, setShowPassword] = React.useState(false);

  // ---------------- Local Helper Functions ------------------
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmitHandler = async (data) => {
    try {
      let loginRes = await dispatch(signIn({ ...data })).unwrap();
      if (loginRes.token) {
        if (loginRes?.roles?.includes("Contact")) {
          navigate("/app/parentmaster");
        } else if (loginRes?.roles?.includes("Driver")) {
          navigate("/app/drivermaster");
        } else if (loginRes?.roles?.includes("SchoolCoordinator")) {
          navigate("/app/schoolAdminmaster");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors || error?.message || "Problem In Login"
        )
      );
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // ----------------------- Return Statement ----------------------------

  return (
    <Grid
      sx={{
        marginLeft: "10px",
        height: {
          xs: "100%",
          sm: "100vh",
          backgroundColor: "white",
        },
        overflowY: "hidden",
      }}
      className="login-main-container"
      // container
      // xs={12}
      // sm={12}
    >
      {loading ? <LoadingScreen /> : ""}
      <Grid>
        <Grid container>
          <Grid
            item
            sx={{
              backgroundImage: `url(${loginImage})`,

              height: "100vh",
              color: "white",
              display: { sm: "flex", xs: "none" },
            }}
            xs={5}
          >
            <Grid
              sx={{
                background:
                  "linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.615025) 43.24%, rgba(0, 0, 0, 0) 79.9%)",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: "100px",
              }}
              sm={12}
            >
              <Typography
                style={{
                  fontFamily: "Albert Sans",
                  fontSize: "28.91px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                {" "}
                {t("common.logInMsg1")} <br /> {t("common.logInMsg2")}{" "}
              </Typography>
              <Typography
                sx={{ fontSize: "11px", fontWeight: 300, color: "#FFFFFF" }}
              >
                {" "}
                {t("common.footer")}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{ backgroundColor: "white", height: "100vh" }}
            sm={7}
            xs={12}
          >
            <Grid
              container
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "20px",
                paddingTop: "15px",
              }}
            >
              <NewDatePicker />
            </Grid>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
              }}
              container
              xs={12}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingInline: "16px",
                  }}
                  item
                  sm={6.5}
                  xs={12}
                >
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box>
                      <img src={logo} alt="" className="busteri-logo" />
                    </Box>
                    <Typography className="login-heading">
                      {t("common.logIn")}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                      <Box>
                        <Controller
                          control={control}
                          name="email"
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              fullWidth
                              value={value}
                              onChange={onChange}
                              id="outlined-basic"
                              placeholder={t("common.placeHolderMessage1")}
                              variant="outlined"
                              className="email-textbox"
                              sx={{
                                "& fieldset": { border: "none" },
                              }}
                            />
                          )}
                        />
                        <label>{errors?.email?.message || ""}</label>
                      </Box>
                      <Box>
                        <Controller
                          control={control}
                          name="password"
                          render={({ field: { onChange, value } }) => (
                            <OutlinedInput
                              value={value}
                              onChange={onChange}
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              sx={{
                                "& fieldset": { border: "none" },
                              }}
                              placeholder="**********"
                              style={{ width: "100%" }}
                              className="email-textbox"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          )}
                        />
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "12px",
                          }}
                        >
                          {t("common.passwordValidation")}
                        </div>
                        <label>{errors?.password?.message || ""}</label>
                      </Box>
                      <Box sx={{}} className="checkbox-container">
                        {/* <Box>
                          <Controller
                            control={control}
                            name="isRemember"
                            render={({ field: { onChange, value } }) => (
                              <Checkbox
                                onChange={onChange}
                                value={value}
                                sx={{
                                  color: "#67C6D6",
                                  borderRadius: "3px",
                                  "&.Mui-checked": {
                                    color: "#67C6D6",
                                  },
                                }}
                              />
                            )}
                          />
                          Remember me
                        </Box> */}
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => navigate("/login/password")}
                        >
                          {t("common.forgotPassword")}
                        </Box>
                      </Box>
                      <Box>
                        <Button
                          type="submit"
                          fullWidth
                          sx={{
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                          className="login-button"
                        >
                          {" "}
                          {t("common.login")}
                        </Button>
                      </Box>
                      <Typography className="trademark">
                        {t("common.footer")}
                      </Typography>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
