import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FetchVehicleData } from "src/thunks/vehicle";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import { GetError } from "src/constant";
import { t } from "i18next";

export const CommonVehicles = ({ onChange, value, disable }) => {
  const dispatch = useDispatch();

  const AllVehicle = useSelector(
    (state) => state?.Vehicle?.vehicleData?.result || []
  );

  const [svalue, setsValue] = useState("");

  useEffect(() => {
    let defaultData = _.find(AllVehicle, (d) => d.id == `${value}`);
    setsValue(defaultData?.vehicleNo || "");
  }, [value, AllVehicle]);

  const getAllVehicleRouteData = async () => {
    try {
      await dispatch(
        FetchVehicleData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();
    } catch (error) {
      // toast.error(err?.message || "something issue");
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  useEffect(() => {
    getAllVehicleRouteData();
  }, []);

  return (
    <Autocomplete
      disableClearable
      disabled={disable}
      disablePortal
      onChange={(e, value) => onChange(value?.value || "")}
      value={svalue}
      options={AllVehicle?.map((d) => {
        return {
          label: d?.vehicleNo,
          value: d?.id,
        };
      })}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("common.selectVehicle")}
          size="small"
        />
      )}
    />
  );
};
