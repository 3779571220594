import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchDriverData = createAsyncThunk(
  "fetchDriverData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `driver?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=true`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const AddDriver = createAsyncThunk(
  "AddDriver",
  async (model, thunkApi) => {
    try {
      await getAxios().post("driver", model);
      let res = await getAxios().get(
        `driver?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=true`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const EditDriver = createAsyncThunk(
  "EditDriver",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`driver/${model?.id}`, model);
      let res = await getAxios().get(
        `driver?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=true`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteDriver = createAsyncThunk(
  "DeleteDriver",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`driver/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `driver?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=true`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
