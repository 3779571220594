import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { ImageUploading } from "src/components/ImageUpload";
import _ from "lodash";
import { ContactValidationSchema } from "src/helpers/validation";
import AllCites from "src/constant/fi.json";
import { InlineLoader } from "src/components/InlineLoader";
import { FetchCompanyData } from "src/thunks/company";
import { EditContact } from "src/thunks/contact";
import AutoCompleteComponent from "src/components/AutoComplete";
import "./ContactStyles.scss";

const AddUserForm = ({
  toggleHandler,
  editData,
  setEditFun,
  currentPage,
  pageSize,
}) => {
  // ----------------- Global States ------------------------
  const loading = useSelector((state) => state?.Contact?.loading);
  const companyLoading = useSelector((state) => state?.Company?.loading);
  const AllCompany = useSelector(
    (state) => state?.Company?.comapnyData?.result || []
  );
  // const userDetail = useSelector((state) => state?.Auth?.userDetail || []);

  // ---------------- Hooks ---------------------------------
  const dispatch = useDispatch();

  // ------------------Components Local States --------------

  const [selectedFile, setSelectedFile] = useState("");

  // ------------------ React Hook Form ---------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(ContactValidationSchema),
  });

  // ---------------- Add Edit API Calls --------------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: data?.phone,
        address: {
          street1: data?.street1,
          street2: data?.street2,
          country: data?.country,
          state: data?.state,
          cityName: data?.cityName,
          zip: data?.zip,
          zipAreaCode: data?.zipAreaCode || "",
        },
        paging: true,
        currentPage: currentPage,
        pageSize: pageSize,
        companyId: data?.company || "",
        role: data.role,
        profileUrl: selectedFile?.url || selectedFile || "",
        language: data?.language || "en",
        isActive: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
        newData["address"]["id"] = editData?.address?.id;
        newData["addressId"] = editData?.address?.id;
      }
      const res = await dispatch(EditContact({ ...newData })).unwrap();
      if (res) {
        toast.success(
          editData?.id ? t("toast.contactEdited") : t("toast.userAdded")
        );
        reset({});
        setEditFun({});
        toggleHandler();
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    reset({
      companyId: data?.company,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phoneNumber,
      street1: data?.address?.street1,
      street2: data?.address?.street2 || "",
      country: data?.address?.country,
      state: data?.address?.state || "",
      cityName: data?.address?.cityName,
      zip: data?.address?.zip,
      role: data?.role || "contact",
      company: data?.company?.id,
      language: editData?.language,
      zipAreaCode: data?.address?.zipAreaCode || "",
    });
    setValue("role", "contact");
    setSelectedFile(data?.profileUrl || "");
  };

  const getCompanyMetaData = async () => {
    try {
      const res = await dispatch(
        FetchCompanyData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  // ----------------- Compnent Life Cycle Methods ----------------------------

  useEffect(() => {
    setEditData(editData);
    getCompanyMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      {companyLoading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className="forminputTitle">{t("user.profilePicture")}</span>
              <ImageUploading
                type="User"
                onChange={setSelectedFile}
                value={selectedFile}
                title="Upload Profile picture"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.firstName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={t("common.enter")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.firstName?.message
                  ? errorMessageChangeInFinish("common.firstName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.lastName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={"Enter Last Name"}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.lastName?.message
                  ? errorMessageChangeInFinish("common.lastName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.email")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    disabled={editData?.id ? true : false}
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.email")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.email?.message
                  ? errorMessageChangeInFinish("common.email")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.phone")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.phone")}
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.phone?.message
                  ? errorMessageChangeInFinish("common.phone")
                  : ""}
              </span>
            </Grid>
            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.company")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="company"
                render={({ field: { onChange, value } }) => (
                  <CommonCompany onChange={onChange} value={value} />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.company?.message
                  ? errorMessageChangeInFinish("company.company")
                  : ""}
              </span>
            </Grid> */}

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("company.company")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="company"
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutoCompleteComponent
                      id={"Comapany"}
                      onChange={(e, value) => onChange(value?.value)}
                      options={getOptions(AllCompany, "name", "id")}
                      title={t("company.company")}
                      value={getValue(AllCompany, value, "id")}
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {errors?.company?.message
                  ? errorMessageChangeInFinish("company.company")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street1")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="street1"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street1")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.street1?.message
                  ? errorMessageChangeInFinish("common.street1")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.street2")}
              </Typography>
              <Controller
                control={control}
                name="street2"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.street2")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.cityName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.state")}
              </Typography>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.state")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.country")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.country")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.country?.message
                  ? errorMessageChangeInFinish("common.country")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zip")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="zip"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("common.zip")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.zip?.message
                  ? errorMessageChangeInFinish("common.zip")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.zipAreaDescription")}
              </Typography>
              <Controller
                control={control}
                name="zipAreaCode"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={`${t("common.enter")} ${t(
                      "common.zipAreaDescription"
                    )}`}
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("user.role")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="role"
                defaultValue={editData?.id ? editData.role : ""}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    displayEmpty
                    disabled={editData?.id || ""}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" disabled>
                      {placeHolderMessage(t("user.role"))}
                    </MenuItem>
                    <MenuItem value={"Admin"}>{t("common.admin")}</MenuItem>
                    <MenuItem value={"Organizer"}>
                      {t("common.organizer")}
                    </MenuItem>
                  </Select>
                )}
              />
              <span className="errorMessageTitle">
                {errors?.role?.message
                  ? errorMessageChangeInFinish("user.role")
                  : ""}
              </span>
            </Grid> */}

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("common.Language")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="language"
                defaultValue={editData?.language ? editData?.language : ""}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" disabled>
                      {placeHolderMessage(t("common.Language"))}
                    </MenuItem>
                    <MenuItem value={"EN"}>{t("common.english")}</MenuItem>
                    <MenuItem value={"FN"}>{t("common.finish")}</MenuItem>
                  </Select>
                )}
              />
              <span className="errorMessageTitle">
                {errors?.language?.message
                  ? errorMessageChangeInFinish("common.Language")
                  : ""}
              </span>
            </Grid>
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;
