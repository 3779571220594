import React from "react";
import { Box, Button, Modal } from "@mui/material";
import "./deletemodal.scss";
import deleteIcon from "src/assets/images/ModalDeleteIcon.png";
import { t } from "i18next";
import { InlineLoader } from "../InlineLoader";

export const DeleteModal = ({
  togal,
  toggleHandler,
  title,
  subtitle,
  children,
  deleteButtonHandler,
  loading,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "398px",
    height: "280px",
    bgcolor: "background.paper",
    borderRadius: "18px",
  };

  return (
    <Modal
      open={togal}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {loading == true ? <InlineLoader /> : ""}
        <div style={{}} className="deleteModalMain">
          <div className="deletemainHeaderDelete">
            <div
              style={{
                paddingTop: 26,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBlock: "10px",
                }}
              >
                <img src={deleteIcon} alt="imgNotFound"></img>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="title" style={{ width: "200px" }}>
                  {t("common.deleteMessage")}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "2rem",
                paddingInline: "3rem",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "black",
                  fontSize: "15px",
                  width: "122px",
                  color: "white",
                  borderRadius: "22px",
                  "&:hover": {
                    backgroundColor: "black", // Change to the desired hover color
                  },
                }}
                color="primary"
                onClick={() => deleteButtonHandler()}
                fullWidth
              >
                {t("common.yes")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "black",
                  fontSize: "15px",
                  width: "122px",
                  color: "white",
                  borderRadius: "22px",
                  "&:hover": {
                    backgroundColor: "black", // Change to the desired hover color
                  },
                }}
                onClick={() => toggleHandler()}
                fullWidth
              >
                {t("common.no")}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
