import React, { useState, useEffect } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import Table from "src/components/Table/index.js";
import AddVehicleForm from "./AddVehicleForm.jsx";
import { DeleteVehicle, FetchVehicleData } from "src/thunks/vehicle.js";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { GetError } from "src/constant/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./VehicleStyle.scss";

const VehicleList = () => {
  // ---------------------- Table Columns ----------------------------------
  let column = [
    { title: t("vehicle.vehicleNumber"), field: "vehicleNo" },
    { title: t("company.companyName"), field: "company.name" },
    { title: t("vehicle.noOfSeats"), field: "noOfSeats" },
    { title: t("vehicle.engineType"), field: "engineTypeNavigation.name" },
    { title: t("vehicle.handicapSeat"), field: "handicapSeats" },
    { title: t("vehicle.vehicleType"), field: "typeOfVehicleNavigation.name" },
    { title: t("common.note"), field: "notes" },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    vehicleStateData?.vehicleData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // ------------------- React Golbal States ----------------------------------
  const vehicleStateData = useSelector((state) => state.Vehicle);

  // ------------------- React Hooks ------------------------------------------
  const dispatch = useDispatch();

  // ------------------- Component Local States -------------------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // ------------------ API Call Functions ------------------------------------

  const getAllVehicleData = async () => {
    try {
      let res = await dispatch(
        FetchVehicleData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteVehicle({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.vehicleDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // --------------------- Local Helper Functions ------------------------------------

  const openAndCloseModel = () => {
    setIsModal(!isModal);
    setEditData(null);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const mapData = (data) => {
    return data?.map((columnData) => {
      return {
        ...columnData,
      };
    });
  };
  // ------------------ Compnent Life Cycle Methods -----------------------------
  // useEffect(() => {
  //   getAllVehicleData();
  // }, []);

  useEffect(() => {
    getAllVehicleData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      <Table
        loading={vehicleStateData?.loading}
        headerTitle={t("vehicle.vehicle")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={column}
        Data={mapData(vehicleStateData?.vehicleData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={vehicleStateData?.vehicleData?.totalPages}
        totalItems={vehicleStateData?.vehicleData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={
          editData?.id ? t("vehicle.editVehicle") : t("vehicle.addNewVehicle")
        }
        subtitle={
          editData?.id
            ? t("vehicle.editVehicleProfile")
            : t("vehicle.createNewVehicleProfile")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddVehicleForm
          currentPage={page}
          pageSize={rowsPerPage}
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        title={"Are You Sure To Delete?"}
        subtitle={"Yes"}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={vehicleStateData?.loading}
      />
    </div>
  );
};
export default VehicleList;
