import React from 'react'
import { imageApi } from 'src/settings'
import { Grid } from '@mui/material'
import './style.scss'

export const ImageView = ({ value }) => {
    return <Grid
        className='ImageViewCustom'
        id="imageContainer"
    >
        {value ? (
            <div className="circle">
                <img
                    src={`${imageApi}${value}`}
                    alt="Selected"
                    className="profile-pic"
                />
            </div>
        ) : (
            <div className="circle">
                <img className="profile-pic" src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" />
            </div>
        )}
    </Grid>

}