import React from "react";
import MaterialTable from "material-table";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./DriverTable.scss";

const DriverTable = ({
  columns,
  Data,
  tableTittle,
  action,
  options,
  detailPanel,
  handleRowSelect,
  openModelForAdd,
  headerTitle,
  loading,
}) => {
  const rowStyle = (rowData, index) => {
    if (index % 2 === 1) {
      return { backgroundColor: "#F8F8F8" }; // Apply a background color to even rows
    }
    return {};
  };

  const headerStyle = {
    backgroundColor: "#EEF9FF",
    color: "#404040",
    fontFamily: "Albert Sans",
    fontWeight: 600,
    fontSize: "12px",
    borderBottom: "0px",
    height: "40px",
  };

  return (
    <div className="common-table-main-container">
      {(headerTitle || openModelForAdd) && (
        <div className="header-main-container">
          {headerTitle && <div className="header-heading">{headerTitle}</div>}
          {openModelForAdd && (
            <div style={{ display: "flex" }}>
              <div>
                <Add className="header-add-logo" />
                <Button
                  onClick={() => openModelForAdd()}
                  className="header-add-button"
                  size="large"
                >
                  Add New {headerTitle}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="common-table">
        <MaterialTable
          isLoading={loading}
          title={tableTittle}
          columns={columns}
          data={Data}
          options={{
            ...options,
            rowStyle: { ...rowStyle, fontSize: "12px" },
            headerStyle: headerStyle,
          }}
          actions={action}
          detailPanel={detailPanel}
          onSelectionChange={(data) => handleRowSelect(data)}
        />
      </div>
    </div>
  );
};

export default DriverTable;
