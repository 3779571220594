import { createSlice } from "@reduxjs/toolkit";
import {
  FetchSchoolData,
  AddSchool,
  DeleteSchool,
  EditSchool,
} from "../thunks/school";

const initState = {
  loading: false,
  schoolData: [],
};

const SchoolSlice = createSlice({
  name: "school",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchSchoolData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchSchoolData.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolData = action.payload;
      })
      .addCase(FetchSchoolData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddSchool.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolData = action.payload;
      })
      .addCase(AddSchool.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteSchool.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolData = action.payload;
      })
      .addCase(DeleteSchool.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditSchool.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditSchool.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolData = action.payload;
      })
      .addCase(EditSchool.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default SchoolSlice.reducer;
