import React from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ScheduleUpdateModal.scss";

export const ScheduleUpdateModal = ({
  togal,
  toggleHandler,
  title,
  subtitle,
  children,
  onsubmitHandler,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90% ",
    maxHeight: window.innerHeight - 50,
    bgcolor: "#E4FBFF",
    // overflowY: "scroll",
    borderRadius: "8px",
  };

  return (
    <Modal
      open={togal}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div style={{ height: "100%" }} className="schedule-update-modal">
          <div className="mainHeader">
            <div>
              <div className="title-container">
                <div className="title">New Schedules Update</div>
                <div
                  onClick={() => toggleHandler()}
                  style={{
                    position: "absolute",
                    right: 15,
                    top: -5,
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon fontSize="medium" />
                </div>
              </div>
            </div>
          </div>
          <div className="schedule-modal-body">
            <div className="content">
              <Typography className="driver-schedule-update-sub-content">
                The new schedules for the upcoming season will be published on
                August 4th, 2023. Please check the times and if you notice
                errors/deficiencies, please send a message to The new schedules
                for the upcoming season will be published on August 4th, 2023.
                Please check the times and if you notice errors/deficiencies,
                please send a message to The new schedules for the upcoming
                season will be published on August 4th, 2023. Please check the
                times and if you notice errors/deficiencies, please send a
                message to The new schedules for the upcoming season will be
                published on August 4th, 2023. Please check the times and if you
                notice errors/deficiencies, please send a message to The new
                schedules for the upcoming season will be published on August
                4th, 2023. Please check the times and if you notice
                errors/deficiencies, please send a message to
              </Typography>
            </div>
            <Grid className="driver-schedule-update-sub-user-details">
              <Grid className="driver-schedule-update-sub-user-details-image"></Grid>
              <Grid className="driver-schedule-update-sub-user-details-content">
                <Typography className="driver-schedule-update-sub-user-details-content-heading">
                  Tommi Aumol
                </Typography>
                <Typography className="driver-schedule-update-sub-user-details-content-subcontent">
                  Sr. Manager @ Busteri{" "}
                </Typography>
                <Typography className="driver-schedule-update-sub-email">
                  info@halikonliikken.fi
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
