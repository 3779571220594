import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t } from "i18next";
import { GetError } from "src/constant/index.js";
import { ImageView } from "src/components/ImageView/index.js";
import { FetchContactData, DeleteContact } from "src/thunks/contact.js";
import Table from "src/components/Table/index.js";
import AddUserForm from "./AddContactForm.jsx";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./ContactStyles.scss";
import { Grid } from "@mui/material";
import ContactSearchingComponent from "src/components/ContactSearchingComponent/index.js";

const ContactListPage = () => {
  // --------------- Redux Global Objects ------------------------
  const dispatch = useDispatch();
  const userStateData = useSelector(
    (state) => state?.Contact?.ContactData || []
  );

  const loading = useSelector((state) => state?.Contact?.loading);

  // ---------------- Local Component States ------------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // -------------- Table Column -------------------

  let columns = [
    {
      title: t("user.profile"),
      field: "profileUrl",
      maxWidth: 90,
      render: (rowdata) => <ImageView value={rowdata?.profileUrl} />,
    },
    { title: t("user.fullName"), field: "fullName" },
    { title: t("common.email"), field: "email" },
    { title: t("user.phoneNumber"), field: "phoneNumber" },
    { title: t("user.address"), field: "address" },
    { title: t("user.role"), field: "role" },
    { title: t("company.company"), field: "companyId" },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    userStateData?.result,
                    (userInfo) => userInfo.id == rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // -------------- Api Calls -----------------------------------------

  const getAllUserData = async () => {
    try {
      await dispatch(
        FetchContactData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteContact({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.contactDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const searchingApiCallFunction = async (data) => {
    try {
      const response = await dispatch(
        FetchContactData({
          currentPage: 1,
          pageSize: rowsPerPage,
          paging: true,
          contactName: data?.contactName || "",
          contactEmail: data?.contactEmail || "",
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ---------------------------- Local Functions -----------------------------------------

  const mapData = (data) => {
    return data.map((user) => {
      return {
        ...user,
        address: `${user?.address?.street1 || ""}, ${
          user?.address?.street2 || ""
        }, ${user?.address?.zip}, ${user?.address?.zipAreaCode}, ${
          user?.address?.cityName
        }, ${user?.address?.state || ""}, ${user?.address?.country || ""} `,
        fullName: `${user?.firstName || ""} ${user?.lastName || ""}`,
        profileUrl: `${user?.profileUrl || ""}`,
        email: `${user?.email || ""}`,
        phoneNumber: `${user?.phoneNumber || ""}`,
        companyId: `${user?.company?.name || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };
  //------------------------  Life Cycle Methods --------------------------------------------------

  // useEffect(() => {
  //   getAllUserData();
  // }, []);

  useEffect(() => {
    getAllUserData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      <Table
        loading={loading}
        headerTitle={t("contact.contact")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        isContract={true}
        issearch={
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
              paddingInline: "10px",
              marginTop: "-8px",
            }}
            sm={12}
          >
            {" "}
            <ContactSearchingComponent
              onSubmit={(data) => {
                searchingApiCallFunction(data);
              }}
              clearFields={() => {
                searchingApiCallFunction();
              }}
            />
          </Grid>
        }
        Data={mapData(userStateData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
        hideAddButton={true}
      />
      <PaginationComponent
        count={userStateData?.totalPages}
        totalItems={userStateData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={editData?.id ? t("contact.contact") : t("contact.contact")}
        subtitle={
          editData?.id ? t("contact.editProfile") : t("contact.editProfile")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddUserForm
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
          currentPage={page + 1}
          pageSize={rowsPerPage}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={loading}
      />
    </div>
  );
};
export default ContactListPage;
