import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Table from "src/components/Table/index.js";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import {
  getByIdOrderItems,
  deleteOrderItemById,
  EditItemOrder,
} from "src/thunks/orderItems.js";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { CommonModal } from "src/components/Modal/index.js";
import EditOrderItemForm from "./EditOrderItemForm.jsx";
import { GetError } from "src/constant/index.js";
import "../OrderStyle.scss";
import { t } from "i18next";
import { CancelModal } from "src/components/IsCancelModule/index.js";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";

const OrderItemList = ({ rowdataId, itemName }) => {
  // ------------- Global States -------------------
  const { getByIdOrderItemsData } = useSelector((state) => state?.OrderItem);
  const loading = useSelector((state) => state?.OrderItem?.loading);

  // ------------- Table columns -------------------

  let columns = [
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 200,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("src/assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    getByIdOrderItemsData?.result,
                    (d) => d.id === rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("src/assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData);
              }}
            />
            {rowData?.isCancel ? (
              <SettingsBackupRestoreOutlinedIcon
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "4px",
                  marginLeft: "4px",
                  color: "green",
                }}
                onClick={() => {
                  openAndCloseCancelModel();
                  setSelectedRowData(rowData);
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  width: "22px",
                  height: "22px",
                  marginTop: "4px",
                  marginLeft: "1px",
                  color: "red",
                }}
                onClick={() => {
                  openAndCloseCancelModel();
                  setSelectedRowData(rowData);
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: t("contract.itemName"),
      field: "itemName",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.itemName);
      },
    },
    {
      title: t("contract.schoolName"),
      field: "school",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.school);
      },
    },
    {
      title: t("schedule.contactName"),
      field: "contactName",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.contactName);
      },
    },
    {
      title: t("common.cityName"),
      field: "cityName",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.cityName);
      },
    },
    {
      title: t("common.address"),
      field: "address",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.address);
      },
    },
  ];

  //--------------- React Hooks ---------------------

  const dispatch = useDispatch();

  //--------------- Local States ----------------------
  const [isModal, setIsModal] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isCancelModal, setIsCancelModal] = useState(false);

  // ------------- API Call Functions -----------------

  const getOrderItemsByIdData = async () => {
    try {
      await dispatch(
        getByIdOrderItems({
          id: rowdataId,
          itemName: itemName,
        })
      );
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        deleteOrderItemById({
          id: deleteid?.id,
          listId: rowdataId,
          orderId: deleteid?.orderId,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.orderItemDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const updateItemStatus = async () => {
    try {
      const newEditData = {
        id: selectedRowData?.id,
        itemName: selectedRowData?.itemName,
        contactFistName: selectedRowData?.itemName,
        contactLastName: selectedRowData?.lastName,
        itemStreet1: selectedRowData?.street1,
        itemStreet2: selectedRowData?.street2,
        itemCity: selectedRowData?.cityName,
        itemCountry: selectedRowData?.country,
        itemState: selectedRowData?.state,
        itemZip: selectedRowData?.zipCode,
        schoolId: selectedRowData?.schoolId,
        isCancel: !selectedRowData?.isCancel,
        orderId: rowdataId,
      };
      const res = await dispatch(EditItemOrder({ ...newEditData })).unwrap();
      if (res) {
        openAndCloseCancelModel();
        if (selectedRowData?.isCancel) {
          toast.success(t("common.orderItemActivated"));
        } else {
          toast.success(t("common.orderItemCanceled"));
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ---------------Local Helper Functions ------------------------------------

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };

  const openAndCloseCancelModel = () => {
    setIsCancelModal(!isCancelModal);
  };

  const checkIsCanceled = (isCancel, data) => {
    return isCancel ? <s>{data}</s> : <span>{data}</span>;
  };

  const mapData = (data) => {
    return data.map((orderItem) => {
      return {
        ...orderItem,
        address: `${orderItem?.street1 || ""}, ${orderItem?.street2 || ""}, ${
          orderItem?.zipCode || ""
        }, ${orderItem?.zipAreaCode || ""}, ${orderItem?.cityName || ""}, ${
          orderItem?.state || ""
        }, ${orderItem?.country || ""} `,
        contactName: `${orderItem?.firstName} ${orderItem?.lastName}`,
      };
    });
  };

  // ------------- Life Cycle Methods ----------------
  useEffect(() => {
    getOrderItemsByIdData();
  }, []);

  return (
    <div className="order-item-container">
      <div>
        <Table
          tableTittle={false}
          columns={columns}
          Data={mapData(getByIdOrderItemsData?.result || [])}
          loading={loading}
          options={{
            actionsColumnIndex: -1,
            paging: false,
            search: false,
            toolbar: false,
          }}
        />
        <CommonModal
          togal={isModal}
          title={t("contract.editContractItem")}
          subtitle={t("contract.editContractItemDetails")}
          toggleHandler={() => openAndCloseModel()}
        >
          <EditOrderItemForm
            toggleHandler={() => openAndCloseModel()}
            editData={editData}
            setEditFun={setEditData}
          />
        </CommonModal>

        <DeleteModal
          togal={isDeleteModal}
          title={"Are You Sure To Delete?"}
          toggleHandler={() => openAndCloseDeleteModel()}
          deleteButtonHandler={deleteButtonHandler}
          loading={loading}
        />
        <CancelModal
          togal={isCancelModal}
          toggleHandler={() => openAndCloseCancelModel()}
          deleteButtonHandler={() => updateItemStatus()}
          message={
            selectedRowData?.isCancel
              ? t("common.contactItemActivate")
              : t("common.contactItemCancel")
          }
          resetState={selectedRowData?.isCancel}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default OrderItemList;
