import { createSlice } from "@reduxjs/toolkit";
import { GetReportData } from "../thunks/report";

const initState = {
  loading: false,
  reportData: [],
};

const VehicleSlice = createSlice({
  name: "Report",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetReportData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetReportData.fulfilled, (state, action) => {
        state.loading = false;
        state.reportData = action.payload;
      })
      .addCase(GetReportData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default VehicleSlice.reducer;
