import { createSlice } from "@reduxjs/toolkit";
import {
  FetchDriverData,
  AddDriver,
  DeleteDriver,
  EditDriver,
} from "../thunks/driver";

const initState = {
  loading: false,
  DriverData: [],
};

const DriverSlice = createSlice({
  name: "Driver",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchDriverData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchDriverData.fulfilled, (state, action) => {
        state.loading = false;
        state.DriverData = action.payload;
      })
      .addCase(FetchDriverData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddDriver.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.DriverData = action.payload;
      })
      .addCase(AddDriver.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteDriver.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.DriverData = action.payload;
      })
      .addCase(DeleteDriver.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditDriver.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.DriverData = action.payload;
      })
      .addCase(EditDriver.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default DriverSlice.reducer;
