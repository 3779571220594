import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

// ---------------- Sign In  -----------------------------

export const signIn = createAsyncThunk("signIn", async (model, thunkApi) => {
  try {
    let res = await getAxios().post("auth", model);

    localStorage.setItem("token", res?.data?.token);
    localStorage.setItem("id", res?.data?.id);
    if (model?.isRemember) {
      localStorage.setItem("RememberMe", JSON.stringify(model));
    }

    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// ---------------- Get User Details  -----------------------------

export const getUserById = createAsyncThunk(
  "getUserById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`user/${model}`);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DriverShiftLog = createAsyncThunk(
  "DriverShiftLog",
  async (model, thunkApi) => {
    try {
      // let res = await getAxios().get(`DriverShiftLog/${model?.id}`);
      let res = await getAxios().get(`/Driver/${model?.id}/shift`);

      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DriverShiftStartAndEnd = createAsyncThunk(
  "DriverShiftStartAndEnd",
  async (model, thunkApi) => {
    try {
      // await getAxios().post(`DriverShiftLog`, {
      //   driverId: model?.driverId,
      // });
      // let url = `Driver/${model?.driverId}/GetSchedule`;
      await getAxios().post(`Driver/${model?.driverId}/shift`, {
        driverId: model?.driverId,
      });

      // let shiftData = await getAxios().get(`DriverShiftLog/${model?.driverId}`);

      // const shiftState = shiftData?.data?.driverId == 0 ? false : true;

      let url = `Driver/${model?.driverId}/schedule?isTodaysSchedule=${
        model?.isTodaysSchedule || false
      }&driverId=${model?.driverId}&scheduleId=${
        model?.scheduleId || 0
      }&isUpcommingSchedule=${model?.isUpcommingSchedule || false}`;
      let shiftData = await getAxios().get(`/Driver/${model?.driverId}/shift`);

      const shiftState = shiftData?.data?.driverId == 0 ? false : true;
      let res = await getAxios().get(url);
      return { ...res.data, shiftState };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const sendEmailOtp = createAsyncThunk(
  "auth/sendEmailOtp",
  async (model, thunkAPi) => {
    try {
      let res = await getAxios().get(`/Auth/sendotp?email=${model?.email}`);
      return res.data;
    } catch (error) {
      return thunkAPi.rejectWithValue(error);
    }
  }
);

export const VerifyOtp = createAsyncThunk(
  "auth/VerifyOtp ",
  async (model, thunkAPi) => {
    try {
      let res = await getAxios().post(`/Auth/confirmotp`, model);
      return res.data;
    } catch (error) {
      return thunkAPi.rejectWithValue(error);
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "auth/VerifyOtp ",
  async (model, thunkAPi) => {
    try {
      await getAxios().post(`/Auth/forgotpassword`, model);
      return true;
    } catch (error) {
      return thunkAPi.rejectWithValue(error);
    }
  }
);
