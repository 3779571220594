import { createSlice } from "@reduxjs/toolkit";
import {
  getByIdOrderItems,
  deleteOrderItemById,
  EditItemOrder,
  getAllOrderItem,
} from "../thunks/orderItems";

const initState = {
  loading: false,
  getByIdOrderItemsData: [],
};

const OrderItemSlice = createSlice({
  name: "orderItem",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get by id order items data
      .addCase(getByIdOrderItems.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getByIdOrderItems.fulfilled, (state, action) => {
        state.loading = false;
        state.getByIdOrderItemsData = action.payload;
      })
      .addCase(getByIdOrderItems.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteOrderItemById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteOrderItemById.fulfilled, (state, action) => {
        state.loading = false;
        state.getByIdOrderItemsData = action.payload;
      })
      .addCase(deleteOrderItemById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditItemOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditItemOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.getByIdOrderItemsData = action.payload;
      })
      .addCase(EditItemOrder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllOrderItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrderItem.fulfilled, (state, action) => {
        state.loading = false;
        // state.getByIdOrderItemsData = action.payload;
      })
      .addCase(getAllOrderItem.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default OrderItemSlice.reducer;
