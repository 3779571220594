import React from "react";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./modal.scss";

export const MobileModal = ({
  togal,
  toggleHandler,
  title,
  subtitle,
  children,
  onsubmitHandler,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95% ",
    height: "95vh",
    bgcolor: "background.paper",
    overflowY: "scroll",
    borderRadius: "10px",
  };

  return (
    <Modal
      open={togal}
      onClose={toggleHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div style={{ height: "100%" }} className="addAndUpdateModule">
          <div
            className="mainHeader"
            style={{ borderBottom: "2px solid #E7E7E7" }}
          >
            <div style={{ paddingLeft: 28, paddingTop: 26 }}>
              {title && (
                <div style={{ position: "relative" }}>
                  <div className="title">{title}</div>
                  <div
                    onClick={() => toggleHandler()}
                    style={{
                      position: "absolute",
                      right: 15,
                      top: -15,
                      cursor: "pointer",
                    }}
                  >
                    <CloseIcon fontSize="medium" />
                  </div>
                </div>
              )}
              {subtitle && <div className="subtitle">{subtitle}</div>}
            </div>
          </div>
          <div className="customModalBody">{children}</div>
        </div>
      </Box>
    </Modal>
  );
};
