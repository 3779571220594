import * as yup from "yup";

// -------------------------- Login Valiation Schema -------------------------------------

export const sendEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
});

export const forGotPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must be at least 8 characters"
    ),
  confirmPassword: yup
    .string()
    .required("Password Is Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must be at least 8 characters"
    ),
  isRemember: yup.string(),
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .required("Password Is Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must be at least 8 characters"
    ),
  isRemember: yup.string(),
});

// ---------------------------------------------------------------------------------------

// -------------------------- Comapny Validation Schema ---------------------------------

export const CompanyValidationSchema = yup.object().shape({
  companyName: yup.string().required("Company Name Is Required"),
  street1: yup.string().required("Street1 Is Required"),
  street2: yup.string(),
  cityName: yup.string().required("City Name Is Required"),
  zip: yup.string().required("Zip Is Required"),
  zipAreaCode: yup.string(),
  State: yup.string(),
  country: yup.string().required("Country Is Required"),
  webSite: yup.string(),
});

// ---------------------------------------------------------------------------------------

// -------------------------- Driver Validation Schema ---------------------------------

export const DriverValidationSchema = yup.object().shape({
  licNo: yup.string().required("Licence number Is Required"),
  // licType: yup.string().required("Licence Type Is Required"),
  firstName: yup.string().required("First Name Is Required"),
  lastName: yup.string().required("Last Name Is Required"),
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
  phone: yup.string().required("Phone Is Required"),
  company: yup.string().required("Company Is Required"),
  // licExpirationDate: yup
  //   .string()
  //   .required("Licence Expiration Date Is Required"),
  notes: yup.string(),
});

// ---------------------------------------------------------------------------------------

// -------------------------- Vehicle Validation Schema ---------------------------------

export const VehicleValidationSchema = yup.object().shape({
  companyId: yup.string().required("Comapny Id Is Required"),
  noOfSeats: yup.string().required("No Of Seats Is Required"),
  vehicleNumber: yup.string().required("Vehicle Number Is Required"),
  engineType: yup.string().required("Engine Type Is Required"),
  handicapSeatNo: yup.string().required("Handicap Seats Is Required"),
  typeOfVehicle: yup.string().required("Vehicle Type Is Required"),
  brand: yup.string(),
  model: yup.string(),
  firstRegistrationDate: yup
    .string()
    .required("First Registration Date Is Required"),
  emissionStandards: yup.string(),
  notes: yup.string(),
});

// ---------------------------------------------------------------------------------------

// -------------------------- School Validation Schema ---------------------------------

export const SchoolValidationSchema = yup.object().shape({
  schoolName: yup.string().required("School Name Is Required"),
  street1: yup.string().required("Street 1 Is Required"),
  street2: yup.string(),
  country: yup.string().required("Country Is Required"),
  cityName: yup.string().required("City Name Is Required"),
  state: yup.string(),
  zip: yup.string().required("Zip Type Is Required"),
  zipAreaCode: yup.string(),
});

// ---------------------------------------------------------------------------------------

// -------------------------- School User Validation Schema ---------------------------------

export const SchoolUserValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name Is Required"),
  companyId: yup.string().required("Comapny Id Is Required"),
  lastName: yup.string().required("Last Name Is Required"),
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
  phone: yup.string().required("Phone Is Required"),
  school: yup.string().required("School Is Required"),
  street1: yup.string().required("Street1 Is Required"),
  street2: yup.string(),
  cityName: yup.string().required("City Name Is Required"),
  state: yup.string(),
  country: yup.string().required("Country Is Required"),
  zip: yup.string().required("Zip  Is Required"),
  zipAreaCode: yup.string(),
});

// ---------------------------------------------------------------------------------------

// -------------------------- User Validation Schema ---------------------------------

export const UserValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name Is Required"),
  lastName: yup.string().required("Last Name Is Required"),
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
  phone: yup.string().required("Phone Is Required"),
  company: yup.string().required("Company Is Required"),
  street1: yup.string().required("Street1 Is Required"),
  street2: yup.string(),
  cityName: yup.string().required("City Name Is Required"),
  state: yup.string(),
  country: yup.string().required("Country Is Required"),
  zip: yup.string().required("Zip  Is Required"),
  zipAreaCode: yup.string(),
  role: yup.string().required("Role  Is Required"),
  language: yup.string().required("Language Is Required"),
});

// ---------------------------------------------------------------------------------------

// -------------------------- Contact Validation Schema ---------------------------------

export const ContactValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name Is Required"),
  lastName: yup.string().required("Last Name Is Required"),
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
  phone: yup.string().required("Phone Is Required"),
  company: yup.string().required("Company Is Required"),
  street1: yup.string().required("Street1 Is Required"),
  street2: yup.string(),
  cityName: yup.string().required("City Name Is Required"),
  state: yup.string(),
  country: yup.string().required("Country Is Required"),
  zip: yup.string().required("Zip  Is Required"),
  zipAreaCode: yup.string(),
  role: yup.string().required("Role  Is Required"),
  language: yup.string().required("Language Is Required"),
});

// ---------------------------------------------------------------------------------------

// -------------------------- Route Validation Schema ---------------------------------

export const RouteValidationSchema = yup.object().shape({
  routeName: yup.string().required("Route Name Is Required"),
  companyId: yup.string().required("Company Is Required"),
  driverID: yup.string().required("DriverID Is Required"),
  cityName: yup.string().required("City Name Is Required"),
});

// -------------------------- Stop Validation Schema ---------------------------------

export const StopValidationSchema = yup.object().shape({});

// -------------------------- Order Validation Schema ---------------------------------

export const OrderValidationSchema = yup.object().shape({
  orderNumber: yup.string().required("Order Number Is Required"),
  companyId: yup.string().required("Company Is Required"),
  cityName: yup.string().required("City Name Is Required"),
  startContrct: yup.string().required("Start Date Is Required"),
  endContract: yup.string().required("End Date Is Required"),
  categoryId: yup.string().required("Category Name Is Required"),
  areaDescription: yup.string().required("Area Description Is Required"),
});

// -------------------------- Order Items Validation Schema ---------------------------------

export const OrderItemValidationSchema = yup.object().shape({
  itemName: yup.string().required("Item Name Is Required"),
  schoolId: yup.string().required("School Name Is Required"),
  firstName: yup.string().required("first Name No Is Required"),
  lastName: yup.string().required("last Name No Is Required"),
  street1: yup.string().required("Street1 Is Required"),
  street2: yup.string(),
  cityName: yup.string().required("City Name Is Required"),
  state: yup.string(),
  country: yup.string().required("Country Name Is Required"),
  zipCode: yup.string().required("Zip code Is Required"),
  zipAreaCode: yup.string(),
});

export const SchedualItemValidationSchema = yup.object().shape({
  itemName: yup.string().required("Item Name Is Required"),
  weekDayNo: yup.string().required("Week Day No Is Required"),
  destinationStreet1: yup.string().required("Street1 Is Required"),
  destinationStreet2: yup.string(),
  destinationCityName: yup.string().required("City Name Is Required"),
  destinationState: yup.string(),
  destinationCountry: yup.string().required("Country Name Is Required"),
  destinationZipCode: yup.string().required("Zip code Is Required"),
  destinationZipArea: yup.string(),
  destinationDescription: yup.string().required("Description Is Required"),
  // destinationStop: yup.string().required("Stop Is Required"),

  sourceStreet1: yup.string().required("Street1 Is Required"),
  sourceStreet2: yup.string(),
  sourceCityName: yup.string().required("City Name Is Required"),
  sourceState: yup.string(),
  sourceCountry: yup.string().required("Country Name Is Required"),
  sourceZipCode: yup.string().required("Zip code Is Required"),
  sourceZipArea: yup.string(),
  sourceDescription: yup.string().required("Description Is Required"),
  // sourceStop: yup.string().required("Stop Is Required"),
  startTime: yup.string().required("Arrival time Is Required"),
  endTime: yup.string().required("Departure time Is Required"),
  transportType: yup.string().required("Departure time Is Required"),
});

// -------------------------- Schedule Validation Schema ---------------------------------

export const ScheduleValidationSchema = yup.object().shape({
  ScheduleNo: yup.string().required("Schedule Number Is Required"),
  fromDate: yup.string().required("From Date Is Required"),
  toDate: yup.string().required("To Date Is Required"),
  // startTime: yup.string().required("Start Time Is Required"),
  // endTime: yup.string().required("End Time Is Required"),
  orderId: yup.string().required("Order Id Is Required"),
  routeId: yup.string().required("Route Id Is Required"),
  vehicleId: yup.string().required("Vehicle Id Is Required"),
});

// -------------------------- Profile User Validation Schema ---------------------------------

export const ProfileUserValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name Is Required"),
  lastName: yup.string().required("Last Name Is Required"),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email"
    ),
  phone: yup.string().required("Phone Is Required"),
  company: yup.string(),
  street1: yup.string().required("Street1 Is Required"),
  street2: yup.string(),
  cityName: yup.string().required("City Name Is Required"),
  state: yup.string(),
  country: yup.string().required("Country Logo Is Required"),
  zip: yup.string().required("Zip  Is Required"),
  zipAreaCode: yup.string(),
  language: yup.string().required("Language Is Required"),
});

// -------------------------- Report Validation Schema ---------------------------------

export const ReportValidationSchema = yup.object().shape({
  vehicleId: yup.string().required("First Name Is Required"),
  fromDate: yup.string().required("From Date Is Required"),
  toDate: yup.string().required("To Date Is Required"),
});

// -------------------------- Searching Validation Schema ---------------------------------

export const SearchingValidationSchema = yup.object().shape({
  contractNo: yup.string(),
  itemName: yup.string(),
  fromDate: yup.string(),
  toDate: yup.string(),
  contactEmail: yup.string(),
  contactName: yup.string(),
});

// -------------------------- Ride Validation Schema ---------------------------------

export const RideValidation = yup.object().shape({
  driverId: yup.string().required("First Name Is Required"),
  fromDate: yup.string().required("From Date Is Required"),
  toDate: yup.string().required("To Date Is Required"),
});
