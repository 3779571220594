import { createSlice } from "@reduxjs/toolkit";
import { AddCompany, EditCompany, FetchCompanyData, DeleteCompany } from "../thunks/company";

const initState = {
    loading: false,
    comapnyData: [],
};

const ComapnySlice = createSlice({
    name: "Comapny",
    initialState: initState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder
                .addCase(FetchCompanyData.pending, (state, action) => {
                    state.loading = true;
                })
                .addCase(FetchCompanyData.fulfilled, (state, action) => {
                    state.loading = false;
                    state.comapnyData = action.payload;
                })
                .addCase(FetchCompanyData.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(AddCompany.pending, (state, action) => {
                    state.loading = true;
                })
                .addCase(AddCompany.fulfilled, (state, action) => {
                    state.loading = false;
                    state.comapnyData = action.payload;
                })
                .addCase(AddCompany.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(EditCompany.pending, (state, action) => {
                    state.loading = true;
                })
                .addCase(EditCompany.fulfilled, (state, action) => {
                    state.loading = false;
                    state.comapnyData = action.payload;
                })
                .addCase(EditCompany.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(DeleteCompany.pending, (state, action) => {
                    state.loading = true;
                })
                .addCase(DeleteCompany.fulfilled, (state, action) => {
                    state.loading = false;
                    state.comapnyData = action.payload;
                })
                .addCase(DeleteCompany.rejected, (state, action) => {
                    state.loading = false;
                })
        },
});

export default ComapnySlice.reducer;
