import React, { useEffect, useState } from "react";
import { t } from "i18next";
import moment from "moment/moment.js";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import _, { get } from "lodash";
import { toast } from "react-toastify";
import { GetScheduleByDriverId, cancelRideStop } from "src/thunks/schedule.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import DriverTable from "src/components/DriverTable/index.js";
import Table from "src/components/Table/index.js";
import DetailedPanel from "./DetailView/index.js";
import "./ParentMasterStyle.scss";
import { GetError } from "src/constant/index.js";
import { CancelModal } from "src/components/IsCancelModule/index.js";

const ParentList = () => {
  // ---------------- Table Columns
  let columns = [
    {
      title: t("common.dayAndDate"),
      field: "dayWithDate",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.dayWithDate);
      },
    },
    {
      title: t("driver.itemName"),
      field: "itemName",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.itemName);
      },
    },
    {
      title: t("driver.sourceAddress"),
      field: "sourceAddress",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.sourceAddress);
      },
    },
    {
      title: t("driver.startTime"),
      field: "startTime",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.startTime);
      },
    },
    {
      title: t("schedule.destinationAddress"),
      field: "destinationAddress",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.destinationAddress);
      },
    },
    {
      title: t("schedule.endTime"),
      field: "endTime",
      render: (rowdata) => {
        return checkIsCanceled(rowdata?.isCancel, rowdata?.endTime);
      },
    },

    {
      title: t("driver.status"),
      field: "driverName",
      render: (rowData) => {
        if (!rowData?.isCancel) {
          return (
            <div>
              <Button
                className="cancel-ride-button"
                onClick={() => {
                  openAndCloseCancelModel();
                  setSelectedId(rowData?.id);
                  setRowData(rowData);
                }}
              >
                {t("driver.cancelRide")}
              </Button>
            </div>
          );
        } else {
          return <div>{t("driver.canceled")}</div>;
        }
        // return checkIsCanceled(rowdata?.isCancel, rowData?.driverName);
      },
    },
  ];

  let mobileScreenColumns = [
    {
      title: t("driver.date"),
      field: "date",
      render: (rowData) => (
        <div>
          <div>{moment(rowData?.fromDate).format("DD-MM-YYYY")}</div>
          <div>{moment(rowData?.toDate).format("DD-MM-YYYY")}</div>
        </div>
      ),
    },

    {
      title: t("driver.startTime"),
      field: "startTime",
      render: (rowData) => rowData?.startTime,
    },
    {
      title: t("driver.endTime"),
      field: "endTime",
      render: (rowData) => rowData?.endTime,
    },

    {
      title: t("driver.vehicle"),
      field: "vehicleNo",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.vehicleNo);
      },
    },
  ];

  // ----------------- Redux Global States --------------------------
  const scheduleStateData = useSelector(
    (state) => state?.Schedule?.driverScheduleData || []
  );
  let userDetail = useSelector((state) => state?.Auth?.userDetail);

  // ----------------- React Hooks ----------------------------------
  const dispatch = useDispatch();
  const selectedItem = React.useRef([]);

  // ----------------- Component Local Methods ----------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [selectedRowData, setSelectedId] = useState(null);
  const [rowData, setRowData] = useState({});

  // ----------------- API Call Functions ---------------------------
  const getAllScheduleData = async () => {
    try {
      await dispatch(
        GetScheduleByDriverId({
          contactId: userDetail?.transactionId,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
          isTodaysSchedule: false,
          isUpcommingSchedule: true,
        })
      ).unwrap();
    } catch (err) {
      toast.error(err?.message || t("toast.erroMessage"));
    }
  };

  // --------------- Local Helper Functions ----------------------------

  const checkIsCanceled = (isCancel, data) => {
    return isCancel ? <s>{data}</s> : <span>{data}</span>;
  };

  const mapData = (data) => {
    return data?.map((o) => {
      let iscancel = _.filter(o?.scheduleItems, (d) => d.isCancel);
      return {
        ...o,
        iscancel: iscancel.length === o?.scheduleItems?.length ? true : false,
        address: `${
          o?.user?.address?.street1 || o?.user?.address?.street2 || ""
        } ${o?.user?.address?.cityName || ""} ${
          o?.user?.address?.state || ""
        } ${o?.user?.address?.country || ""} ${o?.user?.address?.zip || ""}`,
        fullName: `${o?.user?.firstName || ""} ${o?.user?.middleName || ""} ${
          o?.user?.lastName || ""
        }`,
        profileUrl: `${o?.user?.profileUrl || ""}`,
        email: `${o?.user?.email || ""}`,
        phoneNumber: `${o?.user?.phoneNumber || ""}`,
      };
    });
  };

  // -------------- New Create Data Functions -----------------------------------

  function getDateForDay(startDate, targetDayNumber) {
    // const daysOfWeek = [
    //   "Sunday",
    //   "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday",
    // ];
    // const startDateObj = new Date(startDate);
    // const startDayNumber = startDateObj.getDay();
    // const daysToAdd = (targetDayNumber - startDayNumber + 7) % 7;
    // const targetDate = new Date(startDateObj);
    // targetDate.setDate(startDateObj.getDate() + daysToAdd);
    // const result = `${
    //   daysOfWeek[targetDayNumber]
    // } - ${targetDate.getFullYear()}-${(targetDate.getMonth() + 1)
    //   .toString()
    //   .padStart(2, "0")}-${targetDate.getDate().toString().padStart(2, "0")} `;
    // return result;
  }

  const manipulateDataForTable = (requireData) => {
    const newData = [];
    const data = requireData?.map((item, index) => {
      return item?.scheduleItems?.map((scheduleItems, index) => {
        newData.push({
          ...scheduleItems,
          dayWithDate: `${moment(
            scheduleItems?.scheduleItemDate,
            "YYYY-MM-DD"
          ).format("dddd")} - ${
            scheduleItems?.scheduleItemDate?.split("T")[0] || ""
          }`,
          itemName: scheduleItems?.itemName,
          sourceAddress: `${scheduleItems?.sourceAddress?.street1}, ${scheduleItems?.sourceAddress?.street2},${scheduleItems?.sourceAddress?.cityName}, ${scheduleItems?.sourceAddress?.zip}, ${scheduleItems?.sourceAddress?.country}`,
          startTime: scheduleItems?.startTime,
          destinationAddress: `${scheduleItems?.destinationAddress?.street1}, ${scheduleItems?.destinationAddress?.street2},${scheduleItems?.destinationAddress?.cityName}, ${scheduleItems?.destinationAddress?.zip}, ${scheduleItems?.destinationAddress?.country}`,
          endTime: scheduleItems?.endTime,
          isCancel: scheduleItems?.isCancel,
          scheduleName: item?.scheduleNo,
          fromDate: item?.fromDate,
          toDate: item?.toDate,
        });
        return {
          ...scheduleItems,
          dayWithDate: getDateForDay(item?.fromDate, scheduleItems?.weekDayNo),
          itemName: scheduleItems?.itemName,
          sourceAddress: `${scheduleItems?.sourceAddress?.street1}, ${scheduleItems?.sourceAddress?.street2},${scheduleItems?.sourceAddress?.cityName}, ${scheduleItems?.sourceAddress?.zip}, ${scheduleItems?.sourceAddress?.country}`,
          startTime: scheduleItems?.startTime,
          destinationAddress: `${scheduleItems?.destinationAddress?.street1}, ${scheduleItems?.destinationAddress?.street2},${scheduleItems?.destinationAddress?.cityName}, ${scheduleItems?.destinationAddress?.zip}, ${scheduleItems?.destinationAddress?.country}`,
          endTime: scheduleItems?.endTime,
          isCancel: scheduleItems?.isCancel,
          scheduleName: item?.scheduleNo,
          fromDate: item?.fromDate,
          toDate: item?.toDate,
        };
      });
    });

    return data?.length > 0 ? newData : [];
  };

  const cancelRide = async () => {
    try {
      let requestObject = {
        isCancel: true,
        scheduleItemIds: [selectedRowData],
        scheduleId: scheduleStateData?.id,
      };
      let sucessResponse = await dispatch(
        cancelRideStop({ ...requestObject })
      ).unwrap();
      if (sucessResponse) {
        toast.success(t("toast.scheduleItemCanceled"));
        getAllScheduleData();
        openAndCloseCancelModel();
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const openAndCloseCancelModel = () => {
    setIsCancelModal(!isCancelModal);
  };

  const getMessage = () => {
    return `${t("common.cancelItemMessage1")} ${rowData?.itemName} ${t(
      "common.cancelItemMessage2"
    )} ${rowData?.scheduleName} ${t("driver.from")} ${moment(
      rowData?.fromDate
    ).format("DD-MMM-YY")} ${t("driver.to")} ${moment(rowData?.toDate).format(
      "DD-MMM-YY"
    )} ${t("common.cancelItemMessage3")} ${moment(
      rowData?.scheduleItemDate
    ).format("dddd")}`;
  };

  // ---------------- Component Life Cycle Methos ---------------------------------

  // useEffect(() => {
  //   getAllScheduleData();
  // }, []);

  useEffect(() => {
    getAllScheduleData();
  }, [page, rowsPerPage]);

  // --------------- Return Statement -----------------------------------------------

  return (
    <div className="driver-upcoming-main-container">
      <div className="upcoming-ride-driver-main-header">
        <div className="upcoming-ride-driver-heading">
          {t("driver.upcomingRides")}
        </div>
      </div>
      <div className="table-hiding">
        <Grid container className="show-table">
          {/* <Table
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={columns}
            Data={mapData(scheduleStateData?.result || [])}
            detailPanel={(rowdata) => (
              <DetailedPanel
                rowData={rowdata}
                getAllScheduleData={getAllScheduleData}
              />
            )}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
            }}
          /> */}
          <Table
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={columns}
            Data={manipulateDataForTable(scheduleStateData?.result || [])}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
              toolbar: false,
            }}
          />
          <div style={{ display: "none" }}>
            <PaginationComponent
              count={scheduleStateData?.totalPages}
              totalItems={scheduleStateData?.totalItems}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </Grid>

        {/* <Grid container className="show-table-2">
          <DriverTable
            handleRowSelect={(rows) => (selectedItem.current = rows)}
            tableTittle={false}
            columns={mobileScreenColumns}
            Data={mapData(scheduleStateData?.result || [])}
            detailPanel={(rowdata) => (
              <DetailedPanel
                rowData={rowdata}
                getAllScheduleData={() => getAllScheduleData()}
              />
            )}
            options={{
              paging: false,
              search: false,
              toolbar: false,
              // selection: true,
              // actions: true,
            }}
          />
        </Grid> */}

        <Grid className="for-mobile-view" xs={12}>
          {manipulateDataForTable(scheduleStateData?.result || []).map(
            (ele, i) => {
              return (
                <Grid xs={12} className="item-container">
                  <Grid xs={12} className="date-container">
                    {ele?.isCancel ? (
                      <s>
                        <Typography className="content">
                          {ele?.dayWithDate}
                        </Typography>
                      </s>
                    ) : (
                      <Typography className="content">
                        {ele?.dayWithDate}
                      </Typography>
                    )}
                  </Grid>

                  <Grid xs={12} className="item-name-container">
                    {ele?.isCancel ? (
                      <s>
                        {" "}
                        <Typography className="content">
                          {ele?.itemName}
                        </Typography>
                      </s>
                    ) : (
                      <Typography className="content">
                        {ele?.itemName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} className="departure-time-container">
                    {ele?.isCancel ? (
                      <s style={{ display: "flex" }}>
                        {" "}
                        <Typography className="head">
                          {t("common.departureTime")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.startTime}
                        </Typography>
                      </s>
                    ) : (
                      <>
                        {" "}
                        <Typography className="head">
                          {t("common.departureTime")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.startTime}
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid xs={12} className="departure-address">
                    {ele?.isCancel ? (
                      <s style={{ display: "flex" }}>
                        {" "}
                        <Typography className="head">
                          {t("common.departureAddress")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.sourceAddress}
                        </Typography>
                      </s>
                    ) : (
                      <>
                        <Typography className="head">
                          {t("common.departureAddress")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.sourceAddress}
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid xs={12} className="departure-time-container">
                    {ele?.isCancel ? (
                      <s style={{ display: "flex" }}>
                        {" "}
                        <Typography className="head">
                          {t("common.arrivalTime")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.endTime}
                        </Typography>
                      </s>
                    ) : (
                      <>
                        {" "}
                        <Typography className="head">
                          {t("common.arrivalTime")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.endTime}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid xs={12} className="departure-address">
                    {ele?.isCancel ? (
                      <s style={{ display: "flex" }}>
                        {" "}
                        <Typography className="head">
                          {" "}
                          {t("common.arrivalAddress")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.destinationAddress}
                        </Typography>
                      </s>
                    ) : (
                      <>
                        <Typography className="head">
                          {" "}
                          {t("common.arrivalAddress")}
                        </Typography>
                        <Typography className="content">
                          &nbsp; - {ele?.destinationAddress}
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid className="button-container">
                    {!ele?.isCancel ? (
                      <Button
                        className="cancel-ride-button"
                        onClick={() => {
                          openAndCloseCancelModel();
                          setSelectedId(ele?.id);
                          setRowData(ele);
                        }}
                      >
                        {t("driver.cancelRide")}
                      </Button>
                    ) : (
                      t("driver.canceled")
                    )}
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>
      </div>
      <CancelModal
        togal={isCancelModal}
        toggleHandler={() => openAndCloseCancelModel()}
        deleteButtonHandler={() => cancelRide()}
        message={getMessage()}
        resetState={false}
      />
    </div>
  );
};
export default ParentList;
