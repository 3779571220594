import { createSlice } from "@reduxjs/toolkit";
import {
  getByIdScheduleItems,
  deleteScheduleItemById,
  EditScheduleOrder,
} from "../thunks/scheduleItem";

const initState = {
  loading: false,
  getByIdScheduleItemsData: [],
};

const ScheduleItem = createSlice({
  name: "orderItem",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get by id order items data
      .addCase(getByIdScheduleItems.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getByIdScheduleItems.fulfilled, (state, action) => {
        state.loading = false;
        state.getByIdScheduleItemsData = action.payload;
      })
      .addCase(getByIdScheduleItems.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteScheduleItemById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteScheduleItemById.fulfilled, (state, action) => {
        state.loading = false;
        state.getByIdScheduleItemsData = action.payload;
      })
      .addCase(deleteScheduleItemById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditScheduleOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditScheduleOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.getByIdScheduleItemsData = action.payload;
      })
      .addCase(EditScheduleOrder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default ScheduleItem.reducer;
