import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { Circle } from "@mui/icons-material";
import { useThemeContext } from "src/contexts/theme";
import { ScheduleUpdateModal } from "src/components/ScheduleUpdatesModal";
import "./ParentStyle.scss";
import { useNavigate } from "react-router-dom";
import { GetCurrentRideByDriverId } from "src/thunks/schedule";
import { toast } from "react-toastify";
import { GetError } from "src/constant";

const DriverList = () => {
  // ------------------- Redux Global States -----------------------
  const UpcommingRidedata = useSelector(
    (state) => state?.Schedule?.currentRide?.result
  );
  const UserData = useSelector((state) => state?.Auth?.userDetail);
  const userDetail = useSelector((state) => state?.Auth?.userDetail);

  // ------------------- React Hooks ------------------------------
  const themeObj = useThemeContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ------------------ Component Local States ---------------------
  const [isModal, setIsModal] = useState(false);

  // ------------------ Component Helper Functions ----------------
  const toggleHandler = () => {
    setIsModal(!isModal);
  };

  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };

  // ----------------- Return States ------------------------------
  const getDriverShiftData = async (data) => {
    if (userDetail?.transactionId) {
      try {
        if (data?.driverId) {
          // await dispatch(
          //   DriverShiftLog({ id: userDetail?.transactionId })
          // ).unwrap();
          // await dispatch(
          //   GetCurrentRideForDriver({
          //     ...data,
          //     isTodaysSchedule: false,
          //   })
          // ).unwrap();
        } else {
          await dispatch(
            GetCurrentRideByDriverId({
              ...data,
              isUpcommingSchedule: false,
              isTodaysSchedule: true,
            })
          ).unwrap();
        }
      } catch (error) {
        toast.error(
          GetError(
            error.response?.data?.errors ||
              error?.message ||
              "Problem While Fetching Data. Please Try Again."
          )
        );
      }
    }
  };

  useEffect(() => {
    if (
      userDetail?.roles?.includes("SchoolCoordinator") ||
      userDetail?.roles?.includes("Driver") ||
      userDetail?.roles?.includes("Contact")
    ) {
      const userRoleFind = userDetail?.roles.includes("Contact")
        ? { contactId: userDetail?.transactionId }
        : userDetail?.roles.includes("SchoolCoordinator")
        ? { schoolUserId: userDetail?.transactionId }
        : { driverId: userDetail?.transactionId };

      getDriverShiftData(userRoleFind);
    }
  }, [userDetail]);

  return (
    <div className="parent-dashboard-main-container">
      <Grid
        sm={12}
        xs={12}
        container
        className={`${getThemedBgColor()} dashboard-subcontainer-1`}
      >
        <Grid container sm={12} xs={12} className="dashboard-subcontainer-2">
          <Grid container sm={12} xs={12} className="header-container">
            {" "}
            <Grid item sm={3.5} xs={12}>
              <Grid className="welcomeMessage">
                <Grid className="message1">{t("common.welcomeBack")}</Grid>
                <Grid className="message2">
                  {UserData?.firstName} {UserData?.lastName}
                </Grid>
                <Grid className="message3">{t("common.welcomeText")}</Grid>
                <Grid item className="upcoming-button">
                  <Button
                    className="upcoming-ride-button"
                    onClick={() => navigate("/app/parentUpcomingRides/list")}
                  >
                    {t("driver.upcomingRides")}
                  </Button>
                  <Typography
                    className="view-updates"
                    onClick={() => toggleHandler()}
                    sx={{ display: { xs: "none", sm: "none" } }}
                  >
                    View Updates
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {
              //card
            }
            {/* <Grid
              item
              sm={4.7}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                overflowX: "auto", // Enable horizontal scrolling
                marginTop: { xs: "30px" },
              }}
            >
              {
                //first card
              }
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { sm: "center" },
                  alignItems: "flex-start",
                }}
              >
                <Grid
                  item
                  sm={5.5}
                  xs={12}
                  className="next-ride-container"
                  sx={{ marginRight: { xs: "10px" } }}
                >
                  <Grid item sm={12} xs={12} sx={{ width: { xs: "250px" } }}>
                    <Grid sm={12} item className="next-ride-image-container">
                      <Grid item className="nextRide-image">
                        <img src={busImage} alt="Not found"></img>
                      </Grid>
                      <Grid item className="next-ride-text">
                        Next Ride
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} className="next-stops-text-container">
                    <Grid style={{ marginLeft: "20px" }}>
                      <Grid sm={10} item sx={{ display: "flex" }}>
                        <Grid item className="circle-rounded-stop-container">
                          <CircleRounded className="circle-rounded-stop" />
                        </Grid>
                        <Grid item className="start-stop-text-container">
                          <Typography className="start-stop-text">
                            {" "}
                            Rantakylä, 50600 Mikkeli
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid sm={10} item sx={{ display: "flex" }}>
                        <Grid item className="place-stop-container">
                          <Place className="place-rounded-stop" />
                        </Grid>
                        <Grid item className="start-stop-text-container">
                          <Typography className="end-stop-text">
                            {" "}
                            Lähemäen koulu
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {
                  //Second card
                }
                <Grid item sm={5.5} xs={12} className="upcoming-ride-container">
                  <Grid item sm={12} xs={12} sx={{ width: { xs: "300px" } }}>
                    <Grid
                      sm={12}
                      item
                      className="upcoming-ride-image-container"
                    >
                      <Grid item className="upcomingRide-image">
                        <img src={BusFrontIcons} alt="Not found"></img>
                      </Grid>
                      <Grid item className="upcoming-ride-text">
                        Upcoming Ride
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} className="upcoming-text-container">
                    <Grid
                      item
                      sm={3}
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="text-1">02</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={8}
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="text-2">
                        Rides in this shift have not yet begun.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            {
              //updates
            }
            {/* <Grid
              item
              sm={3.3}
              xs={12}
              sx={{ display: { sm: "flex", xs: "none" } }}
            >
              <Grid item xs={4} sx={{ display: { xs: "none", sm: "flex" } }}>
                <Grid
                  container
                  xs={2.5}
                  className="driver-schedule-update"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography className="driver-schedule-update-heading">
                    New Schedules Update
                  </Typography>

                  <Typography
                    className="driver-schedule-update-sub-content"
                    sx={{ height: "100px", overflow: "scroll" }}
                  >
                    The new schedules for the upcoming season will be published
                    on August 4th, 2023. Please check the times and if you
                    notice errors/deficiencies, please send a message to
                  </Typography>

                  <Typography className="driver-schedule-update-sub-email">
                    info@halikonliikken.fi
                  </Typography>
                  <Typography className="driver-schedule-update-sub-read-more">
                    Read More
                  </Typography>
                  <Grid className="driver-schedule-update-sub-user-details">
                    <Grid className="driver-schedule-update-sub-user-details-image"></Grid>
                    <Grid className="driver-schedule-update-sub-user-details-content">
                      <Typography className="driver-schedule-update-sub-user-details-content-heading">
                        Tommi Aumol
                      </Typography>
                      <Typography className="driver-schedule-update-sub-user-details-content-subcontent">
                        Sr. Manager @ Busteri{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid className="body-container" container sm={12} xs={12}>
        {UpcommingRidedata?.length > 0 ? (
          <Grid container className="body-sub-container" sm={12} xs={12}>
            <Grid container sm={12} xs={12} className="body-sub-container-2">
              <Grid item className="table-heading" sm={12} xs={12}>
                {t("driver.currentRide")}
              </Grid>
              <Grid item className="child-container" sm={12} xs={12}>
                <Grid
                  item
                  className="route-detail-heading-container"
                  sm={12}
                  xs={12}
                >
                  <Typography className="route-details-heading">
                    {t("driver.routeDetails")}
                  </Typography>
                </Grid>
                {UpcommingRidedata?.map((upcommingRidesItem) => {
                  return (
                    <Grid item sm={12} xs={12} className="details-container">
                      <Grid
                        container
                        sm={12}
                        xs={12}
                        className="details-container-2"
                      >
                        <Grid
                          item
                          sm={3.7}
                          xs={12}
                          className="route-details-container"
                        >
                          <Grid
                            xs={12}
                            sm={12}
                            className="start-end-map-boxes-container"
                          >
                            <Grid
                              xs={12}
                              sm={12}
                              item
                              className="start-map-box"
                            >
                              <Typography className="start-map-box-content">
                                <Typography>
                                  <Circle className="sphere" />
                                </Typography>
                                <Typography className="start-map-box-text">
                                  {upcommingRidesItem?.routeName}
                                </Typography>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid xs={12} sm={12} className="time-container">
                            {" "}
                            <Grid
                              container
                              sm={12}
                              className="ride-starttime-endtime-container d-none"
                            >
                              <Grid className="ride-starttime-endtime-content">
                                <Grid sx={{ display: "flex" }}>
                                  <Typography>
                                    {t("driver.startTime")} :
                                  </Typography>
                                  <Typography>
                                    &#160; {upcommingRidesItem?.startTime}{" "}
                                    &#160;{" "}
                                  </Typography>
                                </Grid>
                                {/* <Typography>| &#160;</Typography> */}
                                <Grid
                                  sx={{ display: "flex", marginTop: "10px" }}
                                >
                                  <Typography>
                                    {t("driver.endTime")} : &#160;
                                  </Typography>
                                  <Typography>
                                    {upcommingRidesItem?.endTime} &#160;
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          xs={11}
                          sm={8}
                          item
                          className="item-details-container"
                        >
                          {upcommingRidesItem?.scheduleItems.length > 0 &&
                            upcommingRidesItem?.scheduleItems?.map(
                              (scheduleItemsObject) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className="item-details-container-2"
                                  >
                                    <Grid
                                      xs={12}
                                      sm={3}
                                      item
                                      className="item-name"
                                    >
                                      {scheduleItemsObject?.itemName || ""}
                                    </Grid>
                                    <Grid
                                      xs={12}
                                      sm={6}
                                      item
                                      className="item-address"
                                    >
                                      {scheduleItemsObject?.destinationAddress
                                        ?.street1 || ""}
                                      ,{" "}
                                      {scheduleItemsObject?.destinationAddress
                                        ?.street2 || ""}
                                      ,{" "}
                                      {scheduleItemsObject?.destinationAddress
                                        ?.cityName || ""}
                                      ,
                                      {scheduleItemsObject?.destinationAddress
                                        ?.state || ""}
                                      ,{" "}
                                      {scheduleItemsObject?.destinationAddress
                                        ?.country || ""}
                                      ,{" "}
                                      {scheduleItemsObject?.destinationAddress
                                        ?.zip || ""}
                                    </Grid>
                                    <Grid
                                      xs={12}
                                      sm={3}
                                      item
                                      className="item-time"
                                    >
                                      {`${scheduleItemsObject?.startTime} - ${scheduleItemsObject?.endTime}`}
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item className="schedule-not-found">
            <span>{t("driver.scheduleMessage1")} </span>
            <span
              className="upcoming-navigation"
              onClick={() => {
                navigate("/app/parentUpcomingRides/list");
              }}
            >
              {t("driver.scheduleMessage2")}{" "}
            </span>
            <span>{t("driver.scheduleMessage3")} </span>
          </Grid>
        )}
      </Grid>
      <ScheduleUpdateModal
        togal={isModal}
        toggleHandler={() => toggleHandler()}
      />
      <div style={{ height: 50 }}></div>
    </div>
  );
};
export default DriverList;
