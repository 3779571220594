import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { forGotPasswordValidationSchema } from "src/helpers/validation";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ChangePassword } from "src/thunks/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetError } from "src/constant";
import logo from "src/assets/images/Group 178038.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { t } from "i18next";

const RenterPassword = ({ setShowContent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConPassword, setShowConPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConPassword = () => setShowConPassword((show) => !show);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forGotPasswordValidationSchema),
  });

  const onResetPasswordSubmitHandler = async (data) => {
    try {
      const verifyToken = localStorage.getItem("verifyToken");
      const res = await dispatch(
        ChangePassword({
          token: verifyToken,
          email: localStorage.getItem("email"),
          password: data?.password,
        })
      ).unwrap();
      if (res) {
        toast.success("Password reset!");
        navigate("/login");
        localStorage.removeItem("email");
      }
    } catch (error) {
      toast.error(GetError(error?.response?.data?.errors) || "something issue");
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      item
      sx={{ backgroundColor: "white", paddingInline: "10px" }}
      xs={12}
      sm={7}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          height: "100vh",
        }}
        item
        xs={12}
        sm={12}
      >
        <Grid
          container
          xs={10}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: "490px",
            }}
            item
            xs={12}
            sm={12}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <img
                  src={logo}
                  alt=""
                  style={{ width: "138px", height: "38.77px" }}
                />
              </Box>

              <Typography
                style={{
                  fontFamily: "Albert Sans",
                  fontSize: "22px",
                  fontWeight: 700,
                }}
              >
                {t("common.resetPassword")}
              </Typography>
              <form onSubmit={handleSubmit(onResetPasswordSubmitHandler)}>
                <Grid item xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    {" "}
                    <Controller
                      control={control}
                      name="password"
                      render={({ field: { onChange, value } }) => (
                        <OutlinedInput
                          value={value}
                          onChange={onChange}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          style={{ width: "100%" }}
                          className="email-textbox"
                          placeholder={t("common.enterPassword")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      )}
                    />
                    <label>{errors?.email?.message || ""}</label>
                  </Grid>
                  <Grid>
                    {" "}
                    <Controller
                      control={control}
                      name="confirmPassword"
                      render={({ field: { onChange, value } }) => (
                        <OutlinedInput
                          value={value}
                          onChange={onChange}
                          id="outlined-adornment-password"
                          type={showConPassword ? "text" : "password"}
                          placeholder={t("common.reEnterPassword")}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          style={{ width: "100%" }}
                          className="email-textbox"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showConPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      )}
                    />
                    <label>{errors?.confirmPassword?.message || ""}</label>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    fontSize: "15.84px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{ color: "black" }}
                    onClick={() => setShowContent("email-id")}
                  >
                    {t("common.goBack")}{" "}
                  </Button>
                  <Button
                    variant="text"
                    sx={{ color: "black" }}
                    onClick={() => navigate("/login")}
                  >
                    {t("common.backToLogin")}{" "}
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      height: { sm: "64.25px", xs: "50px" },
                      fontSize: { sm: "17.6px", xs: "14px" },
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                  >
                    {" "}
                    {t("common.resetPassword")}
                  </Button>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "15px",
                      color: "#000000",
                      fontSize: "12.34px",
                    }}
                  >
                    {t("common.footer")}
                  </Typography>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RenterPassword;
