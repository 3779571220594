import React from "react";
import { placeHolderMessage } from "src/constant";

const MyDateInput = ({ value, onChange }) => {
  const today = new Date();

  // Calculate yesterday's date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Convert to ISO string and extract the date part
  const yesterdayISOString = yesterday.toISOString().split("T")[0];

  return (
    <input
      id="outlined-basic"
      placeholder={placeHolderMessage("schedule.toDate")}
      type="date"
      variant="outlined"
      size="small"
      onChange={(e) => {
        // Check if the selected date is before today
        const selectedDate = new Date(e.target.value);
        if (selectedDate <= new Date()) {
          onChange(e);
        } else {
          // Optionally, you can handle the case where an invalid date is selected
          console.log("Please select a date before or equal to today");
        }
      }}
      value={value}
      min="1900-01-01" // Optionally, you can set a minimum date if needed
      max={yesterdayISOString}
      fullWidth
      style={{
        width: "100%",
        padding: "7px 14px",
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        fontFamily: "Poppins",
      }}
    />
  );
};

export default MyDateInput;
