import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import { Circle, Place } from "@mui/icons-material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useThemeContext } from "src/contexts/theme";
import JourneyStarted from "./JourneyStarted";
import { ScheduleUpdateModal } from "src/components/ScheduleUpdatesModal";
import { DriverShiftLog, DriverShiftStartAndEnd } from "src/thunks/auth";
import { GetError } from "src/constant";
import {
  GetCurrentRideByDriverId,
  GetCurrentRideForDriver,
  StartDriverJourny,
} from "src/thunks/schedule";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { InlineLoader } from "src/components/InlineLoader";
import { LoadingScreen } from "src/components/Loaders/componentLoader";

const DriverList = () => {
  // ----------------- React Global States --------------------------
  const driverShift = useSelector((state) => state?.Auth?.driverShift || {});
  const UpcommingRidedata = useSelector(
    (state) => state?.Schedule?.currentRide
  );
  const UserData = useSelector((state) => state?.Auth?.userDetail);
  const loading = useSelector((state) => state?.Auth?.loading);

  // ----------------- React Hooks ---------------------------------
  const dispatch = useDispatch();
  const themeObj = useThemeContext();
  const navigate = useNavigate();

  // ----------------- Local Component States -----------------------
  const [ScheduleRoutes, setScheduleRoutes] = useState([]);
  const [currentSchedual, setCurrentSchedual] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [newState, setNewState] = useState();

  // ----------------- Local Helper Functions ------------------------

  const getUpcomingRideData = () => {
    if (UpcommingRidedata?.length > 0) {
      let orderUpcommingSchedualData = _.orderBy(
        UpcommingRidedata,
        ["startTime"],
        ["asc"]
      );
      const getCurrentSchedual = _.find(
        orderUpcommingSchedualData,
        (schedualItem) => schedualItem?.isJourneyActive
      );

      setNewState(getCurrentSchedual?.isJourneyActive);
      setCurrentSchedual({ ...getCurrentSchedual });
      setScheduleRoutes([
        ..._.filter(
          orderUpcommingSchedualData,
          (d) => d.driverItems.length > 0
        ),
      ]);
    }
  };

  const startJournyButtonHandler = async (parameterData) => {
    try {
      let data = {
        scheduleId: parameterData?.id || "",
        routeId: parameterData?.routeId || "",
        vehicleId: parameterData?.vehicleId || "",
        driverId: UserData?.transactionId || "",
        time: moment().format("hh:mm"),
        isTodaysSchedule: true,
        isUpcommingSchedule: false,
      };
      if (Object.keys(driverShift)?.length === 0) {
        await dispatch(
          DriverShiftStartAndEnd({ driverId: UserData?.transactionId })
        ).unwrap();
      }
      await dispatch(StartDriverJourny({ ...data })).unwrap();

      setNewState(true);
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getDriverShiftData = async (data) => {
    if (UserData?.transactionId) {
      try {
        if (data?.driverId) {
          await dispatch(
            DriverShiftLog({ id: UserData?.transactionId })
          ).unwrap();
          const response = await dispatch(
            GetCurrentRideForDriver({
              ...data,
              isTodaysSchedule: true,
              scheduleId: 0,
              driverId: data?.driverId,
            })
          ).unwrap();
        } else {
          await dispatch(
            GetCurrentRideByDriverId({
              ...data,
              isTodaysSchedule: false,
            })
          ).unwrap();
        }
      } catch (error) {
        toast.error(
          GetError(
            error.response?.data?.errors ||
              error?.message ||
              "Problem While Fetching Data. Please Try Again."
          )
        );
      }
    }
  };

  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };

  const toggleHandler = () => {
    setIsModal(!isModal);
  };

  // ----------------- Component Life Cycle Methods -------------------

  useEffect(() => {
    if (
      UserData?.roles?.includes("SchoolCoordinator") ||
      UserData?.roles?.includes("Driver") ||
      UserData?.roles?.includes("Contact")
    ) {
      const userRoleFind = UserData?.roles.includes("Contact")
        ? { contactId: UserData?.transactionId }
        : UserData?.roles.includes("SchoolCoordinator")
        ? { schoolUserId: UserData?.transactionId }
        : { driverId: UserData?.transactionId };

      getDriverShiftData(userRoleFind);
    }
  }, [UserData]);

  useEffect(() => {
    getUpcomingRideData();
  }, [UpcommingRidedata]);

  return (
    <div className="driver-dashboard-main-container">
      {loading ? <LoadingScreen /> : ""}
      <Grid
        sm={12}
        container
        className={`${getThemedBgColor()} dashboard-subcontainer-1`}
      >
        <Grid className="dashboard-subcontainer-2" container sm={12}>
          <Grid container sm={12} className="dashboard-subcontainer-3">
            {" "}
            <Grid item sm={3.5} xs={12}>
              <Grid className="welcomeMessage">
                <Grid className="message1">{t("common.welcomeBack")}</Grid>
                <Grid className="message2">
                  {UserData?.firstName} {UserData?.lastName}
                </Grid>
                <Grid className="message3">{t("common.welcomeText")}</Grid>
                <Grid item className="upcoming-button">
                  <Button
                    className="upcoming-ride-button"
                    onClick={() => navigate("/app/upcomingRides/list")}
                  >
                    {t("driver.upcomingRides")}
                  </Button>
                  <Typography
                    className="view-updates"
                    onClick={() => toggleHandler()}
                    sx={{ display: { xs: "none", sm: "none" } }}
                  >
                    View Updates
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {
              //card
            }
            {/* <Grid
              item
              sm={4.7}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                overflowX: "auto", // Enable horizontal scrolling
                marginTop: { xs: "30px" },
              }}
            >
              {
                //first card
              }
              <Grid
                item
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { sm: "center" },
                  alignItems: "flex-start",
                }}
              >
                <Grid
                  item
                  sm={5.5}
                  xs={12}
                  className="next-ride-container"
                  sx={{ marginRight: { xs: "10px" } }}
                >
                  <Grid item sm={12} xs={12} sx={{ width: { xs: "250px" } }}>
                    <Grid sm={12} item className="next-ride-image-container">
                      <Grid item className="nextRide-image">
                        <img src={busImage} alt="Not found"></img>
                      </Grid>
                      <Grid item className="next-ride-text">
                        Next Ride
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} className="next-stops-text-container">
                    <Grid style={{ marginLeft: "20px" }}>
                      <Grid sm={10} item sx={{ display: "flex" }}>
                        <Grid item className="circle-rounded-stop-container">
                          <CircleRounded className="circle-rounded-stop" />
                        </Grid>
                        <Grid item className="start-stop-text-container">
                          <Typography className="start-stop-text">
                            {" "}
                            Rantakylä, 50600 Mikkeli
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid sm={10} item sx={{ display: "flex" }}>
                        <Grid item className="place-stop-container">
                          <Place className="place-rounded-stop" />
                        </Grid>
                        <Grid item className="start-stop-text-container">
                          <Typography className="end-stop-text">
                            {" "}
                            Lähemäen koulu
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {
                  //Second card
                }
                <Grid item sm={5.5} xs={12} className="upcoming-ride-container">
                  <Grid item sm={12} xs={12} sx={{ width: { xs: "300px" } }}>
                    <Grid
                      sm={12}
                      item
                      className="upcoming-ride-image-container"
                    >
                      <Grid item className="upcomingRide-image">
                        <img src={BusFrontIcons} alt="Not found"></img>
                      </Grid>
                      <Grid item className="upcoming-ride-text">
                        Upcoming Ride
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} className="upcoming-text-container">
                    <Grid
                      item
                      sm={3}
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="text-1">02</Typography>
                    </Grid>
                    <Grid
                      item
                      sm={8}
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="text-2">
                        Rides in this shift have not yet begun.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            {
              //updates
            }
            {/* <Grid
              item
              sm={3.3}
              xs={12}
              sx={{ display: { sm: "flex", xs: "none" } }}
            >
              <Grid item xs={4} sx={{ display: { xs: "none", sm: "flex" } }}>
                <Grid
                  container
                  xs={2.5}
                  className="driver-schedule-update"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography className="driver-schedule-update-heading">
                    New Schedules Update
                  </Typography>

                  <Typography
                    className="driver-schedule-update-sub-content"
                    sx={{ height: "100px", overflow: "scroll" }}
                  >
                    The new schedules for the upcoming season will be published
                    on August 4th, 2023. Please check the times and if you
                    notice errors/deficiencies, please send a message to
                  </Typography>

                  <Typography className="driver-schedule-update-sub-email">
                    info@halikonliikken.fi
                  </Typography>
                  <Typography className="driver-schedule-update-sub-read-more">
                    Read More
                  </Typography>
                  <Grid className="driver-schedule-update-sub-user-details">
                    <Grid className="driver-schedule-update-sub-user-details-image"></Grid>
                    <Grid className="driver-schedule-update-sub-user-details-content">
                      <Typography className="driver-schedule-update-sub-user-details-content-heading">
                        Tommi Aumol
                      </Typography>
                      <Typography className="driver-schedule-update-sub-user-details-content-subcontent">
                        Sr. Manager @ Busteri{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid className="current-ride-section">
        {!newState ? (
          Object.keys(ScheduleRoutes)?.length > 0 ? (
            <Grid container className="current-ride-section-subcontainer">
              <Grid container sm={12} className="real-data-exits-container">
                <Grid item className="table-heading">
                  {t("driver.currentRide")}
                </Grid>
                {ScheduleRoutes?.map((currentSchedual) => {
                  return (
                    <Grid item className="map-container">
                      <Grid>
                        <Typography className="route-details-heading">
                          {t("driver.routeDetails")}
                        </Typography>
                      </Grid>{" "}
                      <>
                        <Grid container xs={12} className="map-subcontainer">
                          <Grid
                            xs={12}
                            sm={8}
                            className="start-end-map-boxes-container"
                          >
                            {" "}
                            <Grid xs={12} sm={5.7} className="start-map-box">
                              <Typography className="start-map-box-content">
                                <Typography>
                                  <Circle className="sphere" />
                                </Typography>
                                <Typography className="start-map-box-text">
                                  {currentSchedual?.routeName || ""}
                                  {/* {currentSchedual?.routeStops?.length > 0 && currentSchedual?.routeStops[0]?.name} */}
                                </Typography>
                              </Typography>
                            </Grid>
                            {/* <Grid xs={12} sm={5.7} className="end-map-box">
                              <Typography className="end-map-box-content">
                                <Typography className="map-icon-container">
                                  <Place className="map-icon" />
                                </Typography>
                                <Typography>
                                  {currentSchedual?.routeStops?.length > 0 &&
                                    currentSchedual?.routeStops[
                                      currentSchedual?.routeStops?.length - 1
                                    ]?.name}
                                </Typography>
                              </Typography>
                            </Grid> */}
                          </Grid>

                          <Grid
                            container
                            sm={12}
                            className="ride-starttime-endtime-container-xs d-none"
                          >
                            <Grid className="ride-starttime-endtime-content">
                              <Grid style={{ display: "flex" }}>
                                <Typography>
                                  {t("driver.startTime")}:
                                </Typography>
                                <Typography>
                                  &#160; {currentSchedual?.startTime}&#160;{" "}
                                </Typography>
                              </Grid>

                              {/* <Typography>| &#160;</Typography> */}
                              <Grid
                                style={{ display: "flex", marginTop: "10px" }}
                              >
                                <Typography>
                                  {t("driver.endTime")} : &#160;
                                </Typography>
                                <Typography>
                                  {currentSchedual?.endTime} &#160;{" "}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            sm={3}
                            xs={11.3}
                            className="start-journey-button-container"
                          >
                            <Button
                              disabled={currentSchedual?.isJourneyCompleted}
                              onClick={() =>
                                startJournyButtonHandler(currentSchedual)
                              }
                              fullWidth
                              className="start-journey-button"
                              style={
                                currentSchedual?.isJourneyCompleted
                                  ? { background: "#B2BEB5" }
                                  : {}
                              }
                            >
                              {currentSchedual?.isJourneyCompleted
                                ? t("driver.endJourney")
                                : t("driver.startJourney")}
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          sm={12}
                          className="ride-starttime-endtime-container-sm d-none"
                        >
                          <Grid className="ride-starttime-endtime-content">
                            <Typography>{t("driver.startTime")}</Typography>
                            <Typography>
                              &#160; {currentSchedual?.startTime} &#160;
                            </Typography>
                            <Typography>| &#160;</Typography>
                            <Typography>
                              {t("driver.endTime")} : &#160;
                            </Typography>
                            <Typography>
                              {currentSchedual?.endTime} &#160;{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sm={11.3}
                          xs={11}
                          className="real-time-map-container"
                        >
                          <img
                            src="https://i.pcmag.com/imagery/articles/01IB0rgNa4lGMBlmLyi0VP6-6..v1611346416.png"
                            alt="not found"
                            className="real-time-map"
                          ></img>
                        </Grid>
                      </>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          ) : (
            <Grid item className="schedule-not-found">
              <span>{t("driver.scheduleMessage1")} </span>
              <span
                className="upcoming-navigation"
                onClick={() => {
                  navigate("/app/upcomingRides/list");
                }}
              >
                {t("driver.scheduleMessage2")}{" "}
              </span>
              <span>{t("driver.scheduleMessage3")} </span>
            </Grid>
          )
        ) : (
          <JourneyStarted
            routeDetails={currentSchedual}
            endJournyFlag={() => {
              setNewState(false);
              startJournyButtonHandler(currentSchedual);
            }}
          />
        )}
      </Grid>
      <ScheduleUpdateModal
        togal={isModal}
        toggleHandler={() => toggleHandler()}
      />
    </div>
  );
};
export default DriverList;
