import { createSlice } from "@reduxjs/toolkit";
import { FetchAllCities, FetchLookupValuesForDropdown } from "../thunks/common";

const initState = {
    loading: false,
    cities: [],
    LookupValue: {}
};

const ComapnySlice = createSlice({
    name: "Comapny",
    initialState: initState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder
                .addCase(FetchAllCities.pending, (state, action) => {
                    state.loading = true;
                })
                .addCase(FetchAllCities.fulfilled, (state, action) => {
                    state.loading = false;
                    state.cities = action.payload;
                })
                .addCase(FetchAllCities.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(FetchLookupValuesForDropdown.pending, (state, action) => {
                    state.loading = true;
                })
                .addCase(FetchLookupValuesForDropdown.fulfilled, (state, action) => {
                    state.loading = false;
                    state["LookupValue"][`${action.payload?.type}`] = action.payload?.data
                })
                .addCase(FetchLookupValuesForDropdown.rejected, (state, action) => {
                    state.loading = false;
                })
        },
});

export default ComapnySlice.reducer;
