import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { t } from "i18next";
import _ from "lodash";
import { Button, Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { JourneyEndModal } from "src/components/DriverEndJourneyModal";
import { RouteTravelHistoryForAddItemHistrory } from "src/thunks/schedule";
import { GetError } from "src/constant";
import { useDispatch, useSelector } from "react-redux";
import "./JourneyStarted.scss";
import { LoadingScreen } from "src/components/Loaders/componentLoader";

const JourneyStarted = ({ routeDetails, endJournyFlag }) => {
  // ------------- Redux Global States ----------------
  const UserData = useSelector((state) => state?.Auth?.userDetail);
  const loading = useSelector((state) => state?.Schedule?.loading);

  // -------------- React Hooks -----------------------
  const dispatch = useDispatch();

  // -------------- React Local Component States ------
  const [isModal, setIsModal] = useState(false);
  const [CurrentStop, setCurrentStop] = useState({});

  // ------------- Local API Functions ---------------

  let remainingStopList = _.filter(routeDetails?.driverItems, (d) => d.isNext);

  const RouteTravelHistory = async () => {
    try {
      let removeIsCancelDestinationItem = _.filter(
        CurrentStop?.destinationItems || [],
        (d) => !d.isCancel
      );
      let newData = removeIsCancelDestinationItem?.map((d) => {
        return {
          scheduleItemId: d?.id || "",
          startTime: d?.startTime || "",
          endTime: d?.endTime || "",
          sourceAddressId: d?.sourceAddress?.id || "",
          sourceStopId: d?.sourceStopId || "",
          destinationAddressId: d?.destinationAddress?.id || "",
          destinationStopId: d?.destinationStopId || "",
          distance: 1,
          transactionTime: moment().format("hh:mm:ss"),
        };
      });

      let removeIsCancelSourceItems = _.filter(
        CurrentStop?.sourceItems || [],
        (d) => !d.isCancel
      );

      let newData2 = removeIsCancelSourceItems?.map((d) => {
        return {
          scheduleItemId: d?.id || "",
          startTime: d?.startTime || "",
          endTime: d?.endTime || "",
          sourceAddressId: d?.sourceAddress?.id || "",
          sourceStopId: d?.sourceStopId || "",
          destinationAddressId: d?.destinationAddress?.id || "",
          destinationStopId: d?.destinationStopId || "",
          distance: 1,
          transactionTime: moment().format("hh:mm:ss"),
        };
      });

      if (newData?.length > 0 || newData2?.length > 0) {
        await dispatch(
          RouteTravelHistoryForAddItemHistrory({
            driverId: UserData?.transactionId || "",
            routeItemTravelHistories: [...newData, ...newData2],
            isTodaysSchedule: true,
            scheduleId: 0,
          })
        ).unwrap();
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ------------- React Local Helper Functions -------
  const openAndCloseModel = () => {
    setIsModal(!isModal);
  };

  const getCurrentRoute = () => {
    let Data = routeDetails?.driverItems || [];
    let startJournyStartedOrNot = _.find(Data, (d) => d.isNext);
    setCurrentStop(startJournyStartedOrNot);
  };

  // ---------------- Component Life Cycle Methods -----
  useEffect(() => {
    getCurrentRoute();
  }, [routeDetails]);

  const endjournyFunction = () => {
    endJournyFlag();
    openAndCloseModel();
  };

  // ---------------------- Return Statement --------------------------

  return (
    <div className="journey-main-container">
      {loading ? <LoadingScreen /> : ""}
      <Grid container sm={12} xs={12} className="journey-main-container-2">
        <Grid container sm={12} xs={12} className="journey-main-container-3">
          <Grid container sm={12} xs={12} className="page-heading">
            {t("driver.currentRide")}
          </Grid>
          {remainingStopList?.length === 0 ? (
            <Grid
              container
              sm={12}
              xs={12}
              className="journey-details-message-container"
            >
              <Grid
                container
                className="journey-details-subcontainer-message-1"
                sm={12}
                xs={12}
              >
                <Typography className="journey-details-message-heading">
                  {t("driver.journeyEndText")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid sm={12} xs={12} className="journey-details-container">
              <Grid item sm={12} xs={12}>
                <Grid
                  container
                  className="journey-details-subcontainer-1"
                  sm={12}
                  xs={12}
                >
                  <Typography className="journey-details-heading">
                    {t("driver.journeyDetails")}
                  </Typography>
                  <Typography className="journey-details-date">
                    {t("driver.date")} : {moment().format("DD/MM/YYYY")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  sm={12}
                  xs={12}
                  className="journey-details-subcontainer-2"
                >
                  <Button className="current-stop-button">
                    {t("driver.currentStop")}
                  </Button>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  className="journey-details-container-2"
                >
                  <Grid container sm={12} xs={12}>
                    <Grid
                      container
                      sm={12}
                      xs={12}
                      className="journey-details-stop-details-2"
                      sx={{
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Grid
                        className="journey-stop-address"
                        style={{ color: "red" }}
                      >
                        {CurrentStop?.destinationItems?.length > 0 && (
                          <button className="btn btn-danger btn-sm">
                            {_.map(
                              CurrentStop?.destinationItems,
                              (d) => d.itemName
                            ).join(",")}
                          </button>
                        )}
                      </Grid>
                      <Grid
                        className="journey-stop-address"
                        style={{ color: "#464646" }}
                      >
                        {CurrentStop?.sourceItems?.length > 0 && (
                          <button className="btn btn-dark btn-sm">
                            {" "}
                            {_.map(
                              CurrentStop?.sourceItems || [],
                              (d) => d.itemName
                            ).join(",")}{" "}
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  onClick={() => RouteTravelHistory()}
                  container
                  sm={12}
                  xs={12}
                  className="journey-details-button-container"
                >
                  <Grid item sm={12} xs={12}>
                    <Button className=" journey-details-button">
                      {t("driver.reachedStop")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            sm={12}
            xs={12}
            className="stop-details-container"
            sx={{ marginBottom: "100px" }}
          >
            <Grid
              container
              sm={12}
              xs={12}
              className="stop-details-heading-container"
            >
              <Typography className="stop-details-heading">
                {t("driver.stopDetails")}
              </Typography>
            </Grid>
            <Grid
              container
              className="individual-stop-main-container"
              sm={12}
              xs={12}
              sx={{ marginTop: "20px" }}
            >
              <Grid
                container
                className="individual-stop-details-container"
                sm={12}
                xs={12}
              >
                {_.orderBy(routeDetails?.driverItems, ["time"], ["asc"])?.map(
                  (d) => {
                    return (
                      <Grid
                        sm={12}
                        xs={12}
                        container
                        className={`individual-reached-stop currentStop`}
                      >
                        <Grid item sm={1.5} xs={2}>
                          <Typography className="previous-stop">
                            {d?.name}
                          </Typography>
                          <Typography className="end-time">
                            {d?.sourceItems?.length > 0
                              ? moment(
                                  d["sourceItems"][0].startTime,
                                  "HH:mm"
                                ).format("HH:mm")
                              : d?.destinationItems?.length > 0
                              ? moment(
                                  d["destinationItems"][0].endTime,
                                  "HH:mm"
                                ).format("HH:mm")
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={10}
                          xs={9}
                          className="individual-address-container"
                        >
                          <Grid item sm={11} xs={12}>
                            {_.map(d?.sourceItems || [], (d) => {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  className="user-details"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  sx={{
                                    display: "flex",
                                    flexDirection: {
                                      sm: "column",
                                      xs: "column",
                                    },
                                  }}
                                >
                                  <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    sx={{
                                      display: { md: "flex", xs: "column" },
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      item
                                      sm={12}
                                      xs={12}
                                      sx={{
                                        display: { sm: "block", xs: "block" },
                                      }}
                                    >
                                      <Grid
                                        item
                                        sm={10}
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          className="stop-1"
                                          style={{
                                            color: "green",
                                            display: {
                                              sm: "none",
                                              xs: "flex",
                                            },
                                          }}
                                        >
                                          {d.isCancel ? (
                                            <s>{d.itemName}</s>
                                          ) : (
                                            <span>{d.itemName}</span>
                                          )}
                                        </Typography>
                                        <Grid
                                          sx={{
                                            display: {
                                              sm: "none",
                                              xs: "flex",
                                            },
                                          }}
                                        >
                                          {d.isOnboard ? (
                                            <CheckCircleOutlineIcon
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        sm={1}
                                        xs={12}
                                        sx={{ marginTop: "5px" }}
                                      >
                                        <Typography className="stop-1">
                                          {d.isCancel ? (
                                            <s>
                                              ETA:
                                              {d.etA_StartTime &&
                                                moment(
                                                  d.etA_StartTime,
                                                  "HH:mm"
                                                ).format("HH:mm")}
                                            </s>
                                          ) : (
                                            <span>
                                              ETA:
                                              {d.etA_StartTime &&
                                                moment(
                                                  d.etA_StartTime,
                                                  "HH:mm"
                                                ).format("HH:mm")}
                                            </span>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ display: "flex" }}
                                      md={12}
                                    >
                                      <Grid item sm={3} xs={8} md={12}>
                                        <Typography className="stop-1">
                                          {d?.isCancel ? (
                                            <s>{d.sourceDescription}</s>
                                          ) : (
                                            <span>{d.sourceDescription}</span>
                                          )}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        sm={1}
                                        xs={4}
                                        md={2}
                                        sx={{
                                          display: { xs: "none", md: "flex" },
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {d.isOnboard ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    sx={{
                                      display: { md: "flex", xs: "column" },
                                      alignItems: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {d?.sourceAddress?.street1 || ""},{" "}
                                    {d?.sourceAddress?.street2 || ""},{" "}
                                    {d?.sourceAddress?.cityName || ""},
                                    {d?.sourceAddress?.state || ""},{" "}
                                    {d?.sourceAddress?.country || ""},{" "}
                                    {d?.sourceAddress?.zip || ""}
                                  </Grid>
                                </Grid>
                              );
                            })}
                            {_.map(d?.destinationItems || [], (d) => {
                              return (
                                // <Grid
                                //   item
                                //   sm={12}
                                //   xs={12}
                                //   className="user-details"
                                //   style={{
                                //     backgroundColor: "white",
                                //   }}
                                //   sx={{
                                //     display: "flex",
                                //     flexDirection: {
                                //       sm: "column",
                                //       xs: "column",
                                //     },
                                //   }}
                                // >
                                //   <Grid
                                //     item
                                //     sm={12}
                                //     xs={12}
                                //     md={12}
                                //     sx={{
                                //       display: { md: "flex", xs: "column" },
                                //       alignItems: "center",
                                //     }}
                                //   >
                                //     <Grid
                                //       item
                                //       xs={12}
                                //       sx={{ display: "flex" }}
                                //       md={12}
                                //     >
                                //       <Grid item sm={6} xs={8} md={3}>
                                //         <Typography
                                //           className="stop-1"
                                //           style={{ color: "red" }}
                                //         >
                                //           {d.isCancel ? (
                                //             <s>{d.itemName}</s>
                                //           ) : (
                                //             <span>{d.itemName}</span>
                                //           )}
                                //         </Typography>
                                //       </Grid>

                                //       <Grid item sm={4} xs={2} md={2}>
                                //         <Typography className="stop-1">
                                //           {d.isCancel ? (
                                //             <s>
                                //               ETA:
                                //               {d.etA_StartTime &&
                                //                 moment(
                                //                   d.etA_StartTime,
                                //                   "HH:mm"
                                //                 ).format("HH:mm")}
                                //             </s>
                                //           ) : (
                                //             <span>
                                //               ETA:
                                //               {d.etA_StartTime &&
                                //                 moment(
                                //                   d.etA_StartTime,
                                //                   "HH:mm"
                                //                 ).format("HH:mm")}
                                //             </span>
                                //           )}
                                //         </Typography>
                                //       </Grid>

                                //       <Grid
                                //         item
                                //         sm={1}
                                //         xs={2}
                                //         md={2}
                                //         sx={{
                                //           display: { xs: "flex", md: "none" },
                                //           justifyContent: "flex-end",
                                //         }}
                                //       >
                                //         {d.isDelivered ? (
                                //           <CheckCircleOutlineIcon
                                //             style={{ color: "green" }}
                                //           />
                                //         ) : (
                                //           ""
                                //         )}
                                //       </Grid>
                                //     </Grid>

                                //     <Grid
                                //       item
                                //       xs={12}
                                //       sx={{ display: "flex" }}
                                //       md={12}
                                //     >
                                //       <Grid item sm={3} xs={8} md={12}>
                                //         <Typography className="stop-1">
                                //           {d?.isCancel ? (
                                //             <s>{d.sourceDescription}</s>
                                //           ) : (
                                //             <span>{d.sourceDescription}</span>
                                //           )}
                                //         </Typography>
                                //       </Grid>

                                //       <Grid
                                //         item
                                //         sm={1}
                                //         xs={4}
                                //         md={2}
                                //         sx={{
                                //           display: { xs: "none", md: "flex" },
                                //           justifyContent: "flex-end",
                                //         }}
                                //       >
                                //         {d.isDelivered ? (
                                //           <CheckCircleOutlineIcon
                                //             style={{ color: "green" }}
                                //           />
                                //         ) : (
                                //           ""
                                //         )}
                                //       </Grid>
                                //     </Grid>
                                //   </Grid>
                                //   <Grid
                                //     item
                                //     sm={12}
                                //     xs={12}
                                //     md={12}
                                //     sx={{
                                //       display: { md: "flex", xs: "column" },
                                //       alignItems: "center",
                                //     }}
                                //   >
                                //     {d?.destinationAddress?.street1 || ""},{" "}
                                //     {d?.destinationAddress?.street2 || ""},{" "}
                                //     {d?.destinationAddress?.cityName || ""},
                                //     {d?.destinationAddress?.state || ""},{" "}
                                //     {d?.destinationAddress?.country || ""},{" "}
                                //     {d?.destinationAddress?.zip || ""}
                                //   </Grid>
                                // </Grid>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  className="user-details"
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  sx={{
                                    display: "flex",
                                    flexDirection: {
                                      sm: "column",
                                      xs: "column",
                                    },
                                  }}
                                >
                                  <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    sx={{
                                      display: { md: "flex", xs: "column" },
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      item
                                      sm={12}
                                      xs={12}
                                      sx={{
                                        display: { sm: "block", xs: "block" },
                                      }}
                                    >
                                      <Grid
                                        item
                                        sm={10}
                                        xs={12}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          className="stop-1"
                                          style={{
                                            color: "red",
                                            display: {
                                              sm: "none",
                                              xs: "flex",
                                            },
                                          }}
                                        >
                                          {d.isCancel ? (
                                            <s>{d.itemName}</s>
                                          ) : (
                                            <span>{d.itemName} </span>
                                          )}
                                        </Typography>
                                        <Grid
                                          sx={{
                                            display: {
                                              sm: "none",
                                              xs: "flex",
                                            },
                                          }}
                                        >
                                          {d.isDelivered ? (
                                            <CheckCircleOutlineIcon
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        sm={1}
                                        xs={12}
                                        sx={{ marginTop: "5px" }}
                                      >
                                        <Typography className="stop-1">
                                          {d.isCancel ? (
                                            <s>
                                              ETA:
                                              {d.etA_EndTime &&
                                                moment(
                                                  d.etA_EndTime,
                                                  "HH:mm"
                                                ).format("HH:mm")}
                                            </s>
                                          ) : (
                                            <span>
                                              ETA:
                                              {d.etA_EndTime &&
                                                moment(
                                                  d.etA_EndTime,
                                                  "HH:mm"
                                                ).format("HH:mm")}
                                            </span>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ display: "flex" }}
                                      md={12}
                                    >
                                      <Grid item sm={3} xs={8} md={12}>
                                        <Typography className="stop-1">
                                          {d?.isCancel ? (
                                            <s>{d?.destinationDescription}</s>
                                          ) : (
                                            <span>
                                              {d?.destinationDescription}
                                            </span>
                                          )}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        sm={1}
                                        xs={4}
                                        md={2}
                                        sx={{
                                          display: { xs: "none", sm: "flex" },
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {d.isDelivered ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    sx={{
                                      display: { md: "flex", xs: "column" },
                                      alignItems: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {d?.destinationAddress?.street1 || ""},{" "}
                                    {d?.destinationAddress?.street2 || ""},{" "}
                                    {d?.destinationAddress?.cityName || ""},
                                    {d?.destinationAddress?.state || ""},{" "}
                                    {d?.destinationAddress?.country || ""},{" "}
                                    {d?.destinationAddress?.zip || ""}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>

            <Grid container className="end-journey-container" xs={12} sm={12}>
              <Grid container xs={12} sm={4}>
                <Button
                  className="end-journey-button"
                  fullWidth
                  sx={{
                    "&:hover": {
                      background: "black",
                    },
                  }}
                  onClick={() => openAndCloseModel()}
                >
                  {t("driver.endJourneyButton")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <JourneyEndModal
        togal={isModal}
        toggleHandler={() => openAndCloseModel()}
        endjournyFunction={() => endjournyFunction()}
      />
    </div>
  );
};
export default JourneyStarted;
