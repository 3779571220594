import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";

const TimePicker = ({ onTimeSelected, changedValue }) => {

  const handleHourChange = (e) => {
    let minute = changedValue?.split(":")[1] || "00";
    const formattedTime = `${e?.target?.value}:${minute}:00`;
    onTimeSelected(formattedTime)
  };

  const handleMinuteChange = (e) => {
    let hours = changedValue?.split(":")[0] || "00";
    const formattedTime = `${hours}:${e.target.value}:00`;
    onTimeSelected(formattedTime)
  };


  return (
    <Grid item sm={12} sx={{ display: "flex" }}>
      <TextField
        select
        value={changedValue?.split(":")[0] || "00"}
        onChange={handleHourChange}
        fullWidth
        size="small"
      >
        {Array.from({ length: 24 }, (_, index) => (
          <MenuItem key={index} value={index < 10 ? `0${index}` : `${index}`}>
            {index < 10 ? `0${index}` : `${index}`}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        value={changedValue?.split(":")[1] || "00"}
        onChange={handleMinuteChange}
        fullWidth
        size="small"
      >
        {Array.from({ length: 60 }, (_, index) => (
          <MenuItem key={index} value={index < 10 ? `0${index}` : `${index}`}>
            {index < 10 ? `0${index}` : `${index}`}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default TimePicker;
