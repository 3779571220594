import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t } from "i18next";
import { GetError } from "src/constant/index.js";
import { ImageView } from "src/components/ImageView/index.js";
import { FetchUserData, DeleteUser } from "src/thunks/user.js";
import Table from "src/components/Table/index.js";
import AddUserForm from "./AddUserForm.jsx";
import { CommonModal } from "src/components/Modal";
import { DeleteModal } from "src/components/DeleteModal/index.js";
import { PaginationComponent } from "src/components/Pagination/index.js";
import "./UserStyle.scss";

const UserList = () => {
  // --------------- Redux Global Objects ------------------------
  const dispatch = useDispatch();
  const userStateData = useSelector((state) => state?.User?.UserData || []);
  const loading = useSelector((state) => state?.User?.loading);

  // ---------------- Local Component States ------------------------
  const [isModal, setIsModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteid, setDeleteid] = useState(false);
  const [isDeleteModal, setIsDeleteMOdal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // -------------- Table Column -------------------

  let columns = [
    {
      title: t("user.profile"),
      field: "profileUrl",
      maxWidth: 90,
      render: (rowdata) => <ImageView value={rowdata?.profileUrl} />,
    },
    { title: t("user.fullName"), field: "fullName" },
    { title: t("common.email"), field: "email" },
    { title: t("user.phoneNumber"), field: "phoneNumber" },
    { title: t("user.address"), field: "address" },
    { title: t("user.role"), field: "role" },
    { title: t("company.company"), field: "companyId" },
    {
      title: t("common.action"),
      field: "customActions",
      textAlign: "center",
      maxWidth: 100,
      render: (rowData) => {
        return (
          <div>
            <img
              alt="editIcon"
              className="deleteIcon"
              src={require("../../../assets/images/EditIcon.png")}
              onClick={() => {
                openAndCloseModel();
                setEditData(
                  _.find(
                    userStateData?.result,
                    (userInfo) => userInfo.id == rowData?.id
                  )
                );
              }}
            />
            <img
              alt="deleteIcon"
              className="deleteIcon"
              src={require("../../../assets/images/DeleteIcon.png")}
              onClick={() => {
                openAndCloseDeleteModel();
                setDeleteid(rowData?.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  // -------------- Api Calls -----------------------------------------

  const getAllUserData = async () => {
    try {
      await dispatch(
        FetchUserData({
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const deleteButtonHandler = async () => {
    try {
      let res = await dispatch(
        DeleteUser({
          id: deleteid,
          currentPage: page + 1,
          pageSize: rowsPerPage,
          paging: true,
        })
      ).unwrap();
      if (res) {
        setDeleteid();
        openAndCloseDeleteModel();
        toast.success(t("toast.userDeleted"));
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  // ---------------------------- Local Functions -----------------------------------------

  const mapData = (data) => {
    return data.map((user) => {
      return {
        ...user,
        address: `${user?.address?.street1 || ""}, ${
          user?.address?.street2 || ""
        }, ${user?.address?.zip}, ${user?.address?.zipAreaCode}, ${
          user?.address?.cityName
        }, ${user?.address?.state || ""}, ${user?.address?.country || ""} `,
        fullName: `${user?.firstName || ""} ${user?.lastName || ""}`,
        profileUrl: `${user?.profileUrl || ""}`,
        email: `${user?.email || ""}`,
        phoneNumber: `${user?.phoneNumber || ""}`,
        companyId: `${user?.company?.name || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setEditData(null);
    setIsModal(!isModal);
  };

  const openAndCloseDeleteModel = () => {
    setIsDeleteMOdal(!isDeleteModal);
  };
  //------------------------  Life Cycle Methods --------------------------------------------------

  // useEffect(() => {
  //   getAllUserData();
  // }, []);

  useEffect(() => {
    getAllUserData();
  }, [page, rowsPerPage]);

  return (
    <div className="allcontainer">
      <Table
        loading={loading}
        headerTitle={t("user.user")}
        openModelForAdd={openAndCloseModel}
        tableTittle={false}
        columns={columns}
        Data={mapData(userStateData?.result || [])}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          toolbar: false,
        }}
      />
      <PaginationComponent
        count={userStateData?.totalPages}
        totalItems={userStateData?.totalItems}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CommonModal
        togal={isModal}
        title={editData?.id ? t("user.editUser") : t("user.addNewUser")}
        subtitle={
          editData?.id
            ? t("user.editUserProfile")
            : t("user.createNewProfileUser")
        }
        toggleHandler={() => openAndCloseModel()}
      >
        <AddUserForm
          toggleHandler={() => openAndCloseModel()}
          editData={editData}
          setEditFun={setEditData}
          currentPage={page + 1}
          pageSize={rowsPerPage}
        />
      </CommonModal>

      <DeleteModal
        togal={isDeleteModal}
        toggleHandler={() => openAndCloseDeleteModel()}
        deleteButtonHandler={deleteButtonHandler}
        loading={loading}
      />
    </div>
  );
};
export default UserList;
