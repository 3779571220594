import React from "react";
import { Provider } from "react-redux";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeContextProvider } from "../contexts/theme";
import I18n from "../locales";
import Store from "../store";
import MainApp from "./mainapp";
import { useEffect } from "react";
import Login from "../pages/login";
// eslint-disable-next-line no-unused-vars
const I = I18n;
const RootConatainer = (props) => {
  const location = useLocation();

  const getAuthStatus = () => {
    let userToken = localStorage.getItem("token");
    if (userToken) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getAuthStatus();
  }, [location]);

  return (
    <Provider store={Store}>
      <ThemeContextProvider Theme={"light"}>
        {/* <Router> */}
        <Routes>
          <Route
            path="/app/*"
            element={
              getAuthStatus() ? <MainApp /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/"
            element={
              getAuthStatus() ? (
                <Navigate replace to="/app" />
              ) : (
                <Navigate replace to="/login" />
              )
            }
          />
          <Route path="/login/*" element={<Login />} />
        </Routes>
        {/* </Router> */}
      </ThemeContextProvider>
    </Provider>
  );
};

export default RootConatainer;
