import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import { InlineLoader } from "src/components/InlineLoader";
import {
  MoveScheduleItem,
  getByIdScheduleItems,
} from "src/thunks/scheduleItem";
import { CopySchedule } from "src/thunks/schedule";
import { CommonOrder } from "src/components/dropdowns/Order";
import { CommonRoute } from "src/components/dropdowns/Route";
import { CommonVehicles } from "src/components/dropdowns/Vehicle";
import { CommonSchedule } from "src/components/dropdowns/Schedule/index";
import { ScheduleValidationSchema } from "src/helpers/validation";
import Table from "src/components/Table/index.js";
import { GetError, errorMessageChangeInFinish } from "src/constant";
import "../ScheduleStyle.scss";

const AssignSchedule = ({
  toggleHandler,
  selectedItem,
  DetailSelectedItem,
  currentPage,
  pageSize,
}) => {
  // -------------- Table Columns --------------------------
  let columns = [
    { title: t("schedule.itemName"), field: "itemName" },
    { title: t("schedule.contactName"), field: "contactName" },
    { title: t("schedule.sourceAddress"), field: "sourceAdd", minWidth: 300 },
    { title: t("schedule.sourceDescription"), field: "sourceDescription" },
    // { title: t("schedule.sourceStop"), field: "sourceStopName" },
    {
      title: t("schedule.destinationAddress"),
      field: "destinationAdd",
      minWidth: 300,
    },
    {
      title: t("schedule.destinationDescription"),
      field: "destinationDescription",
    },
    // { title: t("schedule.destinationStop"), field: "destinationStopName" },
    { title: t("schedule.startTime"), field: "startTime" },
    { title: t("schedule.endTime"), field: "endTime" },
    { title: t("schedule.transportType"), field: "transportType" },
  ];

  // -------------- Global States --------------------------
  const { getByIdScheduleItemsData } = useSelector(
    (state) => state.ScheduleItems
  );

  const { loading } = useSelector((state) => state.ScheduleItems);

  const AllSchedule = useSelector(
    (state) => state?.Schedule?.orderData?.result || []
  );

  // -------------- React Hooks ----------------------------
  const dispatch = useDispatch();

  // ------------- React Hook From ---------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(ScheduleValidationSchema),
  });

  // ---------------- Local Component States And Variables -------------------
  let selectedItemNew = selectedItem?.current[0];
  let itemFlag = DetailSelectedItem?.current?.length || false;

  // ---------------- Local Helper Functions ------------------------------------
  const getScheduleItemsByIdData = async () => {
    try {
      await dispatch(getByIdScheduleItems({ id: selectedItemNew?.id }));
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const mapData = (data) => {
    return data.map((schduleData) => {
      return {
        ...schduleData,
        destinationAdd: `${schduleData?.destinationAddress?.street1 || ""}, ${
          schduleData?.destinationAddress?.street2 || ""
        },  ${schduleData?.destinationAddress?.cityName || ""},${
          schduleData?.destinationAddress?.state || ""
        }, ${schduleData?.destinationAddress?.country || ""}, ${
          schduleData?.destinationAddress?.zip || ""
        }  `,
        sourceAdd: `${schduleData?.sourceAddress?.street1 || ""}, ${
          schduleData?.sourceAddress?.street2 || ""
        },  ${schduleData?.sourceAddress?.cityName || ""},${
          schduleData?.sourceAddress?.state || ""
        }, ${schduleData?.sourceAddress?.country || ""}, ${
          schduleData?.sourceAddress?.zip || ""
        }  `,
      };
    });
  };

  const onSubmitHandler = async (data) => {
    try {
      if (itemFlag) {
        let newdata = {
          moveToScheduleId: data?.ScheduleNo || "",
          scheduleItemIds: _.map(DetailSelectedItem?.current, "id"),
        };
        const res = await dispatch(MoveScheduleItem({ ...newdata })).unwrap();
        if (res) {
          toggleHandler();
          toast.success(t("toast.scheduleItemMoved"));
        }
      } else {
        let newData = {
          ScheduleNo: data?.ScheduleNo,
          scheduleId: selectedItemNew?.id,
          fromDate: data?.fromDate,
          toDate: data?.toDate,
          startTime: data?.startTime,
          endTime: data?.endTime,
          currentPage: currentPage,
          pageSize: pageSize,
          paging: true,
        };
        const res = await dispatch(CopySchedule({ ...newData })).unwrap();
        if (res) {
          toggleHandler();
          toast.success(t("toast.scheduleCopied"));
        }
      }
    } catch (error) {
      // toast.error("error.message");
      console.log("Error : ", error);
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const onchangeScheduleNo = (scheduleId) => {
    let activeschedule = _.find(AllSchedule, (d) => d.id === scheduleId);
    setValue("orderId", activeschedule?.orderId);
    setValue("routeId", activeschedule?.routeId);
    setValue("vehicleId", activeschedule?.vehicleId);
    setValue("ScheduleNo", activeschedule?.id);
    setValue("fromDate", moment(activeschedule?.fromDate).format("YYYY-MM-DD"));
    setValue("toDate", moment(activeschedule?.toDate).format("YYYY-MM-DD"));
    setValue("endTime", activeschedule?.endTime);
    setValue("startTime", activeschedule?.startTime);
  };

  // ----------------- Component Life Cycle Methods --------------------------
  useEffect(() => {
    setValue("orderId", selectedItemNew?.orderId);
    setValue("routeId", selectedItemNew?.routeId);
    setValue("vehicleId", selectedItemNew?.vehicleId);
    getScheduleItemsByIdData();
  }, [selectedItem]);

  return (
    <>
      <div className="pt-3 allfooterMainContainer">
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          style={{ height: "100%" }}
        >
          {loading ? <InlineLoader /> : ""}
          <div className="FormFiledContainer">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className="forminputTitle">
                  {t("schedule.scheduleName")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="ScheduleNo"
                  render={({ field: { onChange, value } }) => {
                    if (itemFlag) {
                      return (
                        <CommonSchedule
                          onChange={(data) => {
                            onChange(data);
                            onchangeScheduleNo(data);
                          }}
                          value={value}
                        />
                      );
                    } else {
                      return (
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter Schedule Name"
                          variant="outlined"
                          size="small"
                          type="text"
                          onChange={onChange}
                          value={value}
                          fullWidth
                        />
                      );
                    }
                  }}
                />
                <span className="errorMessageTitle">
                  {errors?.ScheduleNo?.message
                    ? errorMessageChangeInFinish("schedule.scheduleName")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={4}>
                <Typography className="forminputTitle">
                  {t("schedule.fromDate")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="fromDate"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        disabled={itemFlag}
                        id="outlined-basic"
                        placeholder="Enter First Name"
                        variant="outlined"
                        size="small"
                        type="date"
                        onChange={onChange}
                        value={value}
                        fullWidth
                      />
                    );
                  }}
                />
                <span className="errorMessageTitle">
                  {errors?.fromDate?.message
                    ? errorMessageChangeInFinish("schedule.fromDate")
                    : ""}
                </span>
              </Grid>
              <Grid item xs={4}>
                <Typography className="forminputTitle">
                  {t("schedule.toDate")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="toDate"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        disabled={itemFlag}
                        id="outlined-basic"
                        placeholder="Enter First Name"
                        variant="outlined"
                        size="small"
                        type="date"
                        onChange={onChange}
                        value={value}
                        fullWidth
                      />
                    );
                  }}
                />
                <span className="errorMessageTitle">
                  {errors?.toDate?.message
                    ? errorMessageChangeInFinish("schedule.toDate")
                    : ""}
                </span>
              </Grid>

              {/* <Grid item xs={3}>
                <Typography className="forminputTitle">
                  {t("schedule.startTime")}
                  <span
                    style={{
                      color: "red",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  control={control}
                  name="startTime"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TimePicker
                        onTimeSelected={onChange}
                        changedValue={value}
                      />
                    );
                  }}
                />
                <span className="errorMessageTitle">
                  {errors?.startTime?.message
                    ? errorMessageChangeInFinish("schedule.startTime")
                    : ""}
                </span>
              </Grid> */}

              {/* <Grid item xs={3}>
                <Typography className="forminputTitle">
                  {t("schedule.endTime")}
                  <span
                    style={{
                      color: "red",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  control={control}
                  name="endTime"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TimePicker
                        onTimeSelected={onChange}
                        changedValue={value}
                      />
                    );
                  }}
                />
                <span className="errorMessageTitle">
                  {errors?.endTime?.message
                    ? errorMessageChangeInFinish("schedule.endTime")
                    : ""}
                </span>
              </Grid> */}

              <Grid item xs={4}>
                <Typography className="forminputTitle">
                  {t("schedule.contractName")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="orderId"
                  render={({ field: { onChange, value } }) => (
                    <CommonOrder
                      disable={true}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.orderId?.message
                    ? errorMessageChangeInFinish("schedule.contractName")
                    : ""}
                </span>
              </Grid>

              <Grid item xs={4}>
                <Typography className="forminputTitle">
                  {t("route.routeName")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="routeId"
                  render={({ field: { onChange, value } }) => (
                    <CommonRoute
                      disable={true}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.routeId?.message
                    ? errorMessageChangeInFinish("route.routeName")
                    : ""}
                </span>
              </Grid>

              <Grid item xs={4}>
                <Typography className="forminputTitle">
                  {t("vehicle.vehicle")}
                  <span className="asteric">*</span>
                </Typography>
                <Controller
                  control={control}
                  name="vehicleId"
                  render={({ field: { onChange, value } }) => (
                    <CommonVehicles
                      disable={true}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <span className="errorMessageTitle">
                  {errors?.vehicleId?.message
                    ? errorMessageChangeInFinish("vehicle.vehicle")
                    : ""}
                </span>
              </Grid>

              <Grid item xs={12} className="pt-3">
                <Typography className="forminputTitle">
                  {t("schedule.itemDetails")}
                </Typography>
                <Table
                  tableTittle={false}
                  columns={columns}
                  // action={actions}
                  Data={mapData(
                    itemFlag
                      ? DetailSelectedItem?.current
                      : getByIdScheduleItemsData?.result || []
                  )}
                  options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    search: false,
                    toolbar: false,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="formfooterButton">
            <Button
              className="saveButton"
              onClick={() => {
                reset({});
                toggleHandler();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button type="submit" className="saveButton ms-2">
              {t("common.save")}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AssignSchedule;
