import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";
import RootContainer from "./containers/root";
import "./custom-bootstrap.scss";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";
import { ToastContainer } from "react-toastify";
import { HashRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <HashRouter>
        <ToastContainer
          autoClose={1500}
          pauseOnFocusLoss={true}
          pauseOnHover={false}
          theme="colored"
        />
        <RootContainer />
      </HashRouter>
    </div>
  );
}
ServiceWorkerRegistration.register();

export default App;
