import React, { useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderValidationSchema } from "src/helpers/validation";
import { CommonCompany } from "src/components/dropdowns/Company/index";
import { CommonCity } from "src/components/dropdowns/City";
import AllCites from "src/constant/fi.json";
import { CommonLookupDropdown } from "src/components/dropdowns/EngineType";
import _ from "lodash";
import { AddOrder, EditOrder } from "src/thunks/order";
import { InlineLoader } from "src/components/InlineLoader";
import {
  GetError,
  errorMessageChangeInFinish,
  placeHolderMessage,
} from "src/constant";
import AutoCompleteComponent from "src/components/AutoComplete";
import { FetchCompanyData } from "src/thunks/company";
import "./OrderStyle.scss";
import { FetchLookupValuesForDropdown } from "src/thunks/common";

const AddDriverForm = ({
  toggleHandler,
  editData,
  setEditFun,
  currentPage,
  pageSize,
}) => {
  // ----------------- React Hooks -----------------------

  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.Order?.loading);
  const userDetail = useSelector((state) => state?.Auth?.userDetail || []);
  const AllCompany = useSelector(
    (state) => state?.Company?.comapnyData?.result || []
  );
  const LookupData = useSelector((state) => state?.common?.LookupValue || []);

  // ----------------- React Hook Form ------------------

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(OrderValidationSchema),
  });

  // ---------------- API Add And Edit -------------------

  const onSubmitHandler = async (data) => {
    try {
      let newData = {
        orderNo: data?.orderNumber || "",
        cityName: data?.cityName || "",
        companyId: parseInt(data?.companyId || ""),
        fromDate: data?.startContrct || "",
        toDate: data?.endContract || "",
        areaDescription: data?.areaDescription || "",
        categoryId: parseInt(data?.categoryId || ""),
        isActive: true,
        currentPage: currentPage + 1,
        pageSize: pageSize,
        paging: true,
      };
      if (editData?.id) {
        newData.id = editData?.id;
      }
      const res = await dispatch(
        editData?.id ? EditOrder({ ...newData }) : AddOrder({ ...newData })
      ).unwrap();
      if (res) {
        toggleHandler();
        toast.success(
          editData?.id ? t("toast.orderEdited") : t("toast.orderAdded")
        );
        setEditFun({});
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const setEditData = (data) => {
    reset({
      orderNumber: data?.orderNo || "",
      companyId: data?.companyId || "",
      cityName: data?.cityName || "",
      startContrct: moment(data?.fromDate || "").format("YYYY-MM-DD"),
      endContract: moment(data?.toDate || "").format("YYYY-MM-DD"),
      categoryId: data?.categoryId || "",
      areaDescription: data?.areaDescription || "",
    });
  };

  const makeReturnId = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const RandomeReturnNumberGenrate = (string1, len1) => {
    return string1 + "_" + makeReturnId(len1);
  };

  const getDropdownMetaData = async () => {
    try {
      const res = await dispatch(
        FetchCompanyData({ currentPage: 0, pageSize: 0, paging: false })
      ).unwrap();

      await dispatch(
        FetchLookupValuesForDropdown({ type: "Order_Category" })
      ).unwrap();

      if (res) {
        if (
          userDetail?.roles?.includes("Admin") ||
          userDetail?.roles?.includes("Organizer")
        ) {
          setValue("companyId", `${userDetail?.companyId}`);
        }
      }
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getOptions = (data, lableKey, valueKey) => {
    let options = [];
    data?.map((item) => {
      options.push({ label: item[lableKey], value: item[valueKey] });
    });
    return options;
  };

  const getValue = (data, value, parameter) => {
    let defaultData = _.find(data, (d) => d[parameter] == `${value}`);
    return `${defaultData?.name || defaultData?.city || ``}`;
  };

  // ------------- Life Cycle Methods -------------------

  useEffect(() => {
    if (editData) {
      setEditData(editData);
    } else {
      reset({
        orderNumber: RandomeReturnNumberGenrate("Cont", 8),
      });
    }
    getDropdownMetaData();
  }, [editData]);

  return (
    <div className="pt-3 allfooterMainContainer">
      {loading ? <InlineLoader /> : ""}
      <form onSubmit={handleSubmit(onSubmitHandler)} className="formContainer">
        <div className="FormFiledContainer">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("contract.contractNumber")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="orderNumber"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("contract.contractNumber")}
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.orderNumber?.message
                  ? errorMessageChangeInFinish("contract.contractNumber")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("company.company")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="companyId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <AutoCompleteComponent
                      id={"Comapany"}
                      onChange={(e, value) => onChange(value?.value)}
                      options={getOptions(AllCompany, "name", "id")}
                      title={t("company.company")}
                      value={getValue(AllCompany, value, "id")}
                      disabled={
                        userDetail?.roles?.includes("Admin") ||
                        userDetail?.roles?.includes("Organizer")
                          ? true
                          : false
                      }
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {}
                {errors?.companyId?.message
                  ? errorMessageChangeInFinish("company.company")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("common.cityName")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="cityName"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"Cities"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      _.orderBy(AllCites, ["city"], ["asc"]),
                      "city",
                      "city"
                    )}
                    title={t("common.cityName")}
                    value={getValue(AllCites, value, "city")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.cityName?.message
                  ? errorMessageChangeInFinish("common.cityName")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("contract.category")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="categoryId"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteComponent
                    id={"VehicleType"}
                    onChange={(e, value) => onChange(value?.value)}
                    options={getOptions(
                      LookupData?.Order_Category,
                      "name",
                      "id"
                    )}
                    title={t("contract.category")}
                    value={getValue(LookupData?.Order_Category, value, "id")}
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.categoryId?.message
                  ? errorMessageChangeInFinish("contract.category")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("contract.startContract")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="startContrct"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      type="date"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  );
                }}
              />
              <span className="errorMessageTitle">
                {}
                {errors?.startContrct?.message
                  ? errorMessageChangeInFinish("contract.startContract")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {" "}
                {t("contract.endContract")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="endContract"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    type="date"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.endContract?.message
                  ? errorMessageChangeInFinish("contract.endContract")
                  : ""}
              </span>
            </Grid>

            <Grid item xs={6}>
              <Typography className="forminputTitle">
                {t("contract.areaDescription")}
                <span className="asteric">*</span>
              </Typography>
              <Controller
                control={control}
                name="areaDescription"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    placeholder={placeHolderMessage("contract.areaDescription")}
                    variant="outlined"
                    size="small"
                    type="textarea"
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
              <span className="errorMessageTitle">
                {errors?.areaDescription?.message
                  ? errorMessageChangeInFinish("contract.areaDescription")
                  : ""}
              </span>
            </Grid>
          </Grid>
        </div>
        <div className="formfooterButton">
          <Button
            className="saveButton"
            onClick={() => {
              reset({});
              setEditFun({});
              toggleHandler();
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" className="saveButton ms-2">
            {t("common.save")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddDriverForm;
