import { createSlice } from "@reduxjs/toolkit";
import {
  DeleteContact,
  EditContact,
  FetchContactData,
} from "../thunks/contact";

const initState = {
  loading: false,
  ContactData: [],
};

const ContactSlice = createSlice({
  name: "User",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchContactData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchContactData.fulfilled, (state, action) => {
        state.loading = false;
        state.ContactData = action.payload;
      })
      .addCase(FetchContactData.rejected, (state, action) => {
        state.loading = false;
      })
      // .addCase(AddUser.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(AddUser.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.UserData = action.payload;
      // })
      // .addCase(AddUser.rejected, (state, action) => {
      //   state.loading = false;
      // })
      .addCase(DeleteContact.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteContact.fulfilled, (state, action) => {
        state.loading = false;
        state.ContactData = action.payload;
      })
      .addCase(DeleteContact.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditContact.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditContact.fulfilled, (state, action) => {
        state.loading = false;
        state.ContactData = action.payload;
      })
      .addCase(EditContact.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default ContactSlice.reducer;
