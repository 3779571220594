const Menu = [
  {
    key: "routekey-home",
    label: "sidebar.home",
    path: "/app/home",
    vissbleOnSmallScrren: true,
    roles: ["Admin", "Organizer", "SuperAdmin"],
  },
  {
    key: "routekey-contract",
    label: "sidebar.contract",
    path: "/app/order",
    vissbleOnSmallScrren: true,
    roles: ["Admin", "Organizer", "SuperAdmin"],
  },
  {
    key: "routekey-schedule",
    label: "sidebar.schedule",
    path: "/app/schedule",
    vissbleOnSmallScrren: true,
    roles: ["Admin", "Organizer", "SuperAdmin"],
  },
  {
    key: "routekey-master",
    label: "sidebar.masters",
    path: "/app/masters",
    vissbleOnSmallScrren: true,
    roles: ["Admin", "Organizer", "SuperAdmin"],
    subMenu: [
      {
        key: "routekey-company",
        label: "sidebar.company",
        path: "/app/company",
        vissbleOnSmallScrren: true,
        roles: ["SuperAdmin"],
      },
      {
        key: "routekey-userManagement",
        label: "sidebar.userManagement",
        path: "/app/usermanagement",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "SuperAdmin"],
      },
      {
        key: "routekey-contact",
        label: "sidebar.contact",
        path: "/app/contact",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "SuperAdmin", "Organizer"],
      },
      {
        key: "routekey-driver",
        label: "sidebar.driver",
        path: "/app/driver",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "Organizer", "SuperAdmin"],
      },
      {
        key: "routekey-vehicle",
        label: "sidebar.vehicle",
        path: "/app/vehicle",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "Organizer", "SuperAdmin"],
      },
      {
        key: "routekey-school",
        label: "sidebar.school",
        path: "/app/school",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "Organizer", "SuperAdmin"],
      },
      {
        key: "routekey-schoolUser",
        label: "sidebar.schoolUser",
        path: "/app/schoolUser",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "Organizer", "SuperAdmin"],
      },
      {
        key: "routekey-routes",
        label: "sidebar.routes",
        path: "/app/routes",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "Organizer", "SuperAdmin"],
      },
      {
        key: "routekey-routes",
        label: "sidebar.notifications",
        path: "/app/notifications",
        vissbleOnSmallScrren: true,
        roles: ["Admin", "Organizer", "SuperAdmin"],
      },
    ],
  },
  {
    key: "routekey-driverUpcomingRides",
    label: "sidebar.rides",
    path: "/app/driver/rides",
    vissbleOnSmallScrren: true,
    roles: ["Admin", "Organizer", "SuperAdmin"],
  },
  {
    key: "routekey-reports",
    label: "sidebar.reports",
    path: "/app/reports",
    vissbleOnSmallScrren: true,
    roles: ["Admin", "Organizer", "SuperAdmin"],
  },
  {
    key: "routekey-driverDashboard",
    label: "sidebar.driverDashboard",
    path: "/app/drivermaster",
    vissbleOnSmallScrren: true,
    roles: ["Driver"],
    button: true,
  },
  {
    key: "routekey-driverDashboard",
    label: "sidebar.driverDashboard",
    path: "/app/drivermaster",
    vissbleOnSmallScrren: true,
    roles: ["Driver"],
  },
  {
    key: "routekey-upcomingRides",
    label: "sidebar.upcomingRides",
    path: "/app/upcomingRides",
    vissbleOnSmallScrren: true,
    roles: ["Driver"],
  },
  {
    key: "routekey-driverDashboard",
    label: "sidebar.driverDashboard",
    path: "/app/parentmaster",
    vissbleOnSmallScrren: true,
    roles: ["Contact"],
  },
  {
    key: "routekey-upcomingRides",
    label: "sidebar.upcomingRides",
    path: "/app/parentUpcomingRides",
    vissbleOnSmallScrren: true,
    roles: ["Contact"],
  },
  {
    key: "routekey-driverDashboard",
    label: "sidebar.driverDashboard",
    path: "/app/schoolAdminmaster",
    vissbleOnSmallScrren: true,
    roles: ["SchoolCoordinator"],
  },
  {
    key: "routekey-upcomingRides",
    label: "sidebar.upcomingRides",
    path: "/app/schoolAdminUpcomingRides",
    vissbleOnSmallScrren: true,
    roles: ["SchoolCoordinator"],
  },
];

export default Menu;
