import React from "react";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import { t } from "i18next";
import Table from "src/components/Table/index.js";
import moment from "moment";

const PassengerDetails = ({ rowData }) => {
  // --------------- Table Columns -------------------
  let columns = [
    {
      title: t("driver.itemName"),
      field: "itemName",
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.itemName);
      },
    },
    {
      title: t("driver.day"),
      field: "sourceAdd",
      minWidth: 60,
      render: (rowData) => {
        // return checkIsCanceled(
        //   rowData?.isCancel,
        //   _.find(weekdays, (d) => d.value === rowData?.weekDayNo)?.label || ""
        // );

        return checkIsCanceled(
          rowData?.isCancel,
          `${moment(rowData?.scheduleItemDate, "YYYY-MM-DD").format(
            "dddd"
          )} - ${rowData?.scheduleItemDate?.split("T")[0] || ""}`
        );
      },
    },
    // {
    //   title: "From",
    //   field: "startTime",
    //   render: (render) => `${render?.sourceStopName}-${render?.startTime}`,
    // },
    // {
    //   title: "To",
    //   field: "startTime",
    //   render: (render) => `${render?.destinationStopName}-${render?.endTime}`,
    // },
    {
      title: t("common.address"),
      field: "sourceAdd",
      minWidth: 300,
      render: (rowData) => {
        return checkIsCanceled(rowData?.isCancel, rowData?.sourceAdd);
      },
    },
    {
      title: t("driver.status"),
      field: "status",
      maxWidth: 300,
      render: (rowData) => {
        if (!rowData?.isCancel) {
          return t("driver.active");
        }
        return t("driver.canceled");
      },
    },
  ];

  // --------------- Local Helper Funtions ----------------------

  const mapData = (data) => {
    return data.map((itemDetails) => {
      return {
        ...itemDetails,
        destinationAdd: `${itemDetails?.destinationAddress?.street1 || ""}, ${
          itemDetails?.destinationAddress?.street2 || ""
        },  ${itemDetails?.destinationAddress?.cityName || ""},${
          itemDetails?.destinationAddress?.state || ""
        }, ${itemDetails?.destinationAddress?.country || ""}, ${
          itemDetails?.destinationAddress?.zip || ""
        }  `,
        sourceAdd: `${itemDetails?.sourceAddress?.street1 || ""}, ${
          itemDetails?.sourceAddress?.street2 || ""
        },  ${itemDetails?.sourceAddress?.cityName || ""},${
          itemDetails?.sourceAddress?.state || ""
        }, ${itemDetails?.sourceAddress?.country || ""}, ${
          itemDetails?.sourceAddress?.zip || ""
        }  `,
      };
    });
  };

  const getSourceAddres = (address) => {
    return `${address?.sourceAddress?.street1 || ""}, ${
      address?.sourceAddress?.street2 || ""
    },  ${address?.sourceAddress?.cityName || ""},${
      address?.sourceAddress?.state || ""
    }, ${address?.sourceAddress?.country || ""}, ${
      address?.sourceAddress?.zip || ""
    }  `;
  };

  const getDestinationAddress = (address) => {
    return `${address?.destinationAddress?.street1 || ""}, ${
      address?.destinationAddress?.street2 || ""
    },  ${address?.destinationAddress?.cityName || ""},${
      address?.destinationAddress?.state || ""
    }, ${address?.destinationAddress?.country || ""}, ${
      address?.destinationAddress?.zip || ""
    }  `;
  };

  const checkIsCanceled = (isCancel, data) => {
    return isCancel ? <s>{data}</s> : <span>{data}</span>;
  };

  return (
    <Grid>
      <Grid container className="detail-container-desktopscreen">
        <div className="detail-container-2">
          <Grid sm={12} container className="detail-container-3">
            <Grid item className="detail-heading">
              {t("driver.passengerDetails")}
            </Grid>
          </Grid>

          <div>
            <Table
              tableTittle={false}
              columns={columns}
              Data={mapData(rowData?.scheduleItems || [])}
              options={{
                paging: false,
                search: false,
                toolbar: false,
              }}
            />
          </div>
        </div>
      </Grid>

      <Grid container sm={12} className="card-container">
        {mapData(rowData?.scheduleItems || []).map((ele, i) => {
          return (
            <Grid container sm={12} className="card-container-2">
              <Grid item xs={12}>
                <Typography className="passenger-name">
                  {checkIsCanceled(ele?.isCancel, ele?.itemName)}
                </Typography>
              </Grid>
              <Grid item xs={9} className="card-subcontainer">
                <Grid container xs={12} className="card-subcontainer-2">
                  <Grid item xs={3} className="from-container">
                    <Typography className="place-lable">
                      {checkIsCanceled(ele?.isCancel, t("driver.from"))}&nbsp;:
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {" "}
                    <Typography className="place-details">
                      {checkIsCanceled(ele?.isCancel, getSourceAddres(ele))} :
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="card-subcontainer-3">
                  <Grid item xs={3} className="from-container">
                    <Typography className="place-lable">
                      {checkIsCanceled(ele?.isCancel, t("driver.to"))} : &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="place-details">
                      {checkIsCanceled(
                        ele?.isCancel,
                        getDestinationAddress(ele)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="card-subcontainer-3">
                  <Grid item xs={3} className="from-container">
                    <Typography className="place-lable">
                      {checkIsCanceled(ele?.isCancel, t("driver.day"))} : &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="place-details">
                      {checkIsCanceled(
                        ele?.isCancel,
                        `${moment(ele?.scheduleItemDate, "YY-MM-DD").format(
                          "dddd"
                        )} - ${
                          moment(ele?.scheduleItemDate?.split("T")[0]).format(
                            "YY-MMM-DD"
                          ) || ""
                        }`
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="card-subcontainer-3">
                  <Grid item xs={3} className="from-container">
                    <Typography className="place-lable">
                      {checkIsCanceled(ele?.isCancel, t("driver.status"))} :
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className="place-details">
                      {checkIsCanceled(
                        ele?.isCancel,
                        ele?.isCancel
                          ? t("driver.canceled")
                          : t("driver.active")
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
export default PassengerDetails;
