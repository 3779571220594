import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { RideValidation } from "src/helpers/validation";
import { GetError, errorMessageChangeInFinish } from "src/constant";
import Table from "src/components/Table";
import "./RideStyle.scss";
import "./DriverStyle.scss";

import { InlineLoader } from "src/components/InlineLoader";
import { CommonDriver } from "src/components/dropdowns/Diver";
import {
  GetCurrentRideForDriver,
  GetScheduleByDriverId,
} from "src/thunks/schedule";
import DetailedPanel from "./DetailView/index.js";
import moment from "moment";
import { CommonModal } from "src/components/Modal";
import JourneyStarted from "./JourneyStarted";
import { resetCurrentState } from "../../../slice/schedule";
import _ from "lodash";
import { Loader } from "src/components/Loader";
import { LoadingScreen } from "src/components/Loaders/componentLoader";

const Report = () => {
  // ------------------------------- Table Columns ----------------------------
  let columns = [
    {
      title: t("schedule.schedule"),
      field: "scheduleNo",
    },
    {
      title: t("driver.from"),
      field: "date",
      render: (rowData) => moment(rowData?.fromDate).format("DD-MMM-YYYY"),
    },
    {
      title: t("driver.to"),
      field: "date",
      render: (rowData) => moment(rowData?.toDate).format("DD-MMM-YYYY"),
    },
    // {
    //   title: t("driver.startTime"),
    //   field: "startTime",
    //   render: (rowData) => rowData?.startTime,
    // },
    // {
    //   title: t("driver.endTime"),
    //   field: "endTime",
    //   render: (rowData) => rowData?.endTime,
    // },

    {
      title: t("route.route"),
      field: "routeName",
    },
    {
      title: t("vehicle.vehicle"),
      field: "vehicleNo",
    },
    {
      title: t("common.journey"),
      field: "vehicleNo",
      render: (rowData) => {
        return (
          <div
            onClick={() => {
              setScheduleId(rowData);
              // colloborateDataForDriverShift(rowData?.id);
              openAndCloseModel();
              getDriverShiftData(rowData);
            }}
          >
            <button className="btn btn-dark btn-sm">{t("common.view")}</button>
          </div>
        );
      },
    },
  ];

  // -------------------- Global Objects ------------------------
  const scheduleStateData = useSelector(
    (state) => state?.Schedule?.driverScheduleData || []
  );
  const selectedItem = React.useRef([]);
  const UpcommingRidedata = useSelector(
    (state) => state?.Schedule?.currentRide
  );

  const loading = useSelector((state) => state?.Schedule?.loading);

  // -------------------- React Hooks ----------------------------
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [scheduleId, setScheduleId] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [currentSchedual, setCurrentSchedual] = useState([]);
  const [driverId, setDriverId] = useState(0);

  // -------------------- React Hook Form --------------------------
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(RideValidation),
  });

  // --------------------- API Call Functions ---------------------

  const onSubmitHandler = async (data) => {
    try {
      setDriverId(data?.driverId);

      const parameters = {
        driverId: data?.driverId,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
        currentPage: page + 1,
        pageSize: rowsPerPage,
        paging: true,
        isUpcommingSchedule: false,
      };
      await dispatch(
        GetScheduleByDriverId({
          ...parameters,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("toast.errorMessage")
        )
      );
    }
  };

  const getDriverShiftData = async (data, scheduleId) => {
    try {
      const response = await dispatch(
        GetCurrentRideForDriver({
          ...data,
          isTodaysSchedule: false,
          scheduleId: data?.id,
          driverId: driverId,
          isUpcommingSchedule: false,
        })
      ).unwrap();
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error?.message ||
            "Problem While Fetching Data. Please Try Again."
        )
      );
    }
  };

  const mapData = (data) => {
    return data?.map((userData) => {
      return {
        ...userData,
        address: `${
          userData?.user?.address?.street1 ||
          userData?.user?.address?.street2 ||
          ""
        } ${userData?.user?.address?.cityName || ""} ${
          userData?.user?.address?.state || ""
        } ${userData?.user?.address?.country || ""} ${
          userData?.user?.address?.zip || ""
        }`,
        fullName: `${userData?.user?.firstName || ""} ${
          userData?.user?.middleName || ""
        } ${userData?.user?.lastName || ""}`,
        profileUrl: `${userData?.user?.profileUrl || ""}`,
        email: `${userData?.user?.email || ""}`,
        phoneNumber: `${userData?.user?.phoneNumber || ""}`,
      };
    });
  };

  const openAndCloseModel = () => {
    setIsModal(!isModal);
  };

  const getUpcomingRideData = () => {
    console.log(
      "UpcommingRidedata?.length > 0 && driverId != 0",
      UpcommingRidedata?.length > 0 && driverId != 0
    );
    if (UpcommingRidedata?.length > 0 && driverId != 0) {
      let orderUpcommingSchedualData = _.orderBy(
        UpcommingRidedata,
        ["startTime"],
        ["asc"]
      );

      setCurrentSchedual(orderUpcommingSchedualData || []);
      // setScheduleRoutes([
      //   ..._.filter(
      //     orderUpcommingSchedualData,
      //     (d) => d.driverItems.length > 0
      //   ),
      // ]);
    }
  };

  useEffect(() => {
    getUpcomingRideData();
  }, [UpcommingRidedata]);

  useEffect(() => {
    dispatch(resetCurrentState());
  }, []);

  console.log("Loading ", loading);

  return (
    <div className="allcontainer">
      {loading ? <LoadingScreen /> : ""}

      <div className="report-container">
        <div className="report-heading-container">
          <Typography className="report-heading">
            {t("sidebar.rides")}
          </Typography>
        </div>

        <div className="report-body-container">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container xs={11.8} className="form-container">
              <Grid container xs={10} className="form-sub-container">
                <Grid item xs={3} className="vehicle-container">
                  <Typography className="forminputTitle">
                    {t("driver.driver")}
                    <span className="asteric">*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name="driverId"
                    render={({ field: { onChange, value } }) => (
                      <CommonDriver onChange={onChange} value={value} />
                    )}
                  />
                  <span className="errorMessageTitle">
                    {errors?.driverId?.message
                      ? errorMessageChangeInFinish("driver.driver")
                      : ""}
                  </span>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="forminputTitle">
                    {t("schedule.fromDate")} <span className="asteric">*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name="fromDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter Date"
                          variant="outlined"
                          size="small"
                          type="date"
                          onChange={onChange}
                          value={value}
                          fullWidth
                        />
                      );
                    }}
                  />
                  <span className="errorMessageTitle">
                    {errors?.fromDate?.message
                      ? errorMessageChangeInFinish("schedule.fromDate")
                      : ""}
                  </span>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="forminputTitle">
                    {t("schedule.toDate")} <span className="asteric">*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter Date"
                          variant="outlined"
                          size="small"
                          type="date"
                          onChange={onChange}
                          value={value}
                          fullWidth
                        />
                      );
                    }}
                  />
                  <span className="errorMessageTitle">
                    {errors?.toDate?.message
                      ? errorMessageChangeInFinish("schedule.toDate")
                      : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container xs={1.8} className="button-container">
                <Button
                  type="submit"
                  className="saveButton"
                  sx={{
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                  size="large"
                >
                  {t("sidebar.showData")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      {scheduleStateData?.result?.length > 0 ? (
        <div className="">
          <div className="driver-upcomingrides-container">
            <Table
              handleRowSelect={(rows) => (selectedItem.current = rows)}
              tableTittle={false}
              columns={columns}
              Data={mapData(scheduleStateData?.result || [])}
              detailPanel={(rowdata) => <DetailedPanel rowData={rowdata} />}
              options={{
                actionsColumnIndex: -1,
                paging: false,
                search: false,
                toolbar: false,
              }}
            />
            {/* <PaginationComponent
            count={scheduleStateData?.totalPages}
            totalItems={scheduleStateData?.totalItems}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          /> */}
          </div>
        </div>
      ) : (
        ""
      )}
      <CommonModal
        togal={isModal}
        title={t("driver.stopDetails")}
        // subtitle={
        //   editData?.id
        //     ? t("driver.editDriverProfile")
        //     : t("driver.addNewDriverProfile")
        // }
        toggleHandler={() => openAndCloseModel()}
      >
        {/* <JourneyStarted routeDetails={currentSchedual} /> */}
        {currentSchedual?.map((ele, i) => {
          if (ele?.driverItems?.length > 0) {
            return <JourneyStarted routeDetails={ele} />;
          }
        })}
      </CommonModal>
    </div>
  );
};

export default Report;
