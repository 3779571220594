import React, { useRef } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";
import "./importData.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useState } from "react";
import { uploadRouteItem } from "src/thunks/route";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import toastMessages from "src/constant/toastMessages.json";
import { DownloadFile } from "src/thunks/common";
import { sendUploadItemPaths } from "src/thunks/scheduleItem";
import { senOrderItemPath } from "src/thunks/orderItems";
import { GetError } from "src/constant";
import { t } from "i18next";
import { InlineLoader } from "../InlineLoader";

export const ImportDataMdoal = ({
  togal,
  toggleHandler,
  uploadingId,
  moduleName,
  fetchMasterData,
  rowData,
}) => {
  const dispatch = useDispatch();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "46%",
    transform: "translate(-50%, -50%)",
    width: "495px",
    height: "200px",
    bgcolor: "background.paper",
    overflowY: "scroll",
    borderRadius: "18px",
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, resUploading] = useState({});

  const loading = useSelector((state) => state?.ScheduleItems?.loading);

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    document.getElementById("fileInput").click(); // Trigger file input click
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    setSelectedFile(files);
    try {
      let res = await dispatch(
        uploadRouteItem({ formData: formData, type: "default" })
      ).unwrap();
      resUploading(res[0]);
    } catch (error) {
      toast.error(
        GetError(
          error.response?.data?.errors ||
            error.response?.data?.detail ||
            error?.message ||
            t("common.errorMessage")
        )
      );
    }
  };

  const downloadData = async (file, filename) => {
    let data = {
      orderId: uploadingId,
      filePath: file,
      fileName: filename,
    };
    let res = await dispatch(DownloadFile(data)).unwrap();
    return res;
  };

  const uploadImageInOrder = async () => {
    try {
      let data = {
        id: uploadingId,
        filePath: uploading?.url,
        orderNo: rowData?.orderNo || "",
        cityName: rowData?.cityName || "",
        companyId: rowData?.companyId || "",
        fromDate: rowData?.fromDate || "",
        toDate: rowData?.toDate || "",
        areaDescription: rowData?.areaDescription || "",
        categoryId: rowData?.categoryId || "",
        isActive: true,
      };
      let res = await dispatch(senOrderItemPath(data)).unwrap();
      fetchMasterData();
      toast.success(t("toastMessages.fileUpload"));
      toggleHandler();
      setSelectedFile(null);
    } catch (err) {
      let invalidPath =
        err?.response?.data?.errors?.FilePath?.length > 0
          ? err?.response?.data?.errors?.FilePath[0]
          : "";
      if (invalidPath === "Invalid File.") {
        let res = await downloadData(
          uploading?.url,
          selectedFile?.length > 0
            ? selectedFile[0]?.name
            : "OrderItemTempate.csv"
        );
        setSelectedFile(null);
        toast.error(t("schedule.fileErrorMessage"));
      } else {
        toast.error(
          GetError(
            err.response?.data?.errors ||
              err?.message ||
              t("common.errorMessage")
          )
        );
        setSelectedFile(null);
      }
    }
  };

  const uploadDataInSchedules = async () => {
    try {
      let data = {
        id: uploadingId,
        filePath: uploading?.url,
        scheduleNo: rowData?.scheduleNo || "",
        fromDate: rowData?.fromDate || "",
        toDate: rowData?.toDate || "",
        startTime: rowData?.startTime || "",
        endTime: rowData?.endTime || "",
        orderId: rowData?.orderId || "",
        routeId: rowData?.routeId || "",
        vehicleId: rowData?.vehicleId || "",
        isActive: true,
      };
      let res = await dispatch(sendUploadItemPaths(data)).unwrap();
      setSelectedFile(null);
      if (res.data) {
        toast.success(t("toastMessages.fileUpload"));
        toggleHandler();
      } else {
        let res = await downloadData(
          uploading?.url,
          selectedFile.length > 0
            ? selectedFile[0].name
            : "ScheduleItemTempate.csv"
        );
        toast.error(t("common.fileErrorMessage1"));
      }
    } catch (error) {
      setSelectedFile(null);
      let res = await downloadData(
        uploading?.url,
        selectedFile.length > 0
          ? selectedFile[0].name
          : "ScheduleItemTempate.csv"
      );
      toast.error(t("common.fileErrorMessage1"));
    }
  };

  const uploadpaImage = async () => {
    if (moduleName === "order") {
      uploadImageInOrder();
    } else {
      uploadDataInSchedules();
    }
  };

  return (
    <Modal
      open={togal}
      onClose={() => {
        setSelectedFile(null);
        toggleHandler();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="uploadContainer"
    >
      <Box sx={modalStyle}>
        {loading ? <InlineLoader /> : ""}
        <div style={{ height: "100%" }} className="uploadModalMain">
          <div className="uploadmainHeaderupload">
            <div style={{ paddingTop: 18, paddingLeft: 28 }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="title">
                    <span>{t("schedule.importData")} </span>
                  </div>
                  <div
                    onClick={() => {
                      if (moduleName === "Schedule") {
                        downloadData(
                          "uploads\\Templates\\ScheduleItemTemplate.csv",
                          "ScheduleItemTemplate.csv"
                        );
                      } else
                        downloadData(
                          "uploads\\Templates\\OrderItemTemplate.csv",
                          "OrderItemTempate.csv"
                        );
                    }}
                    style={{
                      paddingRight: 10,
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    <DownloadIcon />
                    {t("schedule.downloadTemplate")}
                  </div>
                </div>
                <div className="subtitle">
                  {t("schedule.importDataSubText")}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                paddingLeft: 28,
                color: "#67C6D6",
                fontSize: "12px",
                paddingRight: 20,
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-basic"
                type="text"
                size="small"
                disabled
                onClick={handleUploadClick}
                InputProps={{
                  startAdornment: (
                    <React.Fragment>
                      <FileUploadOutlinedIcon
                        style={{
                          color: "rgba(0, 0, 0, 0.54)",
                          cursor: "pointer",
                        }}
                      />
                      {selectedFile?.length > 0 ? (
                        selectedFile[0].name
                      ) : (
                        <span
                          style={{
                            marginLeft: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                            cursor: "pointer",
                          }}
                        >
                          {t("schedule.upload")}
                        </span>
                      )}
                    </React.Fragment>
                  ),
                }}
              />
              <input
                ref={fileInputRef}
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept=".csv"
              />
            </div>

            <div
              className="me-3"
              style={{
                display: "flex",
                marginTop: "80px",
                justifyContent: "end",
              }}
            >
              <Button
                className="cancelButton"
                onClick={() => {
                  setSelectedFile(null);
                  toggleHandler();
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                onClick={() => uploadpaImage()}
                className="saveButton ms-3"
                sx={{
                  "&:hover": {
                    backgroundColor: "black",
                    cursor: "pointer",
                  },
                }}
              >
                {t("schedule.upload")}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
