import { createSlice } from "@reduxjs/toolkit";
import {
  AddOrder,
  DeleteOrder,
  EditOrder,
  FetchOrderData,
} from "../thunks/order";

const initState = {
  loading: false,
  orderData: [],
};

const OrderSlice = createSlice({
  name: "order",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchOrderData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchOrderData.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(FetchOrderData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(AddOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(AddOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(AddOrder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(DeleteOrder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(EditOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(EditOrder.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default OrderSlice.reducer;
