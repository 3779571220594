import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "../helpers";

export const FetchContactData = createAsyncThunk(
  "FetchContactData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `Contact?contactName=${model?.contactName || ""}&currentPage=${
          model?.currentPage || 0
        }&contactEmail=${model?.contactEmail || ""}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// export const AddUser = createAsyncThunk("AddContact", async (model, thunkApi) => {
//   try {
//     await getAxios().post("user", model);

//     let res = await getAxios().get(
//       `user?currentPage=${model?.currentPage || 0}&pageSize=${
//         model?.pageSize || 0
//       }&paging=${model?.paging || false}`
//     );
//     return res.data;
//   } catch (error) {
//     return thunkApi.rejectWithValue(error);
//   }
// });

export const EditContact = createAsyncThunk(
  "EditContact",
  async (model, thunkApi) => {
    try {
      await getAxios().put(`Contact/${model?.id}`, model);

      let res = await getAxios().get(
        `Contact?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const DeleteContact = createAsyncThunk(
  "DeleteContact",
  async (model, thunkApi) => {
    try {
      await getAxios().delete(`Contact/${model?.id}`, {
        id: model?.id,
      });
      let res = await getAxios().get(
        `Contact?currentPage=${model?.currentPage || 0}&pageSize=${
          model?.pageSize || 0
        }&paging=${model?.paging || false}`
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
